import { FC, ReactNode } from 'react';
import { cx } from '@emotion/css';

import { DangerIcon } from 'components';
import styles from './styles.module.css';

interface IErrorMessage {
  message: string | ReactNode;
  className?: string;
}

export const ErrorMessage: FC<IErrorMessage> = ({
  message, className,
}) => (
  <div
    className={cx('flex-center', styles.errorMessage, className)}
  >
    <DangerIcon />
    <span className={styles.message}>{message}</span>
  </div>
);
