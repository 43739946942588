import { FC, ReactNode } from 'react';

import styles from './tooltipContentList.module.css';

interface ITooltipContentWithIcon {
  icon: ReactNode;
  text: string | ReactNode;
}

export const TooltipContentWithIcon: FC<ITooltipContentWithIcon> = ({
  icon, text,
}) => (
  <div className={styles.tooltipContentWithIcon}>
    <div className={styles.icon}>
      {icon}
    </div>
    <div>{text}</div>
  </div>
);
