import {
  Contract, ContractReceipt, ContractTransaction, ContractFunction, ethers, BigNumber,
} from 'ethers';

import {
  Address, InfuraProvider, Web3Provider,
} from 'types/web3';
import { parseContractError } from './parseContractError';
import { IContractError } from './types';
import { logger } from '../logger';

/** get balance of token by user address
 * @param tokenAddress The address of ERC20 token
 * @param userAddress The user address
 * @param provider Reader Provider
 * @returns
 */
async function balanceOf(tokenAddress: Address, userAddress: Address, provider: InfuraProvider): Promise<BigNumber> {
  try {
    const contract = new ethers.Contract(
      tokenAddress,
      ['function balanceOf(address owner) public view returns (uint256)'],
      provider,
    ) as InstanceType<typeof Contract> & {
      balanceOf: ContractFunction<BigNumber>;
    };
    return contract.balanceOf(userAddress);
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.balanceOf',
      tokenAddress,
      userAddress,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

/** get decimals by token address
 * @param tokenAddress The address of ERC20 token
 * @param provider Reader Provider
 * @returns
 */
async function getDecimals(tokenAddress: Address, provider: InfuraProvider): Promise<number> {
  try {
    const contract = new ethers.Contract(
      tokenAddress,
      ['function decimals() public view returns (uint8)'],
      provider,
    ) as InstanceType<typeof Contract> & {
      decimals: ContractFunction<number>;
    };
    return contract.decimals();
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.getDecimals',
      tokenAddress,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

/** get token's symbol by token address
 * @param tokenAddress The address of ERC20 token
 * @param provider Reader Provider
 * @returns
 */
async function getSymbol(tokenAddress: Address, provider: InfuraProvider): Promise<string> {
  try {
    const contract = new ethers.Contract(
      tokenAddress,
      ['function symbol() public view returns (string)'],
      provider,
    ) as InstanceType<typeof Contract> & {
      symbol: ContractFunction<string>;
    };
    return contract.symbol();
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.getSymbol',
      tokenAddress,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

/**
 * approve defined contract to transfer on the behalf
 * @param tokenAddress The address of ERC20 token
 * @param targetContractAddress contractAddress to transfer on the behalf
 * @param provider Web3Provider
 * @return Promise<ContractReceipt>
 */
async function approve(
  tokenAddress: Address,
  targetContractAddress: Address,
  provider: Web3Provider,
): Promise<ContractReceipt> {
  try {
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      tokenAddress,
      ['function approve(address spender, uint256 amount) public nonpayable returns (bool)'],
      provider,
    ) as InstanceType<typeof Contract> & {
      approve: ContractFunction<ContractTransaction>;
    };
    const max27 = BigNumber.from('1000000000000000000000000000');
    const approving = await contract.connect(signer).approve(targetContractAddress, max27);
    return approving.wait();
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.approve',
      tokenAddress,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

/**
 * retrieve token's allowance by user address
 * @param tokenAddress The address of ERC20 token
 * @param userAddress The user address
 * @param targetContractAddress contractAddress for checking allowance
 * @param provider Web3Provider
 * @return Promise<BigNumber>
 */
async function allowance(
  userAddress: Address,
  tokenAddress: Address,
  targetContractAddress: Address,
  provider: InfuraProvider | Web3Provider,
): Promise<BigNumber> {
  try {
    const contract = new ethers.Contract(
      tokenAddress,
      ['function allowance(address owner, address spender) public view returns (uint256)'],
      provider,
    ) as InstanceType<typeof Contract> & {
    allowance: ContractFunction<BigNumber>;
    };
    return await contract.allowance(userAddress, targetContractAddress);
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.allowance',
      userAddress,
      tokenAddress,
      targetContractAddress,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

export const erc20 = {
  balanceOf,
  getDecimals,
  getSymbol,
  approve,
  allowance,
};
