import { FC } from 'react';

import { IIcon } from './types';

export const TelegramIcon: FC<IIcon> = ({ opacity = '1' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
  >
    <g clipPath="url(#clip0_364_5391)">
      <path
        d="M20.6451 2.3948C20.2851 2.08952 19.7197 2.04584 19.135 2.28056H19.1341C18.5192 2.52728 1.72879 9.7292 1.04527
         10.0234C0.920953 10.0666 -0.164807 10.4718 -0.0529669 11.3742C0.0468731 12.1878 0.919513 12.5247 1.02607
          12.5636L5.29471 14.0252C5.57791 14.9679 6.62191 18.446 6.85279 19.189C6.99679 19.6522 7.23151 20.2609
          7.64287 20.3862C8.00383 20.5254 8.36287 20.3982 8.59519 20.2158L11.205 17.7951L15.4179 21.0807L15.5182
          21.1407C15.8043 21.2674 16.0784 21.3308 16.34 21.3308C16.5421 21.3308 16.736 21.2929 16.9213
          21.217C17.5525 20.9578 17.805 20.3564 17.8314 20.2882L20.9782 3.93128C21.1702 3.05768 20.9033
          2.6132 20.6451 2.3948ZM9.05935 14.6098L7.61935 18.4498L6.17935 13.6498L17.2193 5.48984L9.05935 14.6098Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_364_5391">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>

);
