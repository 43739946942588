import {
  FC, FormHTMLAttributes, ReactNode,
} from 'react';
import { cx } from '@emotion/css';

import {
  iconStartSize, inputSizes, inputSizesWithIconStart,
} from './input.config';

import styles from './styles.module.css';

interface Props extends FormHTMLAttributes<HTMLInputElement> {
  id?: string;
  value?: string;
  label?: ReactNode;
  type?: 'number' | 'text';
  size?: keyof typeof inputSizes;
  inputClassName?: string;
  variant?: string;
  invalid?: boolean;
  errorMsg?: string;
  helper?: string;
  iconStart?: ReactNode;
  endAdornment?: ReactNode;
  readOnly?: boolean;
  disabled?: boolean;
}

// TODO: tech-debt, refactor
const Input: FC<Props> = ({
  id,
  value = '',
  label,
  type = 'text',
  size = 'md',
  inputClassName = '',
  variant,
  invalid,
  errorMsg = 'Invalid value',
  helper,
  iconStart,
  endAdornment,
  ...rest
}) => {
  const inputStyle = {
    ...inputSizes[size] || {},
    ...(iconStart ? {
      ...inputSizesWithIconStart[size],
    } : {}),
  };
  const iconStartStyles = iconStartSize[size] || {};

  const inputClass = cx(
    styles.default,
    {
      [styles.filled]: variant === 'filled',
    },
    {
      [styles.invalid]: invalid,
    },
    {
      [styles.inputWithIconStart]: !!iconStart,
    },
    inputClassName,
  );

  return (
    <div className={styles.input}>
      {label && (
        <label
          htmlFor={id}
          className={styles.label}
        >
          {label}
        </label>
      )}
      <div className={styles.inputWrapper}>
        {iconStart && (
          <div
            className={styles.iconStart}
            style={iconStartStyles}
          >
            {iconStart}
          </div>
        )}
        <input
          {...rest}
          id={id}
          value={value}
          type={type}
          style={inputStyle}
          className={inputClass}
        />
        {endAdornment && <div className={styles.endAdornment}>{endAdornment}</div>}
      </div>
      {invalid && <div className={styles.errorMsg}>{errorMsg}</div>}
      {helper && <div className={styles.helper}>{helper}</div>}
    </div>
  );
};

export default Input;
