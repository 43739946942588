import { utils } from 'ethers';

import { BLACK_LIST_FARM_HASHES } from 'config';
import { IEventLog } from 'types/web3';

import { parseEvents } from '../parseEvents';
import { IFarmExistEventRes } from './types';

export function parseFarmExistsEvents(unparsed: IEventLog[], iface: utils.Interface): IFarmExistEventRes {
  const parsed = parseEvents(unparsed, iface);

  const farmsMap = new Map();
  parsed.forEach((e) => {
    const {
      farmHash, referredTokenDefn, rewardTokenDefn, sponsor,
    } = e.args;

    // filter out farms that are in the black list
    if (!BLACK_LIST_FARM_HASHES.includes(farmHash)) {
      farmsMap.set(farmHash, {
        referredTokenDefn,
        rewardTokenDefn,
        sponsor,
      });
    }
  });

  return farmsMap;
}
