import { logger, toast } from 'services';

export const writeToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text)
    .catch((err) => {
      toast.toastError('Referral link needs to be copied manually', {
        position: 'top-right',
      });
      logger.logError(err, {
        function: 'writeToClipboard',
      });
    });
};
