import { utils } from 'ethers';

import { TNodeUrl } from 'api/discovery';
import { address, bytes } from 'utils';
import {
  Address, Bytes32, ChainAddress, ChainId, FarmHash, IEventLog,
} from 'types/web3';

import { indexer } from '../indexer';
import { parseEvents } from '../parseEvents';
import {
  IFarmDepositIncreasedEventRes,
  IFarmExistEventRes,
  IFarmMetastateEventRes,
  IRewardsHarvestedEventRes,
} from './types';
import { parseFarmExistsEvents } from './eventParsers';

const eventReg = /(event )(?<eventId>[a-zA-z]+)(\()/;

const events = [
  'event FarmDepositDecreaseClaimed(bytes32 indexed farmHash, uint128 delta)',
  'event FarmDepositDecreaseRequested(bytes32 indexed farmHash, uint128 value, uint128 confirmation)',
  'event FarmDepositIncreased(bytes32 indexed farmHash, uint128 delta)',
  'event FarmExists(address indexed sponsor, bytes24 indexed rewardTokenDefn, bytes24 indexed referredTokenDefn, bytes32 farmHash)',
  'event FarmMetastate(bytes32 indexed farmHash, bytes32 indexed key, bytes value)',
  'event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)',
  'event RewardsHarvested(address indexed caller, bytes24 indexed rewardTokenDefn, bytes32 indexed farmHash, uint128 value, bytes32 leafHash)',
];

const iface = new utils.Interface(events);

const eventIds: Record<string, string> = {};
events.forEach((event) => {
  const match = event.match(eventReg);
  const eventId = match?.groups?.eventId;
  if (eventId) {
    eventIds[eventId] = eventId;
  }
});

async function getFarmExistsEvents(
  oracleUrl: TNodeUrl,
  chainId: ChainId,
  farmsAddress: string,
  account?: Address,
  topic4?: Bytes32[],
): Promise<IFarmExistEventRes> {
  const farmExistsEvents = await indexer.queryIndexer(oracleUrl, {
    addresses: [farmsAddress],
    topic1: [iface.getEventTopic(eventIds.FarmExists)],
    topic2: account ? [address.expandEvmAddressToBytes32(account)] : undefined,
    topic4,
    chainId: [chainId],
  });
  return parseFarmExistsEvents(farmExistsEvents.items, iface);
}

async function getFarmMetastateEvents(
  oracleUrl: TNodeUrl,
  chainId: ChainId,
  farmHashes: FarmHash[],
  farmsAddress: string,
): Promise<IFarmMetastateEventRes> {
  const farmMetastateEvents = await indexer.queryIndexer(oracleUrl, {
    addresses: [farmsAddress],
    topic1: [iface.getEventTopic(eventIds.FarmMetastate)],
    topic2: farmHashes,
    chainId: [chainId],
  });

  const metaState = parseEvents(farmMetastateEvents.items, iface)
    .map((e) => {
      const keyParsed = utils.parseBytes32String(e.args.key);

      let confirmationReward = 0n;
      if (keyParsed === 'confirmationReward') {
        confirmationReward = utils.defaultAbiCoder.decode(['uint128', 'uint128'], e.args.value)[0].toBigInt();
      }

      let rewardsLockTime = 0;
      if (keyParsed === 'rewardsLockTime') {
        rewardsLockTime = utils.defaultAbiCoder.decode(['uint64'], e.args.value)[0].toNumber();
      }

      return {
        farmHash: e.args.farmHash,
        confirmationReward,
        rewardsLockTime,
        event: e,
      };
    });

  const metaStateMap: IFarmMetastateEventRes = new Map();
  metaState.forEach((metasateEvent) => {
    const prevMetaState = metaStateMap.get(metasateEvent.farmHash);

    if (prevMetaState) {
      metaStateMap.set(metasateEvent.farmHash, {
        ...prevMetaState,
        confirmationReward: metasateEvent.confirmationReward || prevMetaState.confirmationReward,
        rewardsLockTime: metasateEvent.rewardsLockTime || prevMetaState.rewardsLockTime,
      });
    } else {
      metaStateMap.set(metasateEvent.farmHash, metasateEvent);
    }
  });

  return metaStateMap;
}

async function getFarmDepositIncreasedEvents(
  oracleUrl: TNodeUrl,
  chainId: ChainId,
  farmHashes: FarmHash[],
  farmsAddress: string,
): Promise<IFarmDepositIncreasedEventRes[]> {
  const farmDepositIncreasedEvents = await indexer.queryIndexer(oracleUrl, {
    addresses: [farmsAddress],
    topic1: [iface.getEventTopic(eventIds.FarmDepositIncreased)],
    topic2: farmHashes,
    chainId: [chainId],
  });

  return parseEvents(farmDepositIncreasedEvents.items, iface)
    .map((e) => ({
      farmHash: e.args.farmHash,
      delta: e.args.delta.toBigInt(),
      event: e,
    }));
}

function parseRewardsHarvestedEvents(unparsed: IEventLog[]): IRewardsHarvestedEventRes {
  const parsed = parseEvents(unparsed, iface);

  const rewardsMap = new Map();
  parsed.forEach((e) => {
    const {
      farmHash, caller, rewardTokenDefn, leafHash, value,
    } = e.args;
    rewardsMap.set(
      leafHash,
      {
        farmHash,
        caller,
        rewardTokenDefn,
        leafHash,
        value: Number.parseFloat(utils.formatUnits(value)),
      },
    );
  });

  return rewardsMap;
}

async function getRewardHarvestedEvents(
  oracleUrl: TNodeUrl,
  account: Address,
  chainId: ChainId,
  farmsAddress: Address,
  filter?: { rewardTokens?: ChainAddress[] },
): Promise<IRewardsHarvestedEventRes> {
  // Allow filtering by reward tokens
  let topic3;
  if (filter?.rewardTokens) {
    topic3 = filter.rewardTokens.map((t) => bytes.expandBytes24ToBytes32(t));
  }

  // Query indexers
  const res = await indexer.queryIndexer(oracleUrl, {
    addresses: [farmsAddress],
    topic1: [iface.getEventTopic(eventIds.RewardsHarvested)],
    topic2: [address.expandEvmAddressToBytes32(account)],
    topic3,
    chainId: [chainId],
  });

  return parseRewardsHarvestedEvents(res.items);
}

export const farms = {
  getFarmExistsEvents,
  getFarmMetastateEvents,
  getFarmDepositIncreasedEvents,
  getRewardHarvestedEvents,
};
