import { cx } from '@emotion/css';
import { FC } from 'react';

import styles from './slicedString.module.css';

interface ISlicedString {
  text: string;
  className?: string
}

export const SlicedString: FC<ISlicedString> = ({
  text, className,
}) => (
  <div
    className={cx('flex-align-center', styles.slicedString, className)}
    data-filetype={text.slice(text.length - 3)}
  >
    <p>{text}</p>
  </div>
);
