import { FC } from 'react';

import { IIcon } from './types';

const SuccessIcon: FC<IIcon> = ({
  fillColor = '#42BE65', opacity = '1', size = '24',
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
  >
    <path
      d="M9.24264 15.3137L5 11.071L6.41421 9.65683L9.24264 12.4853L14.8995 6.8284L16.3137 8.24262L9.24264 15.3137Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0
      11ZM11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706
      20 11 20Z"
      fill={fillColor}
    />
  </svg>

);

export default SuccessIcon;
