import { IOracle, TNodeUrl } from 'api';
import {
  config, DEX_LINKS, EDapp, CHAIN_NAMES,
} from 'config';

const { supportedChainId } = config;

function generateReferralLink(token: string, referrer: string, dapp: EDapp, version = 1): string {
  if (!token || !referrer) return '';

  const v = Number(version).toString(16);
  const tokenHash = token.substring(2).toLowerCase();
  const referrerHash = referrer.substring(2).toLowerCase();

  return `${window.location.origin}/l${version !== 1 ? v : ''}/${dapp}/${tokenHash}${referrerHash}`;
}

function parseUrlForBuyerPage(url = window.location.href)
  : { referrerAddress: string, tokenAddress: string, dapp: EDapp } {
  let dapp = EDapp.SUSHISWAP;

  const idxUniswapV3 = url.indexOf(EDapp.UNISWAPV3);
  if (idxUniswapV3 !== -1) {
    dapp = EDapp.UNISWAPV3;
  }

  const idxUniswapV2 = url.indexOf(EDapp.UNISWAPV2);
  if (idxUniswapV2 !== -1) {
    dapp = EDapp.UNISWAPV2;
  }

  return {
    referrerAddress: `0x${url.substring(url.length - 40)}`,
    tokenAddress: `0x${url.substring(url.length - 80, url.length - 40)}`,
    dapp,
  };
}

function getDappRedirectLink(dapp: EDapp, tokenAddress: string): string {
  let dexLink = DEX_LINKS[dapp];
  if (dapp === EDapp.SUSHISWAP) {
    dexLink += `/swap?outputCurrency=${tokenAddress}&chainId=${supportedChainId}`;
  }
  if (dapp === EDapp.UNISWAPV2 || dapp === EDapp.UNISWAPV3) {
    dexLink += `/#/swap?chain=${CHAIN_NAMES[supportedChainId]}&outputCurrency=${tokenAddress}`;
  }

  return dexLink;
}

function getOracleUrl(oracles: IOracle[]): TNodeUrl {
  return oracles[0].url;
}

export const url = {
  parseUrlForBuyerPage,
  generateReferralLink,
  getDappRedirectLink,
  getOracleUrl,
};
