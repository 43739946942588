import { FC } from 'react';
import { cx } from '@emotion/css';

import { ExternalLinkIcon } from '../icons';

import styles from './defaultLink.module.css';

interface ILink {
  label?: string;
  linkText?: string;
  link: string;
  iconSize?: string;
  className?: string;
  classNameLinkText?: string;
}

export const DefaultLink: FC<ILink> = ({
  label,
  linkText,
  link,
  iconSize,
  className,
  classNameLinkText,
}) => (
  <div className="flex-align-center">
    {label && <div className={styles.label}>{label}</div>}
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={cx(styles.link, className)}
    >
      <ExternalLinkIcon
        size={iconSize}
      />
      {linkText && (
        <span className={cx(styles.linkText, 'linkHoverUnderline', classNameLinkText)}>
          {linkText}
        </span>
      )}
    </a>
  </div>
);
