import { FC } from 'react';
import { AccordionDetails as MUIAccordionDetails, AccordionDetailsProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const DefaultAccordionDetails = styled(MUIAccordionDetails) <AccordionDetailsProps>`
  padding: 16px 24px 11px 24px;
  border-top: 1px solid var(--table-border-color);
  background: var(--accordion-details-bg);
  @media(max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    border-top: 0;
  }
`;

export const AccordionDetails: FC<AccordionDetailsProps> = ({
  children, ...props
}) => (
  <DefaultAccordionDetails {...props}>
    {children}
  </DefaultAccordionDetails>
);
