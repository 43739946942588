import {
  FC, useState, useCallback, useMemo,
} from 'react';

import {
  Button, Input, LinkIcon, RadioGroup,
} from 'components';
import { navigator } from 'utils';
import { TAvailableDexList, toast } from 'services';
import { EDapp, EDappNames } from 'config';

import styles from './referToFarmSuccessfull.module.css';

const DEX_LIST = {
  [EDapp.UNISWAPV3]: {
    value: EDapp.UNISWAPV3,
    label: EDappNames.uniswapv3,
  },
  [EDapp.SUSHISWAP]: {
    value: EDapp.SUSHISWAP,
    label: EDappNames.sushiswap,
  },
};

const copyClick = (link: string): void => {
  navigator.writeToClipboard(link);
  toast.toastSuccess('Link copied to clipboard');
};

interface IReferToFarmSuccessfulCopyLink {
  links: {[key: string]: string};
  availableDexList: TAvailableDexList
  onChangeLinkCopied: () => void;
}
type TDex = { value: EDapp; label: string; } | undefined;

export const ReferToFarmSuccessfulCopyLink: FC<IReferToFarmSuccessfulCopyLink> = ({
  links, availableDexList, onChangeLinkCopied,
}) => {
  const [selectedDex, setSelectedDex] = useState<TDex>(undefined);

  const handleCopyClick = (link: string): void => {
    copyClick(link);
    onChangeLinkCopied();
  };

  const handleDex = useCallback((e) => {
    const dexName = e.target.value as EDapp.UNISWAPV3 || EDapp.SUSHISWAP;
    setSelectedDex(DEX_LIST[dexName]);
  }, []);

  const dexOptions = useMemo(() => Object.entries(DEX_LIST).map(([key, value]) => ({
    ...value,
    disabled: !availableDexList[key],
  })), [availableDexList]);

  const dexDefault = dexOptions.find((dexOption) => !dexOption.disabled);
  const dex = selectedDex || dexDefault;
  return dex ? (
    <div className={styles.step1Content}>
      <RadioGroup
        name="dex"
        selected={dex.value}
        options={dexOptions}
        handleChange={handleDex}
      />
      <div className={styles.copyLinks}>
        <Input
          id={dex.label}
          value={links[dex.value]}
          iconStart={(
            <LinkIcon
              opacity="0.4"
              size="20"
            />
          )}
          size="sm"
          inputClassName={styles.inputClassName}
          endAdornment={(
            <Button
              size="sm"
              variant="tertiary"
              onClick={() => handleCopyClick(links[dex.value])}
            >
              Copy link
            </Button>
          )}
          readOnly
        />
      </div>
    </div>
  ) : null;
};
