import { FC } from 'react';
import { useSelector } from 'react-redux';

import { CreateReferralFarmModal } from 'modules/createFarm';
import { VideoModal } from 'modules/referralFarms';

import { selectors } from 'store';

export const Modals: FC = () => {
  const videoModalOpen = useSelector(selectors.app.isVideoModalOpen);
  const createFarmOpen = useSelector(selectors.app.isCreateFarmModalOpen);

  return (
    <>
      {videoModalOpen ? <VideoModal /> : null}
      {createFarmOpen ? <CreateReferralFarmModal /> : null}
    </>
  );
};
