import {
  BigNumber,
  Contract,
  ContractFunction,
  ContractTransaction,
  ethers,
} from 'ethers';

import {
  Address, ChainId, Web3Provider,
} from 'types/web3';

import { logger, web3 } from 'services';

import { parseContractError } from './parseContractError';
import { IContractError } from './types';

/** stake with amount
 * @param amount amount of tokens to stake
 * @param contractAddr address of the stake contract
 * @param web3Provider ethers.Web3Provider
 * @returns
 */
async function stake(amount: BigNumber, contractAddr: Address, web3Provider: Web3Provider): Promise<string> {
  try {
    const signer = web3Provider.getSigner();

    const contract = new ethers.Contract(
      contractAddr,
      ['function stake(uint256 amount)'],
      signer,
    ) as InstanceType<typeof Contract> & {
      stake: ContractFunction<ContractTransaction>;
    };

    const tx = await contract.stake(amount);
    const { transactionHash } = await tx.wait();
    return transactionHash;
  } catch (e) {
    logger.logError(e as Error, {
      function: 'stake',
      amount,
      contractAddr,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}
/** To with draw the stake
 * @param amount amount of tokens to withdraw
 * @param contractAddr address of the stake contract
 * @param web3Provider ethers.Web3Provider
 * @returns
 */
async function withdraw(amount: BigNumber, contractAddr: Address, web3Provider: Web3Provider): Promise<string> {
  try {
    const signer = web3Provider.getSigner();

    const contract = new ethers.Contract(
      contractAddr,
      ['function withdraw(uint256 amount)'],
      signer,
    ) as InstanceType<typeof Contract> & {
    stake: ContractFunction<ContractTransaction>;
  };

    const tx = await contract.withdraw(amount);
    const { transactionHash } = await tx.wait();
    return transactionHash;
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.withdraw',
      amount,
      contractAddr,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

/** To check how much account have earned with staking so far
 * @param account account address
 * @param contractAddr address of the stake contract
 * @param chainId id of the chaind depend on the network
 * @returns
 */
async function earned(account: Address, contractAddr: Address, chainId: ChainId): Promise<BigNumber> {
  try {
    const provider = web3.getReaderProvider(chainId);
    const contract = new ethers.Contract(
      contractAddr,
      ['function earned(address account) view returns (uint256)'],
      provider,
    ) as InstanceType<typeof Contract> & {
    earned: ContractFunction<BigNumber>;
  };

    return contract.earned(account);
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.stake',
      account,
      chainId,
      contractAddr,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}
/** To check how much unlocked ATTR account have. Note: rewards are locked and released over 12 months time
 * @param account account address
 * @param contractAddr address of the stake contract
 * @param chainId id of the chaind depend on the network
 * @returns
 */
async function unlockedRewards(account: Address, contractAddr: Address, chainId: ChainId): Promise<BigNumber> {
  try {
    const provider = web3.getReaderProvider(chainId);
    const contract = new ethers.Contract(
      contractAddr,
      ['function unlockedRewards(address account) view returns (uint256)'],
      provider,
    ) as InstanceType<typeof Contract> & {
    unlockedRewards: ContractFunction<BigNumber>;
  };

    return contract.unlockedRewards(account);
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.unlockedRewards',
      account,
      chainId,
      contractAddr,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

/** Gives ATTR/second
 * @returns
 */
async function rewardRate(contractAddr: Address, chainId: ChainId): Promise<BigNumber> {
  try {
    const provider = web3.getReaderProvider(chainId);
    const contract = new ethers.Contract(
      contractAddr,
      ['function rewardRate() view returns (uint256)'],
      provider,
    ) as InstanceType<typeof Contract> & {
    rewardRate: ContractFunction<BigNumber>;
  };

    return contract.rewardRate();
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.rewardRate',
      contractAddr,
      chainId,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}
/** Gives balance of staking account
 * @returns
 */
async function balances(account: Address, contractAddr: Address, chainId: ChainId): Promise<BigNumber> {
  try {
    const provider = web3.getReaderProvider(chainId);
    const contract = new ethers.Contract(
      contractAddr,
      ['function balances(address) view returns (uint256)'],
      provider,
    ) as InstanceType<typeof Contract> & {
    balances: ContractFunction<BigNumber>;
  };
    return contract.balances(account);
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.balances',
      account,
      chainId,
      contractAddr,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}
/** Gives balance of staking
 * @returns
 */
async function balance(contractAddr: Address, chainId: ChainId): Promise<BigNumber> {
  try {
    const provider = web3.getReaderProvider(chainId);
    const contract = new ethers.Contract(
      contractAddr,
      ['function balance() view returns (uint256)'],
      provider,
    ) as InstanceType<typeof Contract> & {
    balance: ContractFunction<BigNumber>;
  };

    return contract.balance();
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.balance',
      contractAddr,
      chainId,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}
/** Gives balance of staking
 * @returns
 */
async function lockPeriodFinish(contractAddr: Address, chainId: ChainId): Promise<BigNumber> {
  try {
    const provider = web3.getReaderProvider(chainId);
    const contract = new ethers.Contract(
      contractAddr,
      ['function lockPeriodFinish() view returns (uint256)'],
      provider,
    ) as InstanceType<typeof Contract> & {
    lockPeriodFinish: ContractFunction<BigNumber>;
  };

    return contract.lockPeriodFinish();
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.lockPeriodFinish',
      contractAddr,
      chainId,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

/** To transfer all the unlocked reward to your wallet
 * @param contractAddr address of the stake contract
 * @param web3Provider ethers.Web3Provider
 * @returns
 */
async function getReward(contractAddr: Address, web3Provider: Web3Provider): Promise<string> {
  try {
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(
      contractAddr,
      ['function getReward()'],
      signer,
    ) as InstanceType<typeof Contract> & {
    stake: ContractFunction<ContractTransaction>;
  };

    const tx = await contract.getReward();
    const { transactionHash } = await tx.wait();
    return transactionHash;
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.getReward',
      contractAddr,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

export const preStakingV1 = {
  stake,
  withdraw,
  getReward,
  rewardRate,
  earned,
  unlockedRewards,
  lockPeriodFinish,
  balance,
  balances,
};
