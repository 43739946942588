import { FC } from 'react';

import { Box, SwipeableDrawer as MUIDrawer } from '@mui/material';

import styles from './drawer.module.css';

type TAnchor = 'top' | 'left' | 'bottom' | 'right';
interface ISidebarProps {
    isSidebarOpen: boolean;
    anchor?: TAnchor;
    onToggleSidebar: () => void
}

export const Drawer: FC<ISidebarProps> = ({
  isSidebarOpen, anchor = 'right', onToggleSidebar, children,
}) => (
  <MUIDrawer
    anchor={anchor}
    open={isSidebarOpen}
    onClose={onToggleSidebar}
    onOpen={onToggleSidebar}
  >
    <Box
      className={styles.drawerContent}
      role="presentation"
      onKeyDown={onToggleSidebar}
    >
      {children}
    </Box>
  </MUIDrawer>
);
