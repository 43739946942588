import { FC, ReactNode } from 'react';
import { cx } from '@emotion/css';

import {
  NetworkBadge, FarmDetails, ReferralCard, IFarmRewards,
} from 'modules/shared';

import {
  APR, ChainAddress, ERC20Token,
} from 'types/web3';
import styles from './farmCard.module.css';

interface IFarmCard {
  referToken: ERC20Token & { referredTokenDefn: ChainAddress };
  APRs: APR[];
  rewardsTokens: IFarmRewards[];
  className?: string;
  columnAprLabel?: string;
  tooltips?: {
    apr?: string,
    daily?: string,
    total?: string,
    estimatedDailyRewards?: ReactNode[]
  };
}

export const FarmCard: FC<IFarmCard> = ({
  referToken,
  rewardsTokens,
  className,
  APRs,
  tooltips,
  columnAprLabel,
}) => (
  <div className={className}>
    <div className={cx(styles.header, 'flex-space-between')}>
      <ReferralCard token={referToken} />
      <div className={styles.right}>
        <NetworkBadge />
      </div>
    </div>
    <FarmDetails
      columnAprLabel={columnAprLabel}
      APRs={APRs}
      totalRewards={rewardsTokens.map(({ totalRewards }) => totalRewards)}
      dailyRewards={rewardsTokens.map(({ dailyRewards }) => dailyRewards)}
      tooltips={tooltips}
    />
  </div>
);
