import { FC } from 'react';

import { IIcon as IDefaultIcon } from './types';

interface IIcon extends IDefaultIcon{
  questionMark?: boolean;
}

export const RewardsIcon: FC<IIcon> = ({
  className, questionMark,
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g opacity="0.9">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.18119 1.54365C8.62724 1.05546 9.27666 0.75 9.99807 0.75C10.7195 0.75 11.3689 1.05546 11.815 1.54365H13.5695C13.8111 1.54365 14.0379 1.66003 14.1788 1.8563C14.3197 2.05257 14.3574 2.30469 14.2801 2.53359L13.2657 5.53776C13.3856 5.62582 13.5235 5.7306 13.6754 5.8517C14.2015 6.27125 14.9006 6.89153 15.5949 7.69739C16.9778 9.30265 18.3887 11.7024 18.2821 14.7469C18.2013 17.0539 16.3577 18.9167 14.0778 18.9167H5.91837C3.63845 18.9167 1.79481 17.0539 1.71404 14.7469C1.60761 11.7024 3.01866 9.30263 4.40163 7.69738C5.0959 6.89153 5.79505 6.27125 6.32119 5.85169C6.47303 5.73061 6.61096 5.62585 6.73082 5.5378L5.71608 2.53366C5.63876 2.30476 5.67646 2.05262 5.81734 1.85633C5.95822 1.66005 6.18503 1.54365 6.42664 1.54365H8.18119ZM8.16051 5.08316H11.836L12.5246 3.04365H11.4456C11.1852 3.04365 10.9435 2.90868 10.807 2.68705C10.6507 2.43337 10.3552 2.25 9.99807 2.25C9.64093 2.25 9.34542 2.43337 9.18914 2.68705C9.05259 2.90868 8.8109 3.04365 8.55059 3.04365H7.47161L8.16051 5.08316ZM12.1483 6.58316H7.84827C7.83939 6.58928 7.8302 6.59564 7.82069 6.60225C7.68666 6.69544 7.49161 6.83689 7.25638 7.02447C6.78483 7.40049 6.15779 7.95709 5.53805 8.67644C4.293 10.1216 3.12473 12.1656 3.21312 14.6944M12.1483 6.58316C12.1572 6.58928 12.1664 6.59564 12.1759 6.60225C12.3099 6.69544 12.5049 6.83689 12.7402 7.02447C13.2117 7.40049 13.8387 7.95708 14.4584 8.67643C15.7034 10.1216 16.8716 12.1656 16.783 14.6944C16.729 16.2372 15.5071 17.4167 14.0778 17.4167H5.91837C4.48906 17.4167 3.26716 16.2372 3.21312 14.6944"
        fill="white"
      />
      {questionMark && (
        <path
          d="M9.86776 14.3335C9.50105 14.3335 9.20377 14.632 9.20377 15.0002C9.20377 15.3684 9.50105 15.6668 9.86776 15.6668C10.2345 15.6668 10.5317 15.3684 10.5317 15.0002C10.5317 14.632 10.2345 14.3335 9.86776 14.3335Z"
          fill="white"
        />
      )}
      {questionMark && (
        <path
          d="M7.7919 10.1957C7.79078 10.222 7.79505 10.2482 7.80442 10.2728C7.81379 10.2974 7.82807 10.3198 7.84638 10.3387C7.86469 10.3575 7.88665 10.3724 7.91091 10.3824C7.93517 10.3924 7.9612 10.3973 7.98742 10.3969H8.65677C8.76873 10.3969 8.85798 10.3048 8.87258 10.1932C8.9456 9.65885 9.3107 9.26947 9.96138 9.26947C10.518 9.26947 11.0275 9.54888 11.0275 10.2209C11.0275 10.7382 10.724 10.9761 10.2445 11.3377C9.69851 11.7361 9.26608 12.2012 9.29691 12.9563L9.29934 13.1331C9.30019 13.1866 9.32194 13.2375 9.35988 13.275C9.39783 13.3125 9.44894 13.3335 9.50217 13.3335H10.1602C10.214 13.3335 10.2655 13.312 10.3036 13.2738C10.3416 13.2357 10.363 13.1839 10.363 13.1298V13.0443C10.363 12.4594 10.5845 12.2892 11.1824 11.8338C11.6765 11.4567 12.1917 11.038 12.1917 10.159C12.1917 8.92815 11.1565 8.3335 10.023 8.3335C8.99509 8.3335 7.86897 8.81411 7.7919 10.1957Z"
          fill="white"
        />
      )}
    </g>
  </svg>

);
