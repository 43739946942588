import {
  createAsyncThunk, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

import {
  contracts,
  METAMASK_ERROR_CODE, parseWalletError, TWeb3Instance, wallet, web3,
} from 'services';
import {
  ADDRESSES, CHAIN_NAMES, CHAIN_PARAMS, config,
} from 'config';

import { Address, BigNumberZero } from 'types/web3';

import { EBalances, IWalletState } from './types';
import { RootState } from '../index';

const { localChainId } = config;

const initialState: IWalletState = {
  balances: {
    [EBalances.chainCurrencyBalance]: BigNumberZero,
    [EBalances.wETH]: BigNumberZero,
    [EBalances.ATTR]: BigNumberZero,
    [EBalances.lockedATTR]: BigNumberZero,
  },
  web3Instance: undefined,
  walletChainId: 0,
  account: '',
  isSwitchNetworkRequired: false,
};

export const connectWallet = createAsyncThunk('wallet/connectWallet', async () => {
  try {
    return await wallet.connect();
  } catch (e) {
    if (typeof e === 'string') {
      return Promise.reject(Error(e));
    }
    const error = e as Error;
    return Promise.reject(parseWalletError(error));
  }
});

export const disconnectWallet = createAsyncThunk('wallet/disconnectWallet', async () => {
  try {
    return wallet.disconnect();
  } catch (e) {
    if (typeof e === 'string') {
      return Promise.reject(Error(e));
    }
    const error = e as Error;
    return Promise.reject(parseWalletError(error));
  }
});

export const {
  actions, reducer,
} = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWeb3Instance(state, action: PayloadAction<TWeb3Instance>) {
      state.web3Instance = action.payload;
    },
    setAccount(state, action: PayloadAction<Address>) {
      state.account = action.payload;
    },
    setWalletChainId(state, action: PayloadAction<number>) {
      state.walletChainId = action.payload;
    },

    setBalance(state, action: PayloadAction<{ coin: EBalances, value: BigNumber }>) {
      const {
        coin, value,
      } = action.payload;
      state.balances[coin] = value;
    },

    setSwitchNetworkRequired(state, action: PayloadAction<boolean>) {
      state.isSwitchNetworkRequired = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectWallet.fulfilled, (state, action) => {
      const {
        web3Instance, account, chainId,
      } = action.payload;

      state.web3Instance = web3Instance;
      state.account = account;
      state.walletChainId = chainId;
    });
    builder.addCase(disconnectWallet.fulfilled, (state) => {
      state.account = '';
    });
  },
});

const getBalances = createAsyncThunk<void, void, { state: RootState }>(
  'wallet/getBalances',
  async (prop, {
    getState, dispatch,
  }) => {
    const { account } = getState().wallet;
    if (account) {
      const provider = web3.getReaderProvider(localChainId);

      const {
        WETH, ATTR,
      } = ADDRESSES[CHAIN_NAMES[localChainId]];

      try {
        Promise.all([
          contracts.erc20.balanceOf(WETH, account, provider),
          contracts.erc20.balanceOf(ATTR, account, provider),
          // only call getLockedTokens function on mainnet as it is not existent on test token contract
          CHAIN_NAMES[localChainId] === 'mainnet' ? contracts.attrtoken.getLockedTokens(ATTR, account, provider) : BigNumberZero,
          provider.getBalance(account),
        ]).then(([balanceWETH, balanceATTR, lockedATTR, chainBalance]) => {
          dispatch(actions.setBalance({
            coin: EBalances.wETH,
            value: balanceWETH,
          }));
          dispatch(actions.setBalance({
            coin: EBalances.ATTR,
            value: balanceATTR,
          }));
          dispatch(actions.setBalance({
            coin: EBalances.lockedATTR,
            value: lockedATTR,
          }));
          dispatch(actions.setBalance({
            coin: EBalances.chainCurrencyBalance,
            value: chainBalance,
          }));
        })
          .catch((err) => {
            console.log('err', err);
          });
      } catch (e) {
        console.log('err', e);
      }
    }
  },
);

export const switchNetwork = createAsyncThunk<void, number, { state: RootState }>(
  'wallet/switchNetwork',
  async (chainId: number, {
    getState, dispatch,
  }) => {
    const { web3Instance } = getState().wallet;
    if (!web3Instance) {
      throw Error('Connect wallet to proceed.');
    }

    const chainParams = CHAIN_PARAMS[chainId];
    try {
      await web3Instance.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: chainParams.chainId,
          },
        ],
      });
      dispatch(actions.setWalletChainId(chainId));
      return Promise.resolve();
    } catch (error) {
      if ((error as { code: number }).code === METAMASK_ERROR_CODE.noChain) {
        try {
          await web3Instance.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...chainParams,
              },
            ],
          });

          dispatch(actions.setWalletChainId(chainId));
          return Promise.resolve();
        } catch (addChainError) {
          return Promise.reject(parseWalletError(addChainError as Error));
        }
      } else {
        return Promise.reject(parseWalletError(error as Error));
      }
    }
  },
);

export const thunkActions = {
  connectWallet,
  disconnectWallet,
  switchNetwork,
  getBalances,
};
