import { useQuery } from 'react-query';

import { dex } from 'services';

import { Address } from 'types/web3';

export const useGetDexesPool = (token: Address): {
    checkTokenPoolExist: () => void,
    poolExists: Array<boolean>,
} => {
  const {
    refetch, data,
  } = useQuery(['dex-poll', token], async () => {
    try {
      const uniswapV2PollExist = await dex.checkUniswapV2TokenPoolExist(token);
      const uniswapV3PollExist = await dex.checkUniswapV3TokenPoolExist(token);
      const sushiswapV2PollExist = await dex.checkSushiswapV2TokenPoolExist(token);

      return [uniswapV2PollExist, uniswapV3PollExist, sushiswapV2PollExist];
    } catch (e) {
      return [false, false, false];
    }
  }, {
    enabled: true,
    refetchOnWindowFocus: false,
  });

  return {
    checkTokenPoolExist: refetch,
    poolExists: data || [],
  };
};
