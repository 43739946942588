import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '../components';
import { PoweredBy } from './poweredBy';

import styles from './styles.module.css';
import { useSetTheme } from '../useSetTheme';

const PartnerLayout: FC = () => {
  useSetTheme();

  return (
    <div className={styles.wrapper}>
      <Header
        mainMenuHidden
        menuDropdownHidden
        connectBtnVariant="primary"
      >
        <PoweredBy />
      </Header>
      <main className={styles.content}>
        <Outlet />
      </main>
    </div>
  );
};

export default PartnerLayout;
