import { FC, ReactNode } from 'react';
import { cx } from '@emotion/css';

import {
  TelegramIcon, TwitterIcon, DiscordIcon, List, ListItem,
} from 'components';
import { config } from 'config';

import attrFooterLogo from 'assets/images/attrFooterLogo.svg';
import styles from './footer.module.css';

interface IFOOTER_LISTS {
    label: string;
    className: string,
    items: {
      content: string | ReactNode;
      link: string;
    }[]
}

const { homePageUrl } = config;

const FOOTER_LISTS: IFOOTER_LISTS[] = [
  {
    label: 'About',
    className: styles.about,
    items: [
      {
        content: 'Attrace Protocol',
        link: `${homePageUrl}/about`,
      },
      {
        content: 'Referral Farming',
        link: `${homePageUrl}/about/referral-farming/`,
      },
      {
        content: 'Oracles',
        link: `${homePageUrl}/about/oracles/`,
      },
      {
        content: 'Tokenomics',
        link: `${homePageUrl}/about/tokenomics`,
      },
    ],
  },
  {
    label: 'Guides',
    className: styles.guides,
    items: [
      {
        content: 'How it works',
        link: `${homePageUrl}/guides/referral-farming/how-it-works`,
      },
      {
        content: 'FAQ',
        link: `${homePageUrl}/guides/faq`,
      },
      {
        content: 'Glossary',
        link: `${homePageUrl}/guides/glossary`,
      },
      {
        content: 'Updates',
        link: `${homePageUrl}/updates`,
      },
    ],
  },
  {
    label: 'Community',
    className: styles.community,
    items: [{
      content: (
        <>
          <TelegramIcon opacity=".6" />
          <span className={styles.socialLink}>Telegram</span>
        </>
      ),
      link: 'https://t.me/attrace',
    }, {
      content: (
        <>
          <DiscordIcon opacity=".6" />
          <span className={styles.socialLink}>Discord</span>
        </>
      ),
      link: 'https://discord.com/invite/WSX2RufvbV',
    }, {
      content: (
        <>
          <TwitterIcon opacity=".6" />
          <span className={styles.socialLink}>Twitter</span>
        </>
      ),
      link: 'https://twitter.com/attracenetwork',
    },
    ],
  },
];

export const Footer: FC = () => (
  <footer className={styles.footer}>
    <div className={styles.left}>
      <div className={cx('flex-align-center', styles.footerLogo)}>
        <img
          src={attrFooterLogo}
          alt="Attrace"
          className={styles.logo}
          loading="lazy"
        />
        <div className={styles.logoText}>
          <h4>Attrace</h4>
          <p>Referral Protocol for Web3</p>
        </div>
      </div>
    </div>
    <div className={styles.right}>
      {FOOTER_LISTS.map(({
        label, items, className,
      }) => (
        <List
          key={label}
          label={label}
          labelClassName={styles.listLabel}
        >
          <nav className={cx(styles.links, className)}>
            {items.map(({
              content, link,
            }) => (
              <a
                key={link}
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                <ListItem>
                  {content}
                </ListItem>
              </a>
            ))}
          </nav>
        </List>
      ))}
    </div>
  </footer>
);
