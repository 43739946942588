import { CSSProperties, FC } from 'react';
import {
  AccordionSummary as MUIAccordionSummary, styled, AccordionSummaryProps,
} from '@mui/material';

const AccordionChildStyled = styled(MUIAccordionSummary)<AccordionSummaryProps>(() => ({
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const DefaultAccordionSummary = styled(AccordionChildStyled)`
  padding: 0 24px;
  height: 72px;
  @media(max-width: 768px) {
    height: auto;
    padding: 0;
  }
`;

export const AccordionSummary: FC<AccordionSummaryProps & { customStyles?: CSSProperties}> = ({
  children,
  customStyles,
  ...props
}) => (
  <DefaultAccordionSummary
    {...props}
    style={customStyles}
  >
    {children}
  </DefaultAccordionSummary>
);
