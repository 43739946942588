import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import {
  Header, Footer, Notification, Modals,
} from '../components';
import { useSetTheme } from '../useSetTheme';

import styles from './styles.module.css';

const BasicLayout: FC = () => {
  useSetTheme();

  return (
    <>
      <Notification />
      <Modals />
      <div className={styles.wrapper}>
        <Header />
        <main className={styles.content}>
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default BasicLayout;
