import { FC } from 'react';
import { cx } from '@emotion/css';

import { TokenIcon } from 'modules/shared';
import { ERC20Token } from 'types';

import styles from './styles.module.css';

interface IReferralCard {
  token: ERC20Token;
  tokenIconSize?: 'lg' | 'md' | 'sm' | 'xs';
}

export const ReferralCard: FC<IReferralCard> = ({
  token, tokenIconSize,
}) => (
  <div className="flex-align-center">
    <TokenIcon
      token={token}
      size={tokenIconSize}
    />
    <div className={cx(styles.nameWrapper, 'flex-column')}>
      <p className={styles.name}>{token.symbol}</p>
      <p className={styles.subname}>{token.name}</p>
    </div>
  </div>
);
