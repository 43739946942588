import { FC, ReactNode } from 'react';

import {
  BulbIcon, Modal, ModalContentCard,
} from 'components';

import { FarmCard } from 'modules/shared';

import { selectReferralFarmsValues, useReferralFarmsStore } from '../store';
import { AprTooltip } from '../aprTooltip';
import { IReferralFarmsFarmRow } from '../../types';

import styles from './selectedFarmModal.module.css';

interface ISelectedFarmModal {
  title: string;
  subtitle: string;
  hint: ReactNode;
  isOpen: boolean;
  selectedFarm: IReferralFarmsFarmRow;
  onCloseModal: () => void;
  footerBtn: ReactNode;
  columnAprLabel?: string;
  tooltips?: {
    apr?: string,
    daily?: string,
    total?: string,
    estimatedDailyRewards?: ReactNode[]
  };
}

export const SelectedFarmModal: FC<ISelectedFarmModal> = ({
  title,
  subtitle,
  hint,
  isOpen,
  selectedFarm,
  onCloseModal,
  footerBtn,
  columnAprLabel,
  tooltips,
}) => {
  const {
    referToken, rewardsTokens, APRs,
  } = selectedFarm;

  const { type } = useReferralFarmsStore(selectReferralFarmsValues);

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      title={title}
      subtitle={(<p className={styles.subTitle}>{subtitle}</p>)}
      footer={(
        <div className={styles.footerBtn}>
          {footerBtn}
        </div>
      )}
      classNameContent={styles.modalContent}
    >
      <ModalContentCard>
        <FarmCard
          columnAprLabel={columnAprLabel}
          referToken={referToken}
          rewardsTokens={rewardsTokens}
          APRs={APRs}
          tooltips={{
            ...tooltips,
            estimatedDailyRewards: selectedFarm.estimatedDailyRewards.map((reward, idx) => (
              <AprTooltip
                key={reward}
                estimatedDailyReward={reward}
                rewardTokenSymbol={rewardsTokens[idx].tokenSymbol}
                referredTokenSymbol={referToken.symbol}
                pageType={type}
                withLastSection={false}
              />
            )),
          }}
        />
      </ModalContentCard>

      <div className={styles.hint}>
        <BulbIcon
          size="20"
          opacity=".7"
        />
        {hint}
      </div>
    </Modal>
  );
};
