import fetchRetry, { RequestInitWithRetry } from 'fetch-retry';
import { config } from 'config';

const { requestsRetryCount } = config;

const retryFetch = fetchRetry(fetch);

export const customFetch = (url: string, init?: RequestInitWithRetry): Promise<Response> => retryFetch(url, {
  retries: requestsRetryCount,
  ...init,
});
