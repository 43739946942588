import { FC } from 'react';
import { cx } from '@emotion/css';

import styles from './table.module.css';

interface ITableHead {
  className?: string;
}
export const TableHead: FC<ITableHead> = ({
  children, className,
}) => (
  <div className={cx(styles.tableHeader, className)}>
    {children}
  </div>
);
