import { FC, ReactNode } from 'react';

import styles from './noContentTableBody.module.css';

interface INoContentTableBody {
  title: string | ReactNode;
  subtitle: string;
  icon?: string;
  btn?: ReactNode;
}

export const NoContentTableBody: FC<INoContentTableBody> = ({
  title, subtitle, icon, btn,
}) => (
  <div className={styles.noContentWrapper}>
    {icon && (
      <img
        src={icon}
        alt="no-content"
        loading="lazy"
      />
    ) }
    <h2>{title}</h2>
    <span>{subtitle}</span>
    {btn}
  </div>
);
