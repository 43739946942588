/* eslint-disable max-len */
import { orderBy } from 'lodash';
import { utils } from 'ethers';
import { FormatTypes } from '@ethersproject/abi';

import { address } from 'utils';
import {
  RpcOracleMethod, RpcRoute, rpc,
  logger,
} from 'services';
import { config } from 'config';
import { TNodeUrl } from 'api';
import {
  Entitlement, ILockedEntitlement, IGetAccountEntitlementsRes,
} from './types';

const { oracleChainId } = config;

const abi = [
  'event Entitlement(bytes32 indexed farmHash, address indexed entitlee, uint128 confirmation, uint128 rewardValue, bytes32[] proof)',
  'event LockedEntitlement(bytes32 indexed farmHash, address indexed entitlee, uint128 confirmation, uint128 rewardValue, uint64 unlockTime)',
];

const iface = new utils.Interface(abi);

function parseEntitlementEvents(items: IGetAccountEntitlementsRes[]): any[] {
  const itemsSorted = orderBy(items, ['chainId', 'blockNumber', 'logIndex'], ['asc', 'asc', 'asc']);
  const parsed = itemsSorted.map(
    (row) =>
      iface.parseLog({
        data: row.data,
        topics: row.topics,
      }).args,
  );
  return parsed;
}

interface IGetAccountEntitlements {
  topics: string[];
  chainId: number[];
}

export async function getAccountEntitlements(account: string, host: TNodeUrl): Promise<Entitlement[]> {
  try {
    const topics = [
      utils.id(iface.getEvent('Entitlement').format(FormatTypes.sighash)),
      '',
      address.expandEvmAddressToBytes32(account),
    ];

    const { result } = await rpc.call<IGetAccountEntitlements[], IGetAccountEntitlementsRes[]>(
      `${host}${RpcRoute.rpc}`,
      RpcOracleMethod.oracle_getLogs,
      [{
        topics,
        chainId: [oracleChainId],
      }],
    );

    if (result) {
      return parseEntitlementEvents(result);
    }

    return [];
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'entitlements.getAccountEntitlements',
    });
    return [];
  }
}

export async function getAccountLockedEntitlements(account: string, host: TNodeUrl): Promise<ILockedEntitlement[]> {
  try {
    const topics = [
      utils.id(iface.getEvent('LockedEntitlement').format(FormatTypes.sighash)),
      '',
      address.expandEvmAddressToBytes32(account),
    ];

    const { result } = await rpc.call<IGetAccountEntitlements[], IGetAccountEntitlementsRes[]>(
      `${host}${RpcRoute.rpc}`,
      RpcOracleMethod.oracle_getLogs,
      [{
        topics,
        chainId: [Number(oracleChainId)],
      }],
    );

    if (result) {
      return parseEntitlementEvents(result);
    }

    return [];
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'entitlements.getAccountLockedEntitlements',
    });
    return [];
  }
}
