import { FC } from 'react';
import { cx } from '@emotion/css';

import avatar from 'assets/images/avatar.svg';
import styles from './ProfileIcon.module.css';

interface IProfileIcon {
   icon?: string;
   className?: string;
}

export const ProfileIcon: FC<IProfileIcon> = ({
  icon = avatar, className,
}) => (
  <img
    className={cx(styles.profileIcon, className)}
    src={icon}
    alt="profile"
    loading="lazy"
  />
);
