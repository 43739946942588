import { JsonRPCError } from './types';

const ORACLE_ERRORS = {
  rpc: 'Failed to fetch',
  referrerInvalid: 'referrer invalid',
};

export function parseRpcError(error: JsonRPCError): Error {
  switch (error.message) {
    case ORACLE_ERRORS.rpc:
      return {
        ...error,
        message: 'Oracle is not responding at the moment. Please try in a few minutes.',
      };
    case ORACLE_ERRORS.referrerInvalid:
      return {
        ...error,
        message: 'Referrer cannot be the same as Buyer.',
      };
    default:
      return error;
  }
}
