import { FC, SyntheticEvent } from 'react';
import { styled } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const Icon = styled('span')((props: {disabled?: boolean}) => ({
  border: '1px solid var(--grey-60)',
  borderColor: props?.disabled ? 'var(--grey-30)' : 'var(--grey-60)',
  borderRadius: '50%',
  background: 'transparent',
  width: 16,
  height: 16,
  boxShadow: 'none',
  'input:hover ~ &': {
    backgroundColor: props?.disabled ? 'var(--transparent)' : 'var(--grey-11)',
  },
}));

const CheckedIcon = styled(Icon)({
  border: '1px solid var(--purple)',
  '&:before': {
    display: 'block',
    width: 6,
    height: 6,
    background: 'var(--purple)',
    borderRadius: '50%',
    content: '""',
    margin: '4px 0 4px 4px',
  },
});

const FormControlCustom = styled(FormControlLabel)({
  marginLeft: 0,
  marginRight: '20px',
  '& .MuiTypography-root': {
    fontSize: '1.4rem',
    lineHeight: '2.2rem',
    letterSpacing: '0.15px',
    color: 'var(--white-70)',
    margin: 0,
  },
  '& .MuiTypography-root.Mui-disabled': {
    color: 'var(--white-30)',
  },
});

const RadioCustom: FC<RadioProps> = (props) => (
  <Radio
    sx={{
      padding: '0px 8px 0 0',
    }}
    disableRipple
    color="default"
    checkedIcon={<CheckedIcon />}
    icon={<Icon disabled={props.disabled} />}
    {...props}
  />
);

interface IRadioButton {
  value: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  handleChange: (event: SyntheticEvent<Element, Event>, checked: boolean) => void
}

export const RadioButton: FC<IRadioButton> = ({
  value, label, checked, handleChange, disabled,
}) => (
  <FormControlCustom
    value={value}
    control={(<RadioCustom />)}
    label={label}
    onChange={handleChange}
    checked={checked}
    disabled={disabled}
  />
);
