import {
  createContext, FC, Reducer, useCallback, useContext, useReducer,
} from 'react';

import {
  IState, ITheme, IThemeContext, ETheme,
} from './types';
import {
  actions, TAction, themeReducer,
} from './themeReducer';

const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

const initState: IState = {
  name: '',
  farmName: '',
  logo: '',
  mobileLogo: '',
  theme: ETheme.default,
  routes: {
    myRewards: '',
  },
  icons: {
    noRewards: '',
  },
  isDefaultTheme: false,
};

export const ThemeProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<IState, TAction>>(themeReducer, initState);

  const setTheme = useCallback((partnerTheme: ITheme) => {
    dispatch(actions.setTheme(partnerTheme));
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        ...state,
        setTheme,
        isDefaultTheme: state.theme === ETheme.default,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = (): IThemeContext => useContext(ThemeContext);
