export type TNodeUrl = string;

export type Node = {
  url: TNodeUrl;
  location: Geolocation;
};
export type Geolocation = {
  lat: number;
  lon: number;
};
export interface IDiscoveryRes<T> {
  data: T;
  pop: string;
}

type TContract = {
  chainId: number;
  address: string;
}

export interface IOracle extends Node {
  chainId: number;
}

export interface IDiscoveryChainInfo {
  chainId: number;
  authority: TContract;
  pacemaker: string; // target network chainId
  referralFarmsV1: TContract[];
  confirmationsV1: TContract[];
  oracles: IOracle[];
}

export interface IDiscoveryChainData {
  chainInfo: IDiscoveryChainInfo
}

export interface IDiscoveryTokenList {
  tokenLists: {
    [chainId: string]: {
      origin: string;
      url: string;
    }[];
  };
}

export enum EDiscoveryFileName {
  'tokenLists' = 'tokenLists',
  'chains' = 'chains',
  'chains/147' = 'chains/147',
  'chains/5470' = 'chains/5470',
}
export type TDiscoveryFileName = keyof typeof EDiscoveryFileName
