import { FC } from 'react';

import { IIcon } from './types';

export const DiscordIcon: FC<IIcon> = ({ opacity }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
  >
    <path
      d="M19.6239 4.89005C18.2217 4.24665 16.7181 3.77263 15.1459 3.50114C15.1173 3.4959 15.0887 3.50899
      15.0739 3.53518C14.8805 3.87913 14.6663 4.32784 14.5163 4.68052C12.8254 4.42736 11.1431 4.42736 9.48679
      4.68052C9.33676 4.32 9.11478 3.87913 8.92053 3.53518C8.90578 3.50987 8.87718 3.49677 8.84855
      3.50114C7.27725 3.77176 5.7736 4.24579 4.37052 4.89005C4.35838 4.89528 4.34797 4.90402 4.34106
       4.91536C1.48894 9.17636 0.707629 13.3326 1.09092 17.4374C1.09265 17.4574 1.10392 17.4766 1.11953
        17.4889C3.00127 18.8708 4.82406 19.7097 6.61301 20.2658C6.64164 20.2745 6.67197 20.264 6.69019
        20.2405C7.11337 19.6626 7.49059 19.0532 7.81402 18.4125C7.83311 18.3749 7.81489 18.3304 7.77588
        18.3156C7.17754 18.0886 6.6078 17.8119 6.05975 17.4976C6.0164 17.4723 6.01293 17.4103 6.05281
        17.3806C6.16814 17.2942 6.2835 17.2043 6.39363 17.1135C6.41355 17.0969 6.44131 17.0934 6.46474
        17.1039C10.0652 18.7477 13.9631 18.7477 17.521 17.1039C17.5445 17.0925 17.5722 17.096 17.593
        17.1126C17.7032 17.2034 17.8185 17.2942 17.9347 17.3806C17.9746 17.4103 17.972 17.4723 17.9286
        17.4976C17.3806 17.818 16.8108 18.0886 16.2116 18.3147C16.1726 18.3295 16.1553 18.3749 16.1744
        18.4125C16.5047 19.0523 16.882 19.6617 17.2973 20.2396C17.3147 20.264 17.3459 20.2745 17.3745
        20.2658C19.1721 19.7097 20.9949 18.8708 22.8766 17.4889C22.8931 17.4766 22.9035 17.4583 22.9053
        17.4382C23.364 12.6927 22.1369 8.57052 19.6525 4.91623C19.6465 4.90402 19.6361 4.89528 19.6239
        4.89005ZM8.35169 14.938C7.26771 14.938 6.37454 13.9428 6.37454 12.7207C6.37454 11.4985 7.25039
        10.5033 8.35169 10.5033C9.46163 10.5033 10.3462 11.5072 10.3288 12.7207C10.3288 13.9428 9.45296
        14.938 8.35169 14.938ZM15.6619 14.938C14.5779 14.938 13.6847 13.9428 13.6847 12.7207C13.6847
        11.4985 14.5606 10.5033 15.6619 10.5033C16.7718 10.5033 17.6563 11.5072
        17.639 12.7207C17.639 13.9428 16.7718 14.938 15.6619 14.938Z"
      fill="white"
    />
  </svg>

);
