import { TTableColumns } from 'types';

export enum EReferralFarmsPageType {
    referralFarming = 'referralFarming',
    buyToEarn = 'buyToEarn'
}

export interface IReferralFarmsState {
    values: {
        pageTitle: string,
        pageSubtitle: string,
        columns: TTableColumns;
        type: EReferralFarmsPageType | undefined
        farmCTALabel: string,
    }
    actions: {
        setReferralFarmPageVariant: (variant: EReferralFarmsPageType) => void;
    }
}
