import { FC, useMemo } from 'react';

import { TAvailableDexList } from 'services';

import { Steps } from '../../steps';

import { REFER_TO_EARN_STEPS_SUCCESSFUL } from '../../../config';
import { ReferToFarmSuccessfulCopyLink } from './referToFarmSuccessfulCopyLink';

import styles from './referToFarmSuccessfull.module.css';

interface IReferToFarmSuccessfulContent {
  successfulStepId: number,
  availableDexList: TAvailableDexList
  links: {[key: string]: string};
  onChangeLinkCopied: () => void;
}

export const ReferToFarmSuccessfulContent: FC<IReferToFarmSuccessfulContent> = ({
  links, successfulStepId, availableDexList, onChangeLinkCopied,
}) => {
  const stepContent = useMemo(
    () => {
      switch (successfulStepId) {
        case REFER_TO_EARN_STEPS_SUCCESSFUL[0].id:
          return (
            <ReferToFarmSuccessfulCopyLink
              availableDexList={availableDexList}
              links={links}
              onChangeLinkCopied={onChangeLinkCopied}
            />
          );
        case REFER_TO_EARN_STEPS_SUCCESSFUL[1].id:
          return (
            <div className={styles.stepContentText}>
              Share your referral links with friends and community
              <br />
              {' '}
              via social media 📣
            </div>
          );
        case REFER_TO_EARN_STEPS_SUCCESSFUL[2].id:
        default:
          return (
            <div className={styles.stepContentText}>
              Earn a share of daily rewards for the tokens purchased
              via your referral links. Harvest the rewards in My Rewards 💰
            </div>
          );
      }
    },
    [successfulStepId, availableDexList, links, onChangeLinkCopied],
  );

  return (
    <div className={styles.content}>
      <div className={styles.contentExtra}>
        <Steps
          steps={REFER_TO_EARN_STEPS_SUCCESSFUL}
          successfulStepId={successfulStepId}
        />
        {stepContent}
      </div>
    </div>
  );
};
