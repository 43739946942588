import {
  LinkIcon, RewardsIcon, ShareIcon, SuccessIcon, TractorIcon,
} from 'components';

import { TTableColumns } from 'types';

export const REFER_TO_EARN_TOOLTIPS = {
  apr: `Referral Rate is an annualised rate you earn for the amount of tokens purchased via your referral link. 
    This is an estimated rate which changes depending on the total amount of tokens purchased via all referral
    links (total amount in the farm). For example, the higher the total amount purchased, the lower the referral
    rate and vice versa. This same rate is also earned by people you refer.`,
  daily: `Rewards distributed daily among all the referrers and buyers. You and the referred buyers earn
     a cut of daily rewards based on the share of tokens puchased via your referral link compared to the 
     total amount of tokens purchased via all referral links.`,
  total: `Total amount of the remaining reward tokens out of which the daily farm rewards are paid out. 
      The daily farm rewards will stop being paid when there are no more remaining rewards.`,
};

export const BUY_TO_EARN_TOOLTIPS = {
  ...REFER_TO_EARN_TOOLTIPS,
  apr: `Annualised Percentage Rate is an annualised rate you earn for the amount of tokens purchased via your referral link. 
    This is an estimated rate which changes depending on the total amount of tokens purchased via all referral
    links (total amount in the farm). For example, the higher the total amount purchased, the lower the referral
    rate and vice versa. This same rate is also earned by people you refer.`,
};

export const REFER_TO_EARN_COLUMNS: TTableColumns = [
  {
    key: 'referred_Token',
    header: 'Referred Token',
    width: '29%',
  },
  {
    key: 'daily_rewards',
    header: 'Total Daily Rewards',
    width: '19%',
    tooltip: REFER_TO_EARN_TOOLTIPS.daily,
  },
  {
    key: 'total_rewards',
    header: 'Total Farm Rewards',
    width: '19%',
    tooltip: REFER_TO_EARN_TOOLTIPS.total,
  },
  {
    key: 'est_apr',
    header: 'Referral Rate (est.)',
    width: '19%',
    tooltip: REFER_TO_EARN_TOOLTIPS.apr,
  },
  {
    key: 'refer_to_earn',
    header: '',
    width: '14%',
  },
];

export const BUY_TO_EARN_COLUMNS: TTableColumns = [
  {
    key: 'referred_Token',
    header: 'Referred Token',
    width: '29%',
  },
  {
    key: 'daily_rewards',
    header: 'Total Daily Rewards',
    width: '19%',
    tooltip: BUY_TO_EARN_TOOLTIPS.daily,
  },
  {
    key: 'total_rewards',
    header: 'Total Farm Rewards',
    width: '19%',
    tooltip: BUY_TO_EARN_TOOLTIPS.total,
  },
  {
    key: 'est_apr',
    header: 'Estimated APR',
    width: '19%',
    tooltip: BUY_TO_EARN_TOOLTIPS.apr,
  },
  {
    key: 'refer_to_earn',
    header: '',
    width: '14%',
  },
];

export const REFER_TO_EARN_STEPS_SUCCESSFUL = [
  {
    id: 1,
    text: 'Copy your link',
    icon: <LinkIcon />,
  },
  {
    id: 2,
    text: 'Spread the word',
    icon: <ShareIcon />,
  },
  {
    id: 3,
    text: 'Earn daily rewards ',
    icon: <RewardsIcon />,
  },
];

export const BUY_TO_EARN_STEPS_SUCCESSFUL = [
  {
    id: 1,
    text: 'Buy tokens to join the referral farm',
    icon: <SuccessIcon
      opacity=".7"
      fillColor="var(--white)"
    />,
  },
  {
    id: 2,
    text: 'Hold tokens to earn daily rewards',
    icon: <RewardsIcon />,
  },
  {
    id: 3,
    text: 'Harvest your rewards',
    icon: <TractorIcon />,
  },
];
