import { FC } from 'react';

const CopyIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M19 5.5C19 5.22386 18.7761 5 18.5 5H8C7.44772 5 7 4.55228 7 4C7 3.44772 7.44772 3 8 3H20C20.5523 3 21 3.44772 21 4V16C21 16.5523 20.5523 17 20 17C19.4477 17 19 16.5523 19 16V5.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8C3 7.44772 3.44772 7 4 7H16C16.5523 7 17 7.44772 17 8V20C17 20.5523 16.5523 21 16 21H4C3.44772 21 3 20.5523 3 20V8ZM5 9.5C5 9.22386 5.22386 9 5.5 9H14.5C14.7761 9 15 9.22386 15 9.5V18.5C15 18.7761 14.7761 19 14.5 19H5.5C5.22386 19 5 18.7761 5 18.5V9.5Z"
        fill="white"
      />
    </g>
  </svg>

);

export default CopyIcon;
