import {
  ReactText, useCallback, useRef,
} from 'react';
import { toast } from 'services';

interface IUseFinisActionWarn {
  showFinishActionWarn: () => void;
  dismissFinishActionWarn: () => void;
}

export const useFinishActionWarn = (): IUseFinisActionWarn => {
  const buyFarmToastId = useRef<ReactText>('');

  const showFinishActionWarn = useCallback((): void => {
    buyFarmToastId.current = toast.toastWarn({
      component: 'Go to your wallet to complete this action',
      style: {
        marginTop: 0,
      },
    });
  }, []);

  const dismissFinishActionWarn = useCallback((): void => {
    if (buyFarmToastId.current) {
      toast.dismissToast(buyFarmToastId.current);
    }
  }, []);

  return {
    showFinishActionWarn,
    dismissFinishActionWarn,
  };
};
