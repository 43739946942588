import { FC } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export const FormattedNumber: FC<NumberFormatProps> = ({
  displayType = 'text',
  decimalScale = 2,
  prefix,
  suffix,
  ...rest
}) => (
  <>
    {prefix && (
      <span>
        {prefix}
      </span>
    )}
    <NumberFormat
      displayType={displayType}
      thousandSeparator
      decimalScale={decimalScale}
      {...rest}
    />
    {suffix && (
      <span>
        {suffix}
      </span>
    )}
  </>
);
