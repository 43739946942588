import { FC } from 'react';

import { IIcon } from './types';

export const TwitterIcon: FC<IIcon> = ({ opacity = '1' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
  >
    <g clipPath="url(#clip0_364_5392)">
      <path
        d="M23.9717 5.00445C23.0869 5.39725 22.1386 5.66098 21.1417 5.78069C22.1592 5.17092 22.941 4.20576 23.3077
        3.05729C22.3575 3.6203 21.3006 4.02993 20.1802 4.25065C19.2824 3.29297 18.003 2.69629 16.5871 2.69629C13.8674
        2.69629 11.6621 4.8997 11.6621 7.61937C11.6621 8.00468 11.707 8.38065 11.7912 8.74165C7.69859 8.5359 4.07175
        6.57564 1.64201 3.59599C1.21741 4.32547 0.976122 5.17092 0.976122 6.07249C0.976122 7.78023 1.84402 9.28783
        3.16644 10.1726C2.3584 10.1464 1.59899 9.92379 0.934972 9.5553C0.934972 9.57588 0.934972 9.59645 0.934972
        9.61703C0.934972 12.0037 2.63336 13.9921 4.88353 14.4466C4.47203 14.5588 4.03621 14.6187 3.5873
        14.6187C3.26932 14.6187 2.96069 14.5887 2.65954 14.5289C3.28802 16.4835 5.10612 17.9107 7.25903 17.95C5.57561
        19.2705 3.45262 20.0561 1.14446 20.0561C0.746054 20.0561 0.355126 20.0337 -0.0283203 19.9869C2.14891 21.386
        4.73764 22.1997 7.51903 22.1997C16.5758 22.1997 21.5288 14.6972 21.5288 8.19173C21.5288 7.9785 21.5232 7.76339
        21.5158 7.5539C22.4772 6.85996 23.3114 5.99206 23.9717 5.00445Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_364_5392">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>

);
