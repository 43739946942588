import { utils } from 'ethers';

import { EDapp, config } from 'config';
import {
  rpc, RecommendationsRpcOracleMethod, RpcRoute, JsonRpcResponse,
} from 'services';

import { TNodeUrl } from 'api';
import { EvmAddress, Web3Provider } from 'types/web3';

import {
  DTOGetTimeSignature,
  IGetTimeSignatureRes,
  ISignAndPostProofOfRecommendationOrigin,
  ISignAndPostProofOfRecommendationWithReferrer,
  ITimeSignatureProps,
  ZERO_ADDR,
  ZERO_HASH,
} from './types';

const { porRouter } = config;

export async function getTimeSignature(props: ITimeSignatureProps): Promise<IGetTimeSignatureRes> {
  const {
    account, tokenAddress, referrer, dapp, router, host,
  } = props;

  const { result } = await rpc.call<string[], DTOGetTimeSignature>(
    `${host}${RpcRoute.recommendations}`,
    RecommendationsRpcOracleMethod.oracle_getTimePromise,
    [account, tokenAddress, referrer, dapp, router],
  );

  return {
    time: Number(result?.time),
    timePromise: result?.sig || '',
  };
}

export function makePreEip721ProofOfRecommendationOrigin(
  promoter: string,
  token: EvmAddress,
  currentTime: number,
  currentRouter: string,
): string {
  return [
    'Sign this message to register for rewards.',
    '',
    "This won't cost you any Ether.",
    '',
    `Signer: ${promoter.toLowerCase()}`,
    `Token: ${token.toLowerCase()}`,
    `Time: ${currentTime}`,
    '',
    `Context: ${currentRouter.toLowerCase()}`,
  ].join('\n');
}

// create proof by origin(PROMOTER), referrer = ZERO_ADDR
export async function signAndPostProofOfRecommendationOrigin(
  account: string,
  tokenAddress: EvmAddress,
  host: TNodeUrl,
  provider: Web3Provider,
): Promise<JsonRpcResponse> {
  const signer = provider.getSigner();

  const {
    time, timePromise,
  } = await getTimeSignature({
    account,
    tokenAddress,
    referrer: ZERO_ADDR,
    dapp: ZERO_HASH,
    router: porRouter,
    host,
  });

  const sig = await signer.signMessage(makePreEip721ProofOfRecommendationOrigin(
    account, tokenAddress, time, porRouter,
  ));

  // Post signed proof of recommendation which has a time promise from the store.
  return rpc.call<ISignAndPostProofOfRecommendationOrigin[]>(
    `${host}${RpcRoute.recommendations}`,
    RecommendationsRpcOracleMethod.oracle_sendProofOfRecommendationOrigin,
    [
      {
        signer: utils.getAddress(account),
        token: utils.getAddress(tokenAddress),
        router: utils.getAddress(porRouter),
        time,
        sig,
        timePromises: [timePromise],
      },
    ],
  );
}

export function makePreEip721ProofOfRecommendation(
  signer: string,
  token: EvmAddress,
  time: number,
  dapp: string,
  referrer: string,
  router: string,
): string {
  return [
    'Sign this message to register for rewards.',
    '',
    "This won't cost you any Ether.",
    '',
    `Signer: ${signer.toLowerCase()}`,
    `Token: ${token.toLowerCase()}`,
    `Time: ${time}`,
    '',
    `Context: ${dapp},${referrer.toLowerCase()},${router.toLowerCase()}`,
  ].join('\n');
}

// create proof by PARTICIPANT, referrer = PROMOTER_ADDRESS
export async function signAndPostProofOfRecommendationWithReferrer(
  account: string,
  tokenAddress: EvmAddress,
  referrer: EvmAddress,
  dappName: EDapp,
  host: TNodeUrl,
  provider: Web3Provider,
): Promise<JsonRpcResponse> {
  const signer = provider.getSigner();
  // The link destination dapp we are linking to
  const dapp = utils.formatBytes32String(dappName);

  const {
    time, timePromise,
  } = await getTimeSignature({
    account,
    tokenAddress,
    referrer,
    dapp,
    router: porRouter,
    host,
  });

  const sig = await signer.signMessage(
    makePreEip721ProofOfRecommendation(account, tokenAddress, time, dapp, referrer, porRouter),
  );

  return rpc.call<ISignAndPostProofOfRecommendationWithReferrer[]>(
    `${host}${RpcRoute.recommendations}`,
    RecommendationsRpcOracleMethod.oracle_sendProofOfRecommendation,
    [
      {
        signer: utils.getAddress(account),
        token: utils.getAddress(tokenAddress),
        referrer: utils.getAddress(referrer),
        dapp,
        router: utils.getAddress(porRouter),
        time,
        sig,
        timePromises: [timePromise],
        linkReferrer: document.referrer,
      },
    ],
  );
}
