export const lazyRetry = (componentImport: () => Promise<any>): Promise<any> => new Promise((resolve, reject) => {
  const hasRefreshed = JSON.parse(
    window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
  );
  componentImport().then((component: any) => {
    window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
    resolve(component);
  }).catch((error: Error) => {
    if (!hasRefreshed) {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
      window.location.reload();
    }
    reject(error);
  });
});
