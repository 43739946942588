export enum routes {
  HOME = '/',
  FARMS = '/farms',
  FARMS_SELECTED = '/farms/:farmAddress',
  BUY_TO_EARN = '/buy-to-earn',
  BUY_TO_EARN_SELECTED = '/buy-to-earn/:farmAddress',
  MY_REWARDS = '/my-rewards',
  MY_FARMS = '/my-farms',
  ORACLE_STAKING = '/staking',
  ORACLE_STAKING_V1 = '/staking/v1',
  BUYERS_FLOW = 'l/:dapp/:hash'
}

export enum partnerRoutes {
  PARTNER_BUY_TO_FARM = '/l/:partner/:dapp/:hash',
  PARTNER_MY_REWARDS = '/:partner/my-rewards',
}

export enum offLayoutRoutes {
  REDIRECTING = '/redirecting'
}

export enum ESearchParams {
  createFarm = 'createFarm'
}
