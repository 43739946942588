import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './reducers';
import { listenerMiddleware } from './listenerMiddleware';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export const { dispatch } = store; // if need to be used outside of react-tree

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export * as actions from './actions';
export * as thunkActions from './thunkActions';
export * as selectors from './selectors';
