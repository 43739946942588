import { env } from 'utils';
import { ISentryExtra, sentry } from './sentry';

const logError = (error: Error, extra?: ISentryExtra): void => {
  sentry.captureException(error, extra);

  // eslint-disable-next-line no-console
  if (env.isDev()) console.log(error);
};

export const logger = {
  logError,
};
