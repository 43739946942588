import { logger, toast } from 'services';

const METAMASK_ERROR = {
  alreadyPending: 'already pending',
  userRejectedRequest: 'User rejected the request.',
  alreadyProcessing: 'Already processing eth_requestAccounts. Please wait.',
  userRejected: 'User Rejected',
};

export const METAMASK_ERROR_CODE = {
  noChain: 4902, // indicates that the chain has not been added to MetaMask.
};

export function parseWalletError(error: Error): Error {
  if (error?.message.includes(METAMASK_ERROR.userRejected)) {
    toast.toastError('Confirm a pending request to connect in your wallet', {
      position: 'top-right',
    });
  }
  if (error?.message.includes(METAMASK_ERROR.alreadyPending)) {
    return {
      ...error,
      message: 'Confirm a pending request to switch network in your wallet',
    };
  }

  if (error?.message.includes(METAMASK_ERROR.userRejectedRequest)) {
    return {
      ...error,
      message: 'You canceled the request in your wallet. Switch network to Ethereum to proceed with transaction.',
    };
  }

  logger.logError(error);
  return error;
}
