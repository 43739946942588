import ReactDOM from 'react-dom';

import { ArrowRightIcon } from 'components';

import metamaskLogo from 'assets/images/metamaskLogo.png';

export const renderInstallMetaMask = (el: Element): void => {
  ReactDOM.render(
    <>
      or
      <button
        type="button"
        className="installMetaMaskBtn"
      >
        <a
          href="https://metamask.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={metamaskLogo}
            alt="metamask"
            loading="lazy"
          />
          Install MetaMask
          <ArrowRightIcon
            fillColor="#282631"
            size="32"
          />
        </a>

      </button>
    </>,
    el,
  );
};
