import { orderBy } from 'lodash';
import { utils } from 'ethers';
import { LogDescription } from '@ethersproject/abi';

import { IEventLog } from 'types/web3';

export function parseEvents(items: IEventLog[], iface: utils.Interface): LogDescription[] {
  const itemsSorted = orderBy(items, ['chainId', 'blockNumber', 'logIndex'], ['asc', 'asc', 'asc']);
  const parsed = itemsSorted.map((row) => iface.parseLog({
    data: row.data,
    topics: JSON.parse(row.topics),
  }));
  return parsed;
}
