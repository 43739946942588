import {
  useContext, forwardRef,
  createContext,
} from 'react';

export const OuterElementContext = createContext({});

export const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return (
    <div
      ref={ref}
      {...props}
      {...outerProps}
    />
  );
});

OuterElementType.displayName = 'OuterElementType';
