import { FC } from 'react';

import { IIcon } from './types';

export const LockIcon: FC<IIcon> = ({
  opacity = '1', size = '24',
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10.5C19.6569 10.5 21 11.8431 21 13.5V19.5C21 21.1569 19.6569 22.5 18 22.5H6C4.34315 22.5 3 21.1569 3 19.5V13.5C3 11.8431 4.34315 10.5 6 10.5V7.5C6 4.18629 8.68629 1.5 12 1.5C15.3137 1.5 18 4.18629 18 7.5V10.5ZM12 3.5C14.2091 3.5 16 5.29086 16 7.5V10.5H8V7.5C8 5.29086 9.79086 3.5 12 3.5ZM18 12.5H6C5.44772 12.5 5 12.9477 5 13.5V19.5C5 20.0523 5.44772 20.5 6 20.5H18C18.5523 20.5 19 20.0523 19 19.5V13.5C19 12.9477 18.5523 12.5 18 12.5Z"
      fill="white"
    />
    <path
      d="M13.9001 16.5C13.9001 17.5493 13.0494 18.4 12.0001 18.4C10.9508 18.4 10.1001 17.5493 10.1001 16.5C10.1001 15.4507 10.9508 14.6 12.0001 14.6C13.0494 14.6 13.9001 15.4507 13.9001 16.5Z"
      fill="white"
    />
  </svg>

);
