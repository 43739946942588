import { FC } from 'react';

import styles from './pageTitle.module.css';

interface IPageTitle {
  title: string
}

export const PageTitle: FC<IPageTitle> = ({ title }) => (
  <h1 className={styles.pageTitle}>{title}</h1>
);
