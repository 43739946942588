import { FC } from 'react';
import { cx } from '@emotion/css';
import { BigNumber, utils } from 'ethers';

import { FormattedNumber } from 'modules/shared';

import styles from './balances.module.css';

const { formatEther } = utils;

interface IBalance {
    icon: string;
    balance: BigNumber;
    suffix: string;
}

export const Balance: FC<IBalance> = ({
  icon, balance, suffix,
}) => (
  <div className={cx(styles.balance, 'flex-align-center')}>
    <div className={styles.iconBox}>
      <img
        alt="icon"
        loading="lazy"
        src={icon}
      />
    </div>
    <FormattedNumber
      value={formatEther(balance)}
      suffix={suffix}
      decimalScale={4}
    />
  </div>
);
