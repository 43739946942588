import { BigNumber, utils } from 'ethers';

import { buffer } from 'utils';
import {
  Bytes32, ChainAddress, EvmAddress,
} from 'types/web3';

export function toReactorAddress(reactorId: string) : Uint8Array {
  return buffer.buf(utils.keccak256(buffer.buf(utils.solidityPack(['bytes32'], [utils.formatBytes32String(reactorId)])))).slice(0, 20);
}

function toChainAddress(chainId: BigNumber | bigint, address: Uint8Array): Uint8Array {
  if (address.byteLength !== 20) throw new Error('invalid address');
  const b = new Uint8Array(24);

  // Only numeric network id's are supported in the chain address, with max of uint32.
  // TODO throw on this if wrong
  buffer.writeUInt32BE(b, Number(buffer.toBigInt(chainId)), 0);

  b.set(address, 4);

  return b;
}

export function toChainAddressEthers(chainId: number, address: string): string {
  return `0x${buffer.bufToHexString(
    toChainAddress(BigNumber.from(chainId),
      buffer.hexToArrayBuffer(address.slice(2))),
  )}`;
}

export function toEvmAddress(addr: ChainAddress): EvmAddress {
  return `0x${addr.slice(Math.max(0, 2 + 4 * 2))}`;
}
export function toChainId(addr: ChainAddress): number {
  return Number.parseInt(addr.slice(2, 2 + 4 * 2), 16);
}

export interface ChainAddressProps {
  chainId: number
  address: EvmAddress
  isNative: boolean
}

export function parseChainAddress(chainAddress: ChainAddress): ChainAddressProps {
  const chainId = toChainId(chainAddress);
  const address = toEvmAddress(chainAddress);
  const isNative = chainId === Number.parseInt(address.slice(Math.max(0, 2 + 16 * 2)), 16);
  return {
    chainId,
    address,
    isNative,
  };
}

export function expandEvmAddressToBytes32(addr: EvmAddress): Bytes32 {
  return `0x000000000000000000000000${addr.slice(2)}`.toLowerCase();
}
