// TODO: refactor it

import {
  useEffect, useRef, forwardRef,
  ReactElement, RefObject,
  HTMLAttributes, ReactChild,
} from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import Typography from '@mui/material/Typography';

import { OuterElementContext, OuterElementType } from './outerElement';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps): ReactElement {
  const {
    data, index, style,
  } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <Typography
      component="div"
      {...dataSet[0]}
      noWrap
      style={inlineStyle}
    >
      {dataSet[1]}
    </Typography>
  );
}

function useResetCache(data: number): RefObject<VariableSizeList> {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

export const ListboxComponent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>((props, ref) => {
  const {
    children, ...other
  } = props;

  const itemData: ReactChild[] = [];
  (children as ReactChild[]).forEach(
    (item: ReactChild & { children?: ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const itemCount = itemData.length;
  const itemSize = 40;

  const getChildSize = (): number => itemSize;

  const getHeight = (): number => {
    if (itemCount > 6) {
      return 6 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => getChildSize()}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.displayName = 'ListboxComponent';
