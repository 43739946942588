import { FC } from 'react';
import { useSelector } from 'react-redux';

import { CopyIcon } from 'components';

import { selectors } from 'store';
import { navigator } from 'utils';

import { cx } from '@emotion/css';
import styles from './copyAddress.module.css';

interface ICopyAddress {
  className?:string
}
export const CopyAddress: FC<ICopyAddress> = ({ className = '' }) => {
  const account = useSelector(selectors.wallet.selectAccount);

  const handleCopyClick = (): void => {
    if (account) {
      navigator.writeToClipboard(account);
    }
  };

  return (
    <div className="flex-align-center">
      <CopyIcon />
      <button
        type="button"
        className={cx(styles.copyBtn, className)}
        onClick={handleCopyClick}
      >
        Copy address
      </button>
    </div>
  );
};
