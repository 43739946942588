import { FC } from 'react';

import { ListLabel } from './listLabel';

interface IList {
  label?: string;
  labelClassName?: string;
}

export const List: FC<IList> = ({
  label, labelClassName, children,
}) => (
  <div className="flex-column">
    {label && (
      <ListLabel
        label={label}
        className={labelClassName}
      />
    )}
    <ul>
      {children}
    </ul>
  </div>
);
