import {
  ReactElement, ReactNode, FC,
} from 'react';
import { cx } from '@emotion/css';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

import { ListboxComponent } from './listbox';

import styles from './dropdownVirtualize.module.css';

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '&.MuiAutocomplete-hasPopupIcon': {
    width: '100%',
    backgroundColor: 'var(--grey-9)',
    borderRadius: '6px 6px 0px 0px',
    borderBottom: '1px solid var(--white-20)',
    marginBottom: '5px',
    color: 'var(--white-90)',
  },
  '&.Mui-focused': {
    color: 'transparent',
    outline: 'none',
    borderBottom: '1px solid var(--purple)',
  },
  '& .MuiInputBase-root': {
    padding: '8px 17px 8px 49px',
  },
  '& .MuiInputBase-root-MuiOutlinedInput-root:hover, .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiOutlinedInput-root': {
    outline: 'none',
    border: 0,
    '&:focus': {
      backgroundColor: 'var(--grey-9)',
    },
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    color: 'var(--white-90)',
    fontFamily: 'var(--font-primary)',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    padding: 0,
  },
  '& .MuiAutocomplete-clearIndicator': {
    display: 'none',
  },
  '& .MuiAutocomplete-popupIndicator': {
    color: 'var(--white)',
    fontSize: '20rem',
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--white)',
    fontSize: '2rem',
  },
}
));

const StyledPopper = styled(Popper)(() => ({
  backgroundColor: 'var(--grey-9)',
  color: 'var(--white-90)',
  outline: 'none',
  '& .MuiAutocomplete-paper': {
    backgroundColor: 'var(--grey-9)',
  },
  '& .MuiTypography-root': {
    padding: '0px!important',
    '& button': {
      padding: '8px 16px',
      width: '100%',
      height: '100%',
      fontFamily: 'var(--font-primary)',
      color: 'var(--white-90)',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    '&:hover': {
      backgroundColor: 'var(--grey-15)',
    },
    '&:focus': {
      backgroundColor: 'var(--grey-15)',
    },
  },
  '& .MuiTypography-root, .MuiAutocomplete-noOptions': {
    color: 'var(--white-90)',
    fontFamily: 'var(--font-primary)',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    padding: 0,
  },
  '& .MuiAutocomplete-noOptions': {
    color: 'var(--white-50)',
    padding: '8px 16px',
  },
}
));

interface IOption {
  label?: string,
  value?: string,
  optionRender: ReactElement
}
interface IDropdownVirtualize {
  value?: string
  options: IOption[]
  startAdornment?: ReactNode
}

export const DropdownVirtualize: FC<IDropdownVirtualize> = ({
  value, options, startAdornment,
}) => (
  <StyledAutocomplete
    id="dropdownVirtualize"
    PopperComponent={StyledPopper}
    ListboxComponent={ListboxComponent}
    options={options}
    value={value}
    renderInput={(params: AutocompleteRenderInputParams) => (
      <div className={cx('flex-center', styles.input)}>
        {startAdornment && <div className={styles.startAdornment}>{startAdornment}</div>}
        <TextField
          {...params}
        />
      </div>
    )}
    renderOption={(props, option) => [props, (option as IOption).optionRender]}
  />
);
