import { ReactElement, ReactNode } from 'react';

import {
  ArrowRightIcon, Button, TransactionModalContentCard,
} from 'components';

import { storage, ELocalStorageItems } from 'services';
import { TRANSACTION_STATUS, TTransactionStatus } from 'types';

import { Steps } from '../steps';
import { BUY_TO_EARN_STEPS_SUCCESSFUL } from '../../config';

import styles from './buyToEarnTransactionModal.module.css';

export const getTransactionInfo = ({
  status,
  dexName,
  subTitle,
  redirectLink,
}: {
    status: TTransactionStatus,
    dexName: string,
    subTitle?: string,
    redirectLink?: string
}): { title: string; subTitle?: string | ReactNode; content?: ReactElement, button?: ReactNode } => {
  if (status === TRANSACTION_STATUS.loading) {
    return {
      title: 'Complete Signature Request',
      subTitle: 'Sign the message to register your address for rewards',
    };
  }
  if (status === TRANSACTION_STATUS.success && redirectLink) {
    const redirectToDex = (): void => {
      storage.setItem(ELocalStorageItems.dexRedirectLink, redirectLink);
      storage.setItem(ELocalStorageItems.dexForRedirect, dexName);
      window.open('/redirecting', '_blanc');
    };

    return {
      title: 'Successfully Registered',
      subTitle: 'Buy & Hold tokens and earn daily rewards!',
      content: (
        <TransactionModalContentCard>
          <div className={styles.successContent}>
            <Steps steps={BUY_TO_EARN_STEPS_SUCCESSFUL} />
          </div>
        </TransactionModalContentCard>
      ),
      button: (
        <Button
          onClick={redirectToDex}
          size="lg"
        >
          {`Buy tokens on ${dexName}`}
          <ArrowRightIcon />
        </Button>
      ),
    };
  }

  return {
    title: 'Transaction Failed',
    subTitle,
  };
};
