import React, { FC } from 'react';

import { Skeleton } from 'components';

import { REFER_TO_EARN_COLUMNS } from '../config';

import styles from './referralFarmsSkeleton.module.css';

const TableRowSkeleton: FC = () => (
  <tr>
    <td data-label={REFER_TO_EARN_COLUMNS[0].header}>
      <div className={styles.firstColumn}>
        <Skeleton
          variant="rectangular"
          width={16}
          height={16}
        />
        <Skeleton
          variant="circular"
          width={40}
          height={40}
        />

        <div>
          <Skeleton
            variant="rectangular"
            width={60}
            height={16}
          />
          <Skeleton
            variant="rectangular"
            width={100}
            height={12}
          />
        </div>
      </div>
    </td>
    <td data-label={REFER_TO_EARN_COLUMNS[1].header}>
      <div className={styles.column}>
        <Skeleton
          variant="rectangular"
          width={100}
          height={16}
        />
      </div>
    </td>
    <td data-label={REFER_TO_EARN_COLUMNS[2].header}>
      <div className={styles.column}>
        <Skeleton
          variant="rectangular"
          width={100}
          height={16}
        />
      </div>
    </td>
    <td data-label={REFER_TO_EARN_COLUMNS[3].header}>
      <div className={styles.column}>
        <Skeleton
          variant="rectangular"
          width={70}
          height={16}
        />
      </div>
    </td>
    <td data-label={REFER_TO_EARN_COLUMNS[4].header}>
      <div className={styles.column}>
        <Skeleton
          variant="rectangular"
          width={121}
          height={32}
        />
      </div>
    </td>

  </tr>

);

export const FarmTableSkeleton: FC = () => (
  <>
    <TableRowSkeleton />
    <TableRowSkeleton />
    <TableRowSkeleton />
    <TableRowSkeleton />
    <TableRowSkeleton />
  </>
);
