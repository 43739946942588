import {
  BigNumber,
  BigNumberish,
  Contract,
  ContractFunction,
  ContractReceipt,
  ContractTransaction,
  ethers,
  utils,
} from 'ethers';

import {
  Address, ChainAddress, Web3Provider,
} from 'types/web3';

import { config } from 'config';
import { web3, logger } from 'services';
import { address } from 'utils';

import {
  IHarvestRewardsDataProp, IIncreaseReferralFarmDataProp, IAdjustDailyFarmRewardsProp, IContractError,
} from './types';
import { parseContractError } from './parseContractError';

const { supportedChainId } = config;

/**
 * increaseReferralFarm
 * @param props The IIncreaseReferralFarmDataProp object
 * @param referralFarmsV1Addr The address of referral farms v1
 * @param provider Web3Provider
 * @return Promise<ContractReceipt>
 */
async function increaseReferralFarm(
  props: IIncreaseReferralFarmDataProp,
  referralFarmsV1Addr: Address,
  provider: Web3Provider,
): Promise<ContractReceipt> {
  const {
    rewardToken, referredToken, dailyFarmRewards, depositTotal,
  } = props;

  try {
    const network = await provider.getNetwork();
    const signer = provider.getSigner();
    const { chainId } = network;

    const contract = new ethers.Contract(
      referralFarmsV1Addr,
      ['function increaseReferralFarm(bytes24 rewardTokenDefn, bytes24 referredTokenDefn, uint128 rewardDeposit, tuple(bytes32 key, bytes value)[] metastate)'],
      provider,
    ) as InstanceType<typeof Contract> & {
    increaseReferralFarm: ContractFunction<ContractTransaction>;
      };

    const referredTokenDefn = address.toChainAddressEthers(chainId, referredToken.address);
    const rewardTokenDefn = address.toChainAddressEthers(chainId, rewardToken.address);
    const metastate = [
    // Metastate keys ideally are ascii and should be up to length 31 (ascii, utf8 might be less)
      {
        key: utils.formatBytes32String('confirmationReward'),
        value: utils.defaultAbiCoder.encode(
          ['uint128', 'uint128'],
          [utils.parseUnits(dailyFarmRewards.toString(), rewardToken.decimals), '0'],
        ),
      },
    ];

    const tx = await contract
      .connect(signer)
      .increaseReferralFarm(
        rewardTokenDefn,
        referredTokenDefn,
        utils.parseUnits(depositTotal.toString(), rewardToken.decimals),
        metastate,
      );
    return tx.wait();
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.increaseReferralFarm',
      rewardToken: rewardToken.address,
      referredToken: referredToken.address,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

/**
 * adjustDailyFarmRewards
 * @param props The IAdjustDailyFarmRewardsProp object
 * @param referralFarmsV1Addr The address of referral farms v1
 * @param web3Provider Web3Provider
 * @return Promise<ContractReceipt>
 */
async function adjustDailyFarmRewards(
  props: IAdjustDailyFarmRewardsProp,
  referralFarmsV1Addr: Address,
  web3Provider: Web3Provider,
): Promise<ContractReceipt> {
  const {
    referredTokenDefn, rewardTokenDefn, rewardToken, dailyFarmRewards,
  } = props;
  try {
    const signer = web3Provider.getSigner();

    const contract = new ethers.Contract(
      referralFarmsV1Addr,
      ['function configureMetastate(bytes24 rewardTokenDefn, bytes24 referredTokenDefn, tuple(bytes32 key, bytes value)[] metastate)'],
      web3Provider,
    ) as InstanceType<typeof Contract> & {
    configureMetastate: ContractFunction<ContractTransaction>;
      };

    const metastate = [
    // Metastate keys ideally are ascii and should be up to length 31 (ascii, utf8 might be less)
      {
        key: utils.formatBytes32String('confirmationReward'),
        value: utils.defaultAbiCoder.encode(
          ['uint128', 'uint128'],
          [utils.parseUnits(dailyFarmRewards.toString(), rewardToken.decimals), '0'],
        ),
      },
    ];

    const tx = await contract
      .connect(signer)
      .configureMetastate(
        rewardTokenDefn,
        referredTokenDefn,
        metastate,
      );
    return tx.wait();
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.adjustDailyFarmRewards',
      referredTokenDefn,
      rewardTokenDefn,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

/**
 * harvestRewards
 * @param rewardsData Array of entitlements and referredTokenDefn objects
 * @param referralFarmsV1Addr The address of referral farms v1
 * @param web3Provider Web3Provider
 * @return Promise<ContractReceipt>
 */
async function harvestRewards(
  rewardsData: IHarvestRewardsDataProp[],
  referralFarmsV1Addr: Address,
  web3Provider: Web3Provider,
): Promise<ContractReceipt> {
  try {
    const signer = web3Provider.getSigner();

    const requests: Array<{
      rewardTokenDefn: ChainAddress,
      entitlements: { farmHash: string, value: BigNumber, confirmation: BigNumberish }[]
    }> = [];
    const proofs: string[][][] = [];

    rewardsData.forEach(({
      rewardTokenDefn, entitles,
    }) => {
      requests.push({
        rewardTokenDefn,
        entitlements: entitles.map(({
          farmHash, rewardValue: value, confirmation,
        }) => ({
          farmHash,
          value,
          confirmation,
        })),
      });

      proofs.push(entitles.map((entitle) => entitle.proof));
    });

    const contract = new ethers.Contract(
      referralFarmsV1Addr,
      ['function harvestRewardsNoGapcheck(tuple(bytes24 rewardTokenDefn, tuple(bytes32 farmHash, uint128 value, uint128 confirmation)[] entitlements)[] reqs, bytes32[][][] proofs)'],
      web3Provider,
    ) as InstanceType<typeof Contract> & {
      harvestRewardsNoGapcheck: ContractFunction<ContractTransaction>;
    };

    const transaction = await contract
      .connect(signer)
      .harvestRewardsNoGapcheck(requests, proofs);
    const receipt = await transaction.wait();
    return receipt;
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.harvestRewards',
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

async function getAccountTokenConfirmationOffset(
  account: Address,
  rewardTokenDefn: ChainAddress,
  referralFarmsV1Addr: Address,
): Promise<number> {
  const provider = web3.getReaderProvider(supportedChainId);

  const contract = new ethers.Contract(
    referralFarmsV1Addr,
    ['function getAccountTokenConfirmationOffset(address account, address token) view returns (uint256)'],
    provider,
  );

  const tokenAddress = address.parseChainAddress(rewardTokenDefn).address;

  const offset = (await contract.getAccountTokenConfirmationOffset(account, tokenAddress).call()) || 0;

  return Number.parseInt(offset, 10);
}

export const referralFarmsV1 = {
  increaseReferralFarm,
  harvestRewards,
  getAccountTokenConfirmationOffset,
  adjustDailyFarmRewards,
};
