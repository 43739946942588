import { FC } from 'react';
import { IIcon } from './types';

export const TimeIcon: FC<IIcon> = ({ opacity = '1' }) => (
  <svg
    opacity={opacity}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.9">
      <path
        d="M9.16675 5.83342H10.8334V10.0001H14.1667V11.6667H9.16675V5.83342Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001ZM16.6667 10.0001C16.6667 13.682 13.682 16.6667 10.0001 16.6667C6.31818 16.6667 3.33341 13.682 3.33341 10.0001C3.33341 6.31818 6.31818 3.33341 10.0001 3.33341C13.682 3.33341 16.6667 6.31818 16.6667 10.0001Z"
        fill="white"
      />
    </g>
  </svg>

);
