import { FC, ReactNode } from 'react';
import { cx } from '@emotion/css';

import styles from './dropdown.module.css';

interface IDropdown {
  mainContent: ReactNode;
  menuItems?: {key: string, element: ReactNode}[];
  className?: string;
  classNameMenuItems?: string;
}

export const Dropdown: FC<IDropdown> = ({
  mainContent,
  className,
  menuItems,
  classNameMenuItems,
}) => (
  <button
    type="button"
    className={cx('flex-align-center', styles.dropdown, className)}
  >
    {mainContent}
    {menuItems && (
      <div className={cx(styles.menu, classNameMenuItems)}>
        {menuItems.map((item) => (
          <div
            key={item.key}
            className={styles.menuItem}
          >
            {item.element}
          </div>
        ))}
      </div>
    )}
  </button>
);
