import { TNodeUrl } from 'api';

export const ZERO_ADDR = '0x0000000000000000000000000000000000000000';
export const ZERO_HASH = '0x0000000000000000000000000000000000000000000000000000000000000000';

export interface ISignAndPostProofOfRecommendationOrigin {
  signer: string;
  token: string;
  router: string;
  time: number;
  sig: string;
  timePromises: string[];
}

export interface ISignAndPostProofOfRecommendationWithReferrer {
  signer: string;
  token: string;
  referrer: string;
  dapp: string;
  router: string;
  time: number;
  sig: string;
  timePromises: string[];
  linkReferrer: string;
}

// Get a time signature from the oracle (all fields required, send sized 0x000....00 when not used)
export interface ITimeSignatureProps {
  account: string;
  tokenAddress: string;
  referrer: string;
  dapp: string;
  router: string;
  host: TNodeUrl;
}

export interface DTOGetTimeSignature {
  sig: string;
  time: string;
}

export interface IGetTimeSignatureRes {
  time: number
  timePromise: string;
}
