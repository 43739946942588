import { logger, customFetch } from 'services';
import { IEventLog } from 'types/web3';
import { TNodeUrl } from 'api';

import { LogParams } from './farms/types';

function makeIndexerUrlPath(params: LogParams): string {
  const parts = [];
  if (params.addresses) params.addresses.forEach((d) => parts.push(`address=${d}`));
  if (params.topics) params.topics.forEach((d) => parts.push(`topic=${d}`));
  if (params.topic1) params.topic1.forEach((d) => parts.push(`topic1=${d}`));
  if (params.topic2) params.topic2.forEach((d) => parts.push(`topic2=${d}`));
  if (params.topic3) params.topic3.forEach((d) => parts.push(`topic3=${d}`));
  if (params.topic4) params.topic4.forEach((d) => parts.push(`topic4=${d}`));
  if (params.chainId) params.chainId.forEach((d) => parts.push(`chainId=${d}`));
  if (params.transactionHash) params.transactionHash.forEach((d) => parts.push(`transactionHash=${d}`));
  if (params.strategy) parts.push(`strategy=${params.strategy}`);

  return `/v1/logsearch?${parts.join('&')}`;
}

async function queryIndexer(
  oracleUrl: TNodeUrl,
  searchParams: LogParams,
): Promise<{ items: IEventLog[] }> {
  try {
    const urlPath = makeIndexerUrlPath(searchParams);

    const res = await (await customFetch(`${oracleUrl}${urlPath}`)).json();

    return res;
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'indexer.queryIndexer',
    });
    return Promise.reject(error);
  }
}

export const indexer = {
  queryIndexer,
};
