import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

import { renderConnectWalletSpinner, renderInstallMetaMask } from 'modules/web3Modal';

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_ID,
    },
  },
};

const modal: Web3Modal = new Web3Modal({
  cacheProvider: false,
  providerOptions,
});

function web3ProviderClickListener(this: Element): void {
  renderConnectWalletSpinner(this);
  const walletIcon = this.querySelector('.web3modal-provider-icon');
  walletIcon?.classList.add('web3modal-provider-spinner-pending');
}

const addSpinnerPending = (): void => {
  document.querySelectorAll('.web3modal-provider-wrapper').forEach((el: Element) => {
    el.addEventListener('click', web3ProviderClickListener);

    const walletIcon = el.querySelector('.web3modal-provider-icon');
    const newEl = document.createElement('div');
    newEl.id = 'web3modal-provider-spinner';
    walletIcon?.appendChild(newEl);
  });
};

const removeSpinnerPending = (): void => {
  document.querySelectorAll('.web3modal-provider-wrapper').forEach((el: Element) => {
    el.removeEventListener('click', web3ProviderClickListener);
    const walletIcon = el.querySelector('.web3modal-provider-icon');
    walletIcon?.classList.remove('web3modal-provider-spinner-pending');
  });
};

const addInstallMetaMask = (): void => {
  const el = document.createElement('div');
  el.id = 'web3_modal_install_metamask';
  document.body.appendChild(el);

  renderInstallMetaMask(el);
};

const removeInstallMetaMask = (): void => {
  const el = document.getElementById('web3_modal_install_metamask');

  if (el) {
    document.body.removeChild(el);
  }
};

const removeWalletUINodes = (): void => {
  removeSpinnerPending();
  removeInstallMetaMask();
};

const connect = async (): Promise<any> => modal.connect();
const clearProvider = (): void => modal.clearCachedProvider();

export const web3Modal = {
  connect,
  clearProvider,
  addSpinnerPending,
  addInstallMetaMask,
  removeWalletUINodes,
};
