import ReactDOM from 'react-dom';
import CircularProgress from '@mui/material/CircularProgress';

export const renderConnectWalletSpinner = (el: Element): void => {
  ReactDOM.render(
    <CircularProgress style={{
      color: '#fff',
    }}
    />,
    el.querySelector('#web3modal-provider-spinner'),
  );
};
