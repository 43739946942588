import { utils } from 'ethers';

import {
  farms, coingecko, TNodeUrl,
} from 'api';
import {
  IDataForAPRMap, TFarmDetailsMap, rpc, RpcOracleMethod, RpcRoute, TFarmRewardMap, TFarmsMap, logger,
} from 'services';
import {
  address, buffer, bytes, env,
} from 'utils';
import { IFarmExistEventRes } from 'api/indexer/farms/types';

import {
  Address, ChainAddress, ChainId, EvmAddress, FarmHash,
} from 'types/web3';

import { IGetFarmDetails, TAccountFarmsMap } from './types';

const iface = new utils.Interface([
  'function getLastConfirmationReward(bytes32 farmHash) view returns (uint128)',
  'function getFarmTrackedRewardValue(bytes32 farmHash) view returns (uint128)',
]);

const farmTokenSizeIface = new utils.Interface([
  'function getFarmTokenSize(bytes32 farmHash, bytes24 referredToken) view returns (uint128)',
]);

// TODO: check catch(() => BigInt('0'));
export async function getLastConfirmationReward(farmHash: FarmHash, oracleUrl: TNodeUrl): Promise<bigint> {
  const data = iface.encodeFunctionData('getLastConfirmationReward', [farmHash]);

  return rpc.call<{ to: string, data: string }[], string>(oracleUrl + RpcRoute.rpc, RpcOracleMethod.oracle_call_old, [{
    to: buffer.toHex(address.toReactorAddress('referralFarmsV1Reactor')),
    data,
  }])
    .then((res) => BigInt(res?.result || '0'))
    .catch(() => BigInt('0'));
}

// TODO: check catch(() => BigInt('0'));
export async function getFarmTokenSize(
  farmHash: FarmHash, referredToken: ChainAddress, oracleUrl: TNodeUrl,
): Promise<bigint> {
  const data = farmTokenSizeIface.encodeFunctionData('getFarmTokenSize', [farmHash, referredToken]);

  return rpc.call<{ to: string, data: string }[], string>(oracleUrl + RpcRoute.rpc, RpcOracleMethod.oracle_call_old, [{
    to: buffer.toHex(address.toReactorAddress('farmTokenSizeV1Reactor')),
    data,
  }])
    .then((res) => BigInt(res?.result || '0'))
    .catch(() => BigInt('0'));
}

async function getDataForAPR(farmExistEvents: IFarmExistEventRes, oracleUrl: TNodeUrl): Promise<IDataForAPRMap> {
  const APRMap: IDataForAPRMap = new Map();

  const uniqueTokenDefns = new Set<string>();

  farmExistEvents.forEach(({
    referredTokenDefn, rewardTokenDefn,
  }) => {
    uniqueTokenDefns.add(address.parseChainAddress(referredTokenDefn).address);
    uniqueTokenDefns.add(address.parseChainAddress(rewardTokenDefn).address);
  });

  const arr = Array.from(uniqueTokenDefns);

  const exchangeRates = await (env.isMainnet()
    ? coingecko.getConversationRate(arr)
    : coingecko.getConversationRateTestnet(arr)
  );

  const { size } = farmExistEvents;
  let idx = 0;

  return new Promise((resolve) => {
    farmExistEvents.forEach((farmExistEvent, key) => {
      const {
        rewardTokenDefn, referredTokenDefn,
      } = farmExistEvent;

      Promise.all([
        getFarmTokenSize(key, referredTokenDefn, oracleUrl),
        getLastConfirmationReward(key, oracleUrl),
      ])
        .then(([farmTokenSize, lastConfirmedReward]) => {
          const rewardConversionRate = exchangeRates[address.parseChainAddress(rewardTokenDefn).address]?.eth;
          const referredConversionRate = exchangeRates[address.parseChainAddress(referredTokenDefn).address]?.eth;

          const conversionRate = referredTokenDefn === rewardTokenDefn
            ? 1
            : rewardConversionRate / referredConversionRate;

          APRMap.set(key, {
            farmTokenSize,
            conversionRate: conversionRate || 0,
            lastConfirmedReward,
            ...farmExistEvent,
          });
          idx += 1;

          if (size === idx) {
            resolve(APRMap);
          }
        })
        .catch((error) => {
          logger.logError(error, {
            function: 'farms.getDataForAPR',
          });
          resolve(new Map());
        });
    });
  });
}

async function getFarmsTrackedRewardsValue(farmsHashes: FarmHash[], host: TNodeUrl): Promise<Map<FarmHash, bigint>> {
  const rewardsTrackedRewardsMap = new Map<FarmHash, bigint>();

  await Promise.all(farmsHashes.map((farmHash) => {
    const data = iface.encodeFunctionData('getFarmTrackedRewardValue', [farmHash]);

    return rpc.call<{ to: string, data: string }[], bigint>(host + RpcRoute.rpc, RpcOracleMethod.oracle_call_old, [{
      to: buffer.toHex(address.toReactorAddress('referralFarmsV1Reactor')),
      data,
    }])
      .then((rpcRes) => {
        if (rpcRes.result) {
          rewardsTrackedRewardsMap.set(farmHash, BigInt(rpcRes.result));
        }
      })
      .catch((error) => {
        logger.logError(error, {
          function: 'farms.getFarmsTrackedRewardsValue',
        });
      });
  }));

  return rewardsTrackedRewardsMap;
}

export async function getFarms(
  chainId: ChainId,
  referralFarmContractAddress: string,
  oracleUrl: TNodeUrl,
): Promise<TFarmsMap> {
  try {
    const farmsExists = await farms.getFarmExistsEvents(oracleUrl, chainId, referralFarmContractAddress);

    const farmHashes = Array.from(farmsExists.keys());

    const farmsMetastate = await farms.getFarmMetastateEvents(
      oracleUrl, chainId, farmHashes, referralFarmContractAddress,
    );
    const dataForAPRMap = await getDataForAPR(farmsExists, oracleUrl);

    const totalRewardsMap = await getFarmsTrackedRewardsValue(farmHashes, oracleUrl);

    const groupedByFarmHash = new Map();
    farmHashes.forEach((farmHash) => {
      const farmExist = farmsExists.get(farmHash);
      const dataForAPR = dataForAPRMap.get(farmHash);
      const totalRewards = totalRewardsMap.get(farmHash);

      if (farmExist && dataForAPR) {
        const newVal = {
          ...farmExist,
          farmTokenSize: dataForAPR.farmTokenSize,
          lastConfirmedReward: dataForAPR.lastConfirmedReward,
          totalRewards: totalRewards || 0n,
          conversionRate: dataForAPR.conversionRate,
          rewardsLockTime: farmsMetastate.get(farmHash)?.rewardsLockTime,
        };
        groupedByFarmHash.set(farmHash, newVal);
      }
    });

    const groupedByReferredToken: TFarmsMap = new Map();

    groupedByFarmHash.forEach((farm) => {
      const {
        referredTokenDefn,
        totalRewards,
        rewardTokenDefn,
        lastConfirmedReward,
        farmTokenSize,
        conversionRate,
        rewardsLockTime,
      } = farm;

      const prev = groupedByReferredToken.get(referredTokenDefn);
      if (prev) {
        const prevRewardValue = prev.rewardMap.get(rewardTokenDefn);

        prev.totalPositionSize += farmTokenSize;
        prev.rewardMap.set(rewardTokenDefn, {
          rewardTokenDefn,
          totalRewards: (prevRewardValue?.totalRewards || 0n) + totalRewards,
          dailyRewards: (prevRewardValue?.dailyRewards || 0n) + lastConfirmedReward,
          conversionRate,
          rewardsLockTime: (prevRewardValue?.rewardsLockTime || 0),
        });

        groupedByReferredToken.set(referredTokenDefn, prev);
      } else {
        const rewardMap: TFarmRewardMap = new Map();
        rewardMap.set(rewardTokenDefn, {
          rewardTokenDefn,
          totalRewards,
          dailyRewards: lastConfirmedReward,
          conversionRate,
          rewardsLockTime,
        });

        groupedByReferredToken.set(referredTokenDefn, {
          referredTokenDefn,
          totalPositionSize: farmTokenSize,
          rewardMap,
        });
      }
    });

    return Promise.resolve(groupedByReferredToken);
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'farms.getFarms',
    });
    return Promise.reject(error);
  }
}

export async function getFarmDetails(
  chainId: ChainId,
  tokenAddress: EvmAddress,
  referralFarmContractAddress: string,
  oracleUrl: TNodeUrl,
): Promise<IGetFarmDetails> {
  try {
    const farmExists = await farms.getFarmExistsEvents(oracleUrl,
      chainId,
      referralFarmContractAddress,
      undefined,
      [bytes.expandBytes24ToBytes32(address.toChainAddressEthers(chainId, tokenAddress))]);

    const farmHashes = Array.from(farmExists.keys());

    const farmsMetastate = await farms.getFarmMetastateEvents(
      oracleUrl, chainId, farmHashes, referralFarmContractAddress,
    );

    const farmsTrackedRewardsValuesMap = await getFarmsTrackedRewardsValue(farmHashes, oracleUrl);

    const dataForAPRMap = await getDataForAPR(farmExists, oracleUrl);

    let totalFarmTokenSize = 0n;
    const farmDetailsMap: TFarmDetailsMap = new Map();

    farmExists.forEach((farmEvent, farmHash) => {
      const trackedRewardValue = farmsTrackedRewardsValuesMap.get(farmHash);
      const dataForApr = dataForAPRMap.get(farmHash);
      const farmMetastate = farmsMetastate.get(farmHash);

      totalFarmTokenSize += dataForApr?.farmTokenSize || 0n;

      const prev = farmDetailsMap.get(farmEvent.rewardTokenDefn);

      farmDetailsMap.set(farmEvent.rewardTokenDefn, {
        totalReward: (prev?.totalReward || 0n) + (trackedRewardValue || 0n),
        dailyReward: (prev?.dailyReward || 0n) + (dataForApr?.lastConfirmedReward || 0n),
        conversionRate: dataForApr?.conversionRate || 1,
        rewardsLockTime: farmMetastate?.rewardsLockTime || prev?.rewardsLockTime || 0,
      });
    });

    return {
      totalPositionSize: totalFarmTokenSize,
      farmDetailsMap,
    };
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'farms.getFarmDetails',
    });
    return Promise.reject(error);
  }
}

export async function getAccountFarms(
  chainId: ChainId,
  referralFarmContractAddress: string,
  oracleUrl: TNodeUrl,
  account: Address,
): Promise<TAccountFarmsMap> {
  try {
    const farmsExists = await farms.getFarmExistsEvents(oracleUrl, chainId, referralFarmContractAddress, account);

    if (!farmsExists.size) return new Map();

    const farmHashes = Array.from(farmsExists.keys());

    const farmsMetastate = await farms.getFarmMetastateEvents(
      oracleUrl,
      chainId,
      farmHashes,
      referralFarmContractAddress,
    );

    const totalRewardsMap = await getFarmsTrackedRewardsValue(farmHashes, oracleUrl);
    const dataForAPRMap = await getDataForAPR(farmsExists, oracleUrl);

    const farmsMap: TAccountFarmsMap = new Map();
    farmHashes.forEach((farmHash) => {
      const farmExist = farmsExists.get(farmHash);
      const dataForAPR = dataForAPRMap.get(farmHash);
      const totalRewards = totalRewardsMap.get(farmHash);

      if (farmExist && dataForAPR) {
        const newVal = {
          ...farmExist,
          farmHash,
          farmTokenSize: dataForAPR.farmTokenSize,
          conversionRate: dataForAPR.conversionRate,
          dailyRewards: dataForAPR.lastConfirmedReward || 0n,
          totalRewards: totalRewards || 0n,
          rewardsLockTime: farmsMetastate.get(farmHash)?.rewardsLockTime || 0,

        };
        farmsMap.set(farmHash, newVal);
      }
    });

    return Promise.resolve(farmsMap);
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'farms.getAccountFarms',
    });
    return Promise.reject(error);
  }
}
