import { FC } from 'react';

import { Skeleton as MUISkeleton, SkeletonProps } from '@mui/material';

export const Skeleton: FC<SkeletonProps> = (props) => (
  <MUISkeleton
    {...props}
    animation="wave"
    sx={{
      bgcolor: 'var(--skeleton-bg)',
      borderRadius: props.variant === 'rectangular' ? '2px' : '50%',
      ...props.sx,
      '&:after': {
        background: 'var(--skeleton-bg-gradient)',
      },
    }}

  />
);
