import { Address } from 'types/web3';

import { BigNumber } from 'ethers';
import { TWeb3Instance } from 'services';

export enum EBalances {
    chainCurrencyBalance = 'ETH',
    wETH = 'wETH',
    ATTR = 'ATTR',
    lockedATTR = 'lockedATTR',
}

export interface IBalances {
    [EBalances.chainCurrencyBalance]: BigNumber;
    [EBalances.wETH]: BigNumber;
    [EBalances.ATTR]: BigNumber;
    [EBalances.lockedATTR]: BigNumber;
}

export interface IWalletState {
    account: Address;
    walletChainId: number;
    balances: IBalances,
    isSwitchNetworkRequired: boolean;
    web3Instance?: TWeb3Instance
}
