import { FC } from 'react';

import { useThemeContext } from 'providers/theme';

import styles from './styles.module.css';

export const Logo: FC = () => {
  const {
    logo, mobileLogo,
  } = useThemeContext();

  return (
    <>
      <img
        src={logo}
        alt="logo"
        className={styles.logo}
      />
      <img
        src={mobileLogo}
        alt="logo"
        className={styles.mobileLogo}
      />
    </>
  );
};
