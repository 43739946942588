export interface ITheme {
    name: string;
    farmName: string;
    logo: string;
    mobileLogo: string;
    theme: string;
    routes: {
        myRewards: string,
    },
    icons: {
        noRewards: string,
    },
}

// define theme colors in @styles/theme.css
export enum ETheme {
    dappradar = 'dappradar',
    default = 'default',
}

export interface IPartnerTheme extends ITheme {
    name: string;
    farmName: string;
    logo: string;
    mobileLogo: string;
    theme: ETheme;
    routes: {
        myRewards: string,
    },
    icons: {
        noRewards: string,
    },
}

export interface IState extends ITheme {
    isDefaultTheme: boolean;
}

export interface IThemeContext extends IState {
    setTheme: (theme: ITheme) => void;
}
