import { ReactChild } from 'react';
import { cx } from '@emotion/css';

import { Tooltip } from 'components';

import styles from './styles.module.css';

interface IInfoLine {
  title: string | ReactChild;
  tooltipContent?: string | ReactChild;
  className?: string;
}

function InfoLine({
  title, tooltipContent, className,
}: IInfoLine): JSX.Element {
  return (
    <p className={cx(styles.line, className)}>
      {title}
      {tooltipContent && (
        <Tooltip
          content={tooltipContent}
          className={styles.tooltip}
        />
      )}
    </p>
  );
}

export default InfoLine;
