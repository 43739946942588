import { FC } from 'react';
import { cx } from '@emotion/css';

import ethereum from 'assets/icons/ethereum.png';

import styles from './networkBadge.module.css';

interface IChainName {
  name?: string,
  icon?: string
  className?: string
}

export const NetworkBadge: FC<IChainName> = ({
  name = 'Ethereum', icon = ethereum, className,
}) => (
  <div className={cx('flex-center', className)}>
    <img
      className={styles.chainIcon}
      src={icon}
      alt={name}
      loading="lazy"
    />
    <span>{name}</span>
  </div>
);
