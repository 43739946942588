import {
  ChangeEvent, FC, memo, ReactElement, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { ContractReceipt, utils } from 'ethers';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button, DefaultLink, InfoLine, Modal, ModalContentCard, TooltipContentList, TransactionModal,
} from 'components';

import {
  actions, thunkActions, selectors, AppDispatch,
} from 'store';
import {
  ERC20Token, TRANSACTION_STATUS, TTransactionStatus,
} from 'types';
import { contracts, web3 } from 'services';
import { IIncreaseReferralFarmDataProp } from 'services/contracts/types';
import { EExplorerPathnames } from 'services/web3';
import { numbers } from 'utils';
import { PROTOCOL_FEE, config } from 'config';

import {
  ConfigReferralFarmBody, ConfigReferralFarmTitle, DepositRewards, SelectToken,
} from './steps';

import { ECreateFarmStep } from './types';

import styles from './createReferralFarmModal.module.css';

const {
  supportedChainId, requestsRetryCount,
} = config;
const {
  roundValue, formatNumber,
} = numbers;

const { formatEther } = utils;

const getTransactionInfo = (
  status: TTransactionStatus,
  depositTotal: string,
  chainId: number,
  confirmPermission: boolean,
  switchNetworkRequired: boolean,
  subTitle?: string,
  transactionHash?: string,
): { title: string; subTitle?: string; content?: ReactElement } => {
  if (switchNetworkRequired && status !== TRANSACTION_STATUS.error) {
    return {
      title: 'Switch Network',
      subTitle: 'Switch network to Ethereum in your wallet to proceed',
    };
  }

  if (status === TRANSACTION_STATUS.loading) {
    return {
      title: 'Confirm Transaction',
      subTitle: confirmPermission
        ? `Confirm permission for the token and deposit ${depositTotal} to create a referral farm`
        : `Deposit ${depositTotal} to create a referral farm`,
    };
  }
  if (status === TRANSACTION_STATUS.success) {
    const link = web3.getExplorerUrl(chainId, EExplorerPathnames.transactionPathname, {
      id: transactionHash,
    });

    return {
      title: 'Successfully Created',
      content: transactionHash
        ? (
          <DefaultLink
            iconSize="24"
            className={styles.modalSuccessLink}
            link={link}
            linkText="View on Explorer"
          />
        ) : undefined,
    };
  }

  return {
    title: switchNetworkRequired ? 'Switch Network failed' : 'Transaction Failed',
    subTitle,
  };
};

const CreateReferralModalTooltip:FC = memo(() => (
  <TooltipContentList items={[
    {
      element: (
        <>
          Rewards in selected
          <strong> Reward Token</strong>
          {' '}
          are deposited and paid out for successful referrals
          <br />
        </>
      ),
      key: 'first',
    },
    {
      element: (
        <>
          <strong>Total Daily Rewards</strong>
          {' are rewards distributed daily among all the referrers and buyers. Each participant earns daily rewards according to their share of total tokens purchased via all referral links'}
          <br />
        </>
      ),
      key: 'second',
    },
    {
      element: (
        <>
          <strong> Total Farm Rewards</strong>
          {' is a total amount of the remaining reward tokens out of which the daily farm rewards are paid out. The daily farm rewards will stop being paid when there are no more remaining rewards.'}
        </>
      ),
      key: 'third',
    },
  ]}
  />
));

CreateReferralModalTooltip.displayName = 'CreateReferralModalTooltip';

// TODO: add useReducer instead of state
export const CreateReferralFarmModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const switchNetworkRequired = useSelector(selectors.wallet.selectSwitchNetworkRequired);
  const account = useSelector(selectors.wallet.selectAccount);
  const web3Instance = useSelector(selectors.wallet.selectWeb3Instance);
  const referralFarmsV1ContractAddress = useSelector(selectors.app.selectReferralFarmContractAddress);

  const [activeStep, setActiveStep] = useState<ECreateFarmStep>(ECreateFarmStep.selectToken);
  const [referredToken, setReferredToken] = useState<ERC20Token | undefined>(undefined);
  const [rewardToken, setRewardToken] = useState<ERC20Token | undefined>(undefined);
  const [dailyFarmRewards, setDailyFarmRewards] = useState<string>('');
  const [totalFarmRewards, setTotalFarmRewards] = useState<string>('');
  const [rewardTokenConfirmPermission, setRewardTokenConfirmPermission] = useState<boolean>(false);

  const depositTotal = useMemo(
    () => {
      const totalFarmRewardsNum = Number(totalFarmRewards);
      return formatNumber(totalFarmRewardsNum + (totalFarmRewardsNum * PROTOCOL_FEE), rewardToken?.decimals);
    },
    [totalFarmRewards, rewardToken?.decimals],
  );
  const dailyFarmRewardsNum = useMemo(
    () => formatNumber(Number(dailyFarmRewards), rewardToken?.decimals),
    [dailyFarmRewards, rewardToken?.decimals],
  );

  const createFarm = useMutation<ContractReceipt, Error, IIncreaseReferralFarmDataProp>(async (data) => {
    if (switchNetworkRequired) {
      await dispatch(thunkActions.wallet.switchNetwork(supportedChainId)).unwrap();
    }

    const web3Provider = web3.getWeb3Provider(web3Instance);

    const tokenAllowance = await contracts.erc20.allowance(
      account, data.rewardToken.address, referralFarmsV1ContractAddress, web3Provider,
    );

    const confirmPermission = Number(formatEther(tokenAllowance)) <= depositTotal;
    setRewardTokenConfirmPermission(confirmPermission);

    if (confirmPermission) {
      await contracts.erc20.approve(data.rewardToken.address, referralFarmsV1ContractAddress, web3Provider);
    }

    return contracts.referralFarmsV1.increaseReferralFarm(data, referralFarmsV1ContractAddress, web3Provider);
  });

  const {
    data: balance, refetch: refetchBalance,
  } = useQuery(
    `balance-${rewardToken?.address}-${supportedChainId}-${account}`,
    () => rewardToken && contracts.erc20.balanceOf(
      rewardToken?.address,
      account,
      web3.getReaderProvider(supportedChainId),
    ),
    {
      retry: requestsRetryCount,
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleCreateFarm = useCallback(() => {
    if (!rewardToken || !referredToken || !dailyFarmRewardsNum || !depositTotal) return;

    createFarm.mutate({
      rewardToken,
      referredToken,
      dailyFarmRewards: dailyFarmRewardsNum,
      depositTotal,
    });
  }, [rewardToken, referredToken, dailyFarmRewardsNum, depositTotal, createFarm]);

  const goToNextStep = useCallback(() => {
    setActiveStep((prev) => prev + 1);
  }, []);

  const goToPrevStep = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  const onSelectReferredToken = useCallback((value: ERC20Token) => {
    setReferredToken(value);
    setRewardToken(value);

    goToNextStep();
  }, [goToNextStep]);

  const onSelectRewardToken = useCallback((selectedToken?: ERC20Token) => {
    setRewardToken(selectedToken);
  }, []);

  const onChangeDailyFarmRewards = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDailyFarmRewards(e.target.value);
  }, []);

  const onChangeTotalFarmRewards = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTotalFarmRewards(e.target.value);
  }, []);

  const handleDepositRewards = useCallback(async () => {
    if (!account) {
      dispatch(thunkActions.wallet.connectWallet())
        .unwrap()
        .then(() => goToNextStep());
    } else {
      goToNextStep();
    }
  }, [account, goToNextStep, dispatch]);

  useEffect(() => {
    if (rewardToken?.address && account) {
      refetchBalance();
    }
  }, [refetchBalance, rewardToken?.address, account]);

  const step = useMemo(
    () => {
      const balanceNum = balance ? roundValue(utils.formatUnits(balance?.toString(), rewardToken?.decimals)) : 0;
      const insufficientFunds = depositTotal > balanceNum;

      switch (activeStep) {
        case ECreateFarmStep.configReferralFarm:
          return {
            title: referredToken && <ConfigReferralFarmTitle token={referredToken} />,
            subTitle: <InfoLine
              title="Specify the details of the referral farm you are about to create"
              tooltipContent={<CreateReferralModalTooltip />}
              className={styles.infoLine}
            />,
            body: <ConfigReferralFarmBody
              referredToken={referredToken}
              rewardToken={rewardToken}
              onSelectRewardToken={onSelectRewardToken}
              dailyFarmRewards={dailyFarmRewards}
              totalFarmRewards={totalFarmRewards}
              onChangeDailyFarmRewards={onChangeDailyFarmRewards}
              onChangeTotalFarmRewards={onChangeTotalFarmRewards}
            />,
            classNameContent: styles.modalContent,
            classNameFooter: styles.modalFooter,
            footer: (
              <Button
                onClick={handleDepositRewards}
                disabled={!dailyFarmRewards || !totalFarmRewards}
              >
                Deposit Rewards

              </Button>
            ),
          };
        case ECreateFarmStep.depositRewards:
          return {
            title: 'Deposit Rewards',
            body: totalFarmRewards && rewardToken && (
              <ModalContentCard>
                <DepositRewards
                  totalFarmRewards={totalFarmRewards}
                  depositTotal={depositTotal}
                  token={rewardToken}
                  balance={balanceNum}
                  insufficientFunds={insufficientFunds}
                />
              </ModalContentCard>
            ),
            classNameFooter: styles.modalFooter,
            footer: (
              <Button
                onClick={handleCreateFarm}
                disabled={!dailyFarmRewards || !totalFarmRewards || insufficientFunds}
              >
                Deposit and Create Farm

              </Button>
            ),
          };
        default:
          return {
            title: 'Create Referral Farm',
            subTitle: 'Select a token that you want people to spread the word about 📣',
            body: <SelectToken
              onSelectToken={onSelectReferredToken}
            />,
            classNameContent: styles.modalContent,
            classNameFooter: styles.selectTokenModalFooter,
          };
      }
    },
    [
      activeStep,
      referredToken,
      rewardToken,
      dailyFarmRewards,
      totalFarmRewards,
      depositTotal,
      balance,
      onChangeDailyFarmRewards,
      onChangeTotalFarmRewards,
      onSelectReferredToken,
      onSelectRewardToken,
      handleCreateFarm,
      handleDepositRewards,
    ],
  );

  if (!createFarm.isIdle && rewardToken && referredToken && dailyFarmRewards && totalFarmRewards) {
    return (
      <TransactionModal
        status={createFarm.status}
        onClose={() => {
          createFarm.reset();
          dispatch(actions.app.toggleCreateFarmModal());
        }}
        onRetry={handleCreateFarm}
        {...getTransactionInfo(
          createFarm.status,
          `${depositTotal} ${rewardToken.symbol}`,
          supportedChainId,
          rewardTokenConfirmPermission,
          switchNetworkRequired,
          createFarm?.error?.message,
          createFarm.data?.transactionHash,
        )}
      />
    );
  }

  return (
    <Modal
      open
      onClose={() => dispatch(actions.app.toggleCreateFarmModal())}
      title={step.title}
      subtitle={step.subTitle}
      classNameContent={step.classNameContent}
      classNameFooter={step.classNameFooter}
      onBack={activeStep !== ECreateFarmStep.selectToken ? goToPrevStep : undefined}
      footer={step.footer}
    >
      {step.body}
    </Modal>
  );
};
