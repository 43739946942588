import { FC, memo } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch, thunkActions } from 'store';

import { DisconnectIcon } from 'components';

import styles from './disconnectButton.module.css';

export const DisconnectButton: FC = memo(() => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <div className={styles.disconnectBtn}>
      <button
        type="button"
        className="flex-center"
        onClick={() => dispatch(thunkActions.wallet.disconnectWallet())}
      >
        <DisconnectIcon />
        <span className={styles.disconnectBtnText}>Disconnect</span>
      </button>
    </div>
  );
});

DisconnectButton.displayName = 'DisconnectButton';
