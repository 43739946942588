import { CSSProperties, FC } from 'react';
import { cx } from '@emotion/css';

import styles from './table.module.css';

interface ITableColumn {
  dataLabel?: string;
  customStyles?: CSSProperties;
  className?: string;
}

export const TableColumn: FC<ITableColumn> = ({
  dataLabel,
  customStyles,
  className,
  children,
}) => (
  <div
    className={cx(styles.tableColumn, className)}
    data-label={dataLabel}
    style={{
      ...customStyles,
    }}
  >
    {children}
  </div>
);
