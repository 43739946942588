import { styled } from '@mui/material/styles';
import {
  Tooltip as MuiTooltip, tooltipClasses, TooltipProps,
} from '@mui/material';

export const StyledTooltip = styled(({
  className, children, ...props
}: TooltipProps) => (
  <MuiTooltip
    {...props}
    classes={{
      popper: className,
    }}
  >
    {children}
  </MuiTooltip>
))(() => ({
  [`& .${tooltipClasses.tooltipArrow}`]: {
    backgroundColor: 'var(--tooltip-bg)',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    padding: '12px 16px',
    fontFamily: 'var(--font-primary)',
    maxWidth: '256px',
    border: '1px solid var(--tooltip-border-color)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: '20px',
    color: 'var(--tooltip-bg)',
    '&:before': {
      border: '1px solid var(--tooltip-border-color)',
    },
  },
}));
