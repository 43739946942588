import {
  CSSProperties, FC, HTMLAttributes,
} from 'react';
import { cx } from '@emotion/css';

import { buttonSizes } from './button.config';

import styles from './styles.module.css';

// TODO: set variant required to remove primary styles by default
interface Props extends HTMLAttributes<HTMLButtonElement> {
  variant?: 'secondary' | 'primary' | 'tertiary';
  className?: string;
  customStyles?: CSSProperties;
  size?: keyof typeof buttonSizes;
  disabled?: boolean
}

const Button: FC<Props> = ({
  children,
  className = '',
  variant,
  onClick,
  customStyles,
  size = 'lg',
  ...rest
}) => {
  const btnClass = cx(styles.primary, {
    [styles.secondary]: variant === 'secondary',
    [styles.tertiary]: variant === 'tertiary',
  }, className);
  const $size = buttonSizes[size] || {};

  return (
    <button
      className={btnClass}
      style={{
        ...customStyles,
        ...$size,
      }}
      onClick={onClick}
      type="button"
      {...rest}
    >
      <div className={styles.children}>{children}</div>
    </button>
  );
};

export default Button;
