import {
  FC, ChangeEvent, useState, useMemo, useCallback,
} from 'react';
import { debounce } from 'lodash';
import { cx } from '@emotion/css';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import { Input, SearchIcon } from 'components';
import { ReferralCard } from 'modules/shared';

import { selectors } from 'store';

import { ERC20Token } from 'types';

import styles from './selectCryptoTokens.module.css';

interface ISelectCryptoTokens {
  onSelectToken: (token: ERC20Token) => void;
}

export const SelectCryptoTokens: FC<ISelectCryptoTokens> = ({ onSelectToken }) => {
  const tokensList = useSelector(selectors.app.selectTokenList);

  const memoizedTokenList = useMemo(() => Array.from(tokensList.values()), [tokensList]);

  const [searchValue, setSearchValue] = useState<string>('');
  const [tokenListFiltered, setTokenListFiltered] = useState< ERC20Token[]>(memoizedTokenList);

  const onSearch = useCallback((val: string) => {
    const list = memoizedTokenList.filter(
      (token) =>
        (token.address + token.symbol + token.name).toLowerCase().includes(val.toLowerCase()),
    );
    setTokenListFiltered(list);
  }, [memoizedTokenList]);

  const debouncedSearch = useMemo(
    () =>
      debounce((val) => {
        onSearch(val);
      }, 500),
    [onSearch],
  );

  const onChangeSearchValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  if (!tokensList.size) {
    return (
      <div className={cx(styles.body, 'flex-center')}>
        <CircularProgress
          size={64}
          sx={{
            color: '#ffffffe6',
            marginTop: '20px',
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className={styles.search}>
        <Input
          placeholder="Search by token or contract address"
          iconStart={(
            <SearchIcon
              opacity="0.7"
              size="24"
            />
          )}
          value={searchValue}
          onChange={onChangeSearchValue}
        />
      </div>
      <div className={styles.selectCryptoTokensContent}>
        {tokenListFiltered.map((token) => (
          <button
            key={token.address}
            type="button"
            className={styles.token}
            onClick={() => onSelectToken(token)}
          >
            <ReferralCard
              token={token}
              tokenIconSize="sm"
            />
          </button>
        ))}
      </div>
    </>
  );
};
