import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DropdownVirtualize } from 'components';
import { TokenIcon } from 'modules/shared';

import { ERC20Token } from 'types';

import { selectors } from 'store';

import styles from './rewardToken.module.css';

interface IOption {
  token: ERC20Token;
  onSelectRewardToken: (selectedToken?: ERC20Token) => void;
}

const Option: FC<IOption> = ({
  token, onSelectRewardToken,
}) => (
  <button
    className="flex-align-center"
    type="button"
    onClick={() => onSelectRewardToken(token)}
  >
    <TokenIcon
      token={token}
      size="xs"
    />
    <span className={styles.tokenSymbol}>{token.symbol}</span>
  </button>
);

interface IRewardToken {
  rewardToken?: ERC20Token;
  onSelectRewardToken: (selectedToken?: ERC20Token) => void;
}

export const RewardToken: FC<IRewardToken> = ({
  rewardToken, onSelectRewardToken,
}) => {
  const tokensList = useSelector(selectors.app.selectTokenList);

  const memoizedTokenList = useMemo(() => Array.from(tokensList.values()).map((token) => ({
    label: token.symbol ?? '',
    value: token.symbol ?? '',
    optionRender: <Option
      token={token}
      onSelectRewardToken={onSelectRewardToken}
    />,
  })), [onSelectRewardToken, tokensList]);

  return (
    <DropdownVirtualize
      options={memoizedTokenList}
      value={rewardToken?.symbol}
      startAdornment={rewardToken && (
        <TokenIcon
          token={rewardToken}
          size="xs"
        />
      )}
    />
  );
};
