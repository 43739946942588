import { FC } from 'react';
import { cx } from '@emotion/css';

import styles from './comingSoon.module.css';

interface IComingSoon {
  className?: string;
}

export const ComingSoon: FC<IComingSoon> = ({ className }) => {
  const classes = cx(styles.comingSoon, className);
  return <div className={classes}>Coming Soon</div>;
};
