import { utils, BigNumber } from 'ethers';

import { address, buffer } from 'utils';
import { Entitlement } from 'api/entitlements/types';

const {
  toHex, buf,
} = buffer;
const {
  keccak256, solidityPack,
} = utils;

function makeLeafHash(
  chainId: number,
  reward: Entitlement,
  rewardTokenDefn: string,
  referralFarmsAddr: string,
  confirmationsAddr: string,
): string {
  return utils.keccak256(
    utils.defaultAbiCoder.encode(
      [
        'bytes24',
        'bytes24',
        'address',
        'bytes24',
        '(bytes32 farmHash, uint128 rewardValue, uint128 confirmation)',
      ],
      [
        address.toChainAddressEthers(chainId, confirmationsAddr),
        address.toChainAddressEthers(chainId, referralFarmsAddr),
        reward.entitlee,
        rewardTokenDefn,
        {
          farmHash: reward.farmHash,
          rewardValue: reward.rewardValue,
          confirmation: reward.confirmation,
        },
      ],
    ),
  );
}

function makeComputedHash(
  leafHash: string,
  proof: string[],
): string {
  let computedHash = toHex(leafHash);
  for (let i = 0; i < proof.length; i += 1) {
    const proofElement = proof[i];
    if (BigNumber.from(computedHash).lte(BigNumber.from(proofElement))) {
      // Hash(current computed hash + current element of the proof)
      computedHash = toHex(keccak256(buf(solidityPack(['bytes32', 'bytes32'], [computedHash, proofElement]))));
    } else {
      // Hash(current element of the proof + current computed hash)
      computedHash = toHex(keccak256(buf(solidityPack(['bytes32', 'bytes32'], [proofElement, computedHash]))));
    }
  }

  return computedHash;
}

export const hash = {
  makeLeafHash,
  makeComputedHash,
};
