interface IChainName {
  [key: number]: string;
}

export const CHAIN_NAMES: IChainName = {
  5: 'goerli',
  1: 'mainnet',
  31337: 'local',
};

type TAdresses = {
  [networkName: string]: {
    WETH: string;
    ATTR: string;
    STAKING: string;
    STAKINGV2: string;
    UNISWAPV2_FACTORY: string;
    UNISWAPV3_FACTORY: string;
    SUSHISWAPV2_FACTORY: string;
  };
};

//  TODO: Move staking address to fetching fro DAO
export const ADDRESSES: TAdresses = {
  goerli: {
    WETH: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
    ATTR: '0x926362b451A012F72b34240F36C3bDc163d462e0',
    STAKING: '0x49093C513B3545C193ceCaAE05f985573784FeFf',
    STAKINGV2: '',
    UNISWAPV2_FACTORY: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    UNISWAPV3_FACTORY: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    SUSHISWAPV2_FACTORY: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  },
  mainnet: {
    WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ATTR: '0x44e2deC86B9F0e0266E9AA66e10323A2bd69CF9A',
    STAKING: '0x4e5c36cc0f10717d6407f39424ab402627b85197',
    STAKINGV2: '0x451d83949C96080404a3c33006478818025e5215',
    UNISWAPV2_FACTORY: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    UNISWAPV3_FACTORY: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    SUSHISWAPV2_FACTORY: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
  },
  local: {
    WETH: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0', // set to ATTR contract to make sure there is a balance shown
    ATTR: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    STAKING: '0x8A791620dd6260079BF849Dc5567aDC3F2FdC318',
    STAKINGV2: '0x0B306BF915C4d645ff596e518fAf3F9669b97016',
    UNISWAPV2_FACTORY: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    UNISWAPV3_FACTORY: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    SUSHISWAPV2_FACTORY: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
  },

};

interface IDex {
  [key: string]: string;
}

export enum EDapp {
  UNISWAPV2 = 'uniswapv2',
  UNISWAPV3 = 'uniswapv3',
  SUSHISWAP = 'sushiswap',
}

export const EDappNames: IDex = {
  sushiswap: 'SushiSwap',
  uniswapv2: 'Uniswap',
  uniswapv3: 'Uniswap',
};

export const DEX_LINKS: IDex = {
  sushiswap: 'https://app.sushi.com',
  uniswapv2: 'https://app.uniswap.org',
  uniswapv3: 'https://app.uniswap.org',
  bittrex: 'https://global.bittrex.com',
};

const enum EOracleChainId {
  'mainnet'= 147,
  'testnet' = 5470,
}

export type TOracleChainId = EOracleChainId.mainnet | EOracleChainId.testnet;

enum EDefaultConfigValues {
  supportedChainId = 1,
  oracleChainId = 147,
  porRouter = '0xc508Ff9E1b6d5491C8983A074339328FE2Cf8076',
  homePageUrl = 'https://attrace.com',
  sentryDsn = '',
  infuraUrl = 'https://mainnet.infura.io/v3',
  attraceReferrer = '0xbE1b7F0B02300B1667b88ece55903f4274C752bd'
}

export const PROTOCOL_FEE = 0.05; // PROTOCOL_FEE = 5% of farm deposit

export const config = {
  env: process.env.REACT_APP_ENV,
  supportedChainId: Number(process.env.REACT_APP_SUPPORTED_CHAIN_ID || EDefaultConfigValues.supportedChainId),
  localChainId: Number(process.env.REACT_APP_LOCAL_CHAIN_ID || process.env.REACT_APP_SUPPORTED_CHAIN_ID || EDefaultConfigValues.supportedChainId),
  infuraProjectId: process.env.REACT_APP_INFURA_ID,
  oracleChainId: Number(process.env.REACT_APP_ORACLE_CHAIN_ID || EDefaultConfigValues.oracleChainId),
  porRouter: process.env.REACT_APP_POR_ROUTER || EDefaultConfigValues.porRouter,
  homePageUrl: process.env.REACT_APP_HOME_PAGE_URL || EDefaultConfigValues.homePageUrl,
  sentryDsn: process.env.REACT_APP_SENTRY_DNS || EDefaultConfigValues.sentryDsn,
  sentryEnabled: process.env.REACT_APP_ENABLE_SENTRY === 'true',
  requestsRetryCount: 3,
  infuraUrl: process.env.REACT_APP_INFURA_URL || EDefaultConfigValues.infuraUrl,
  attraceReferrer: process.env.REACT_APP_ATTRACE_REFERRER || EDefaultConfigValues.attraceReferrer,
};

interface IChainParams {
  [key: number]: {
    chainId: string;
    chainName: string;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
    rpcUrls: string[];
    blockExplorerUrls: string[];
  };
}

export const CHAIN_PARAMS: IChainParams = {
  1: {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
  5: {
    chainId: '0x5',
    chainName: 'Goerli Test Network',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://goerli.infura.io/v3'],
  },
};

export const BLACK_LIST_FARM_HASHES = ['0xa9324ec4ab15ac98f06bf1be9e1c6cdd7b85caf9c05651b60a981ac075104469'];

const chain = CHAIN_NAMES[config.supportedChainId];

export const uniswapv2FactoryAddr = ADDRESSES[chain].UNISWAPV2_FACTORY;
export const uniswapv3FactoryAddr = ADDRESSES[chain].UNISWAPV3_FACTORY;
export const sushiswapv2FactoryAddr = ADDRESSES[chain].SUSHISWAPV2_FACTORY;
