import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  THEMES, useThemeContext, ETheme,
} from 'providers/theme';

export const useSetTheme = (): void => {
  const { setTheme } = useThemeContext();

  const { partner } = useParams<{ partner: string }>();
  const config = THEMES(partner);
  const theme = config?.theme;

  // add/remove partner theme class to body element
  useEffect(() => {
    document.body.classList.add(ETheme.default, theme);
    if (theme) setTheme(config);

    return () => {
      document.body.classList?.remove(ETheme.default, theme);
    };
  }, [theme, config, setTheme]);
};
