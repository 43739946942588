import { device } from 'utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import groupBy from 'lodash/groupBy';

import { actions } from 'store';

import { logger } from 'services';
import { config } from 'config';
import { discovery, fetchERC20Tokens } from 'api';

import { ERC20Token } from 'types';

const {
  oracleChainId, supportedChainId,
} = config;

const BLACK_LIST: string[] = ['0x6e9e3bFbd01904A5513BB2bFD0500127B3fba00B'];

export const useInitialLoad = (): void => {
  const dispatch = useDispatch();

  device.useWindowResize();

  // TODO: move to thunkActions of App Slice
  useEffect(() => {
    const fetchTokensList = async (): Promise<void> => {
      try {
        const tokenList = await fetchERC20Tokens(supportedChainId);
        const tokenListSorted = tokenList
          .flatMap((tokens) => tokens);

        const groupedTokens = groupBy(tokenListSorted, (token: ERC20Token) => token.address.toLowerCase());

        // merge tokens from diff API by address to one object
        const list = Object.entries(groupedTokens)
          .flatMap(([, value]) => value.reduce((r, c) => Object.assign(r, c), {} as ERC20Token))
          .filter((token) => (BLACK_LIST?.length ? !BLACK_LIST.includes(token.address) : true))
          .sort((tokenA: ERC20Token, tokenB: ERC20Token) => tokenA?.symbol?.localeCompare(tokenB?.symbol || '') || 0);

        dispatch(actions.app.setTokensList(list));
      } catch (e) {
        const error = e as Error;
        logger.logError(error, {
          function: 'fetchERC20Tokens',
        });
      }
    };

    const fetchDiscoveryChainData = async (): Promise<void> => {
      try {
        const res = await discovery.fetchDiscoveryChain(oracleChainId);
        dispatch(actions.app.setDiscoveryChainData({
          ...res.data,
        }));
      } catch (e) {
        const error = e as Error;
        logger.logError(error, {
          function: 'discovery.fetchDiscoveryChain',
        });
      }
    };

    fetchTokensList();
    fetchDiscoveryChainData();
  }, [dispatch]);
};
