import { ChangeEvent, FC } from 'react';

import { NetworkBadge } from 'modules/shared';
import { Input, Label } from 'components';

import { ERC20Token } from 'types';

import { RewardToken } from './rewardToken';

import styles from './configReferralFarmBody.module.css';

interface IConfigReferralFarmBody {
  rewardToken?: ERC20Token;
  referredToken?: ERC20Token;
  dailyFarmRewards?: string;
  totalFarmRewards?: string;
  onSelectRewardToken: (selectedToken?: ERC20Token) => void;
  onChangeDailyFarmRewards: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeTotalFarmRewards: (e: ChangeEvent<HTMLInputElement>) => void;
}
export const ConfigReferralFarmBody: FC<IConfigReferralFarmBody> = ({
  referredToken,
  rewardToken,
  dailyFarmRewards,
  totalFarmRewards,
  onSelectRewardToken,
  onChangeDailyFarmRewards,
  onChangeTotalFarmRewards,

}) => {
  if (!referredToken) return <Label label="Please select the token to proceed." />;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Label
            label="Reward Token"
            className={styles.label}
          />
          <RewardToken
            rewardToken={rewardToken}
            onSelectRewardToken={onSelectRewardToken}
          />
        </div>
        <div className={styles.col}>
          <Label
            label="Network"
            className={styles.label}
          />
          <NetworkBadge className={styles.chainName} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <Input
            id="Total Daily Rewards"
            label="Total Daily Rewards"
            placeholder="0"
            endAdornment={(
              <div className={styles.endAdornment}>
                {rewardToken?.symbol}
              </div>
            )}
            value={dailyFarmRewards}
            type="number"
            onChange={onChangeDailyFarmRewards}
          />
        </div>
        <div className={styles.col}>
          <Input
            id="Total Farm Rewards"
            label="Total Farm Rewards"
            placeholder="0"
            endAdornment={(
              <div className={styles.endAdornment}>
                {rewardToken?.symbol}
              </div>
            )}
            value={totalFarmRewards}
            type="number"
            onChange={onChangeTotalFarmRewards}
          />
        </div>
      </div>
    </>
  );
};
