import { FC } from 'react';
import { cx } from '@emotion/css';

import styles from './styles.module.css';

interface IListLabel {
    label: string;
    className?: string;
}

export const ListLabel: FC<IListLabel> = ({
  label, className,
}) => (
  <h6 className={cx(styles.label, className)}>{label}</h6>
);
