import { FC } from 'react';

import { IIcon } from './types';

const ExternalLinkIcon: FC<IIcon> = ({ size = '20' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4573 5.23317H10.5171C10.2409 5.23317 10.0171 5.00931 10.0171 4.73317V3.83331C10.0171 3.55717 10.2409
      3.33331 10.5171 3.33331H16.1666C16.4427 3.33331 16.6666 3.55717 16.6666 3.83331V9.48282C16.6666 9.75896
      16.4427 9.98282 16.1666 9.98282H15.2667C14.9906 9.98282 14.7667 9.75896 14.7667 9.48282V6.61058L10.4524
      10.9249C10.2572 11.1201 9.94057 11.1201 9.74531 10.9249L9.10901 10.2886C8.91375 10.0933 8.91375 9.77675
      9.10901 9.58148L13.4573 5.23317Z"
    />
    <path
      d="M8.10006 5.68516C8.10006 5.40902 7.8762 5.18516 7.60006 5.18516H4.83325C4.00482 5.18516 3.33325 5.85674
      3.33325 6.68517V15.0843C3.33325 15.9127 4.00483 16.5843 4.83325 16.5843H13.2324C14.0608 16.5843 14.7324 15.9127
      14.7324 15.0843V12.3587C14.7324 12.0825 14.5085 11.8587 14.2324 11.8587H13.3325C13.0564 11.8587 12.8325 12.0825
       12.8325 12.3587V14.1845C12.8325 14.4606 12.6087 14.6845 12.3325 14.6845H5.73311C5.45697 14.6845 5.23311 14.4606
       5.23311 14.1845V7.58502C5.23311 7.30888 5.45697 7.08502 5.73311 7.08502H7.60006C7.8762 7.08502 8.10006 6.86117
       8.10006 6.58502V5.68516Z"
    />
  </svg>

);

export default ExternalLinkIcon;
