import { sortBy } from 'lodash';

import { IFarmRewards, getEstimateDailyReward } from 'modules/shared';

import { TFarmsMap } from 'services';
import { address, numbers } from 'utils';

import { ITokensListMap } from 'store/types';
import { APR } from 'types/web3';

import { IReferralFarmsFarmRow } from '../types';

const {
  bigIntToNumber, roundValue, calcApr,
} = numbers;

export const getFarmsWithTokenDetails = (farmsMap: TFarmsMap, tokensList: ITokensListMap): IReferralFarmsFarmRow[] => {
  const res: IReferralFarmsFarmRow[] = [];

  farmsMap.forEach(({
    referredTokenDefn, rewardMap, totalPositionSize,
  }) => {
    const rewardsTokens: IFarmRewards[] = [];

    const referTokenData = tokensList.get(address.parseChainAddress(referredTokenDefn).address);

    const APRs: APR[] = [];
    const estimatedDailyRewards: number[] = [];
    rewardMap.forEach(({
      dailyRewards, rewardTokenDefn, totalRewards, conversionRate, rewardsLockTime,
    }) => {
      const rewardTokenData = tokensList.get(address.parseChainAddress(rewardTokenDefn).address);

      const totalPositionNumber = bigIntToNumber(totalPositionSize.toString(), referTokenData?.decimals);
      const dailyRewardsNumber = bigIntToNumber(dailyRewards.toString(), rewardTokenData?.decimals);

      const rewardApr = calcApr(totalPositionNumber, dailyRewardsNumber * conversionRate);

      const estimatedDailyReward = getEstimateDailyReward(totalPositionNumber, dailyRewardsNumber);

      APRs.push(rewardApr);
      estimatedDailyRewards.push(roundValue(estimatedDailyReward / 2));

      if (rewardTokenData) {
        rewardsTokens.push({
          dailyRewards: {
            amount: dailyRewardsNumber,
            tokenSymbol: rewardTokenData.symbol,
            rewardsLockTime,
          },
          totalRewards: {
            amount: numbers.bigIntToNumber(totalRewards.toString(), rewardTokenData?.decimals),
            tokenSymbol: rewardTokenData.symbol,
            rewardsLockTime,
          },
          tokenSymbol: rewardTokenData.symbol,
        });
      }
    });

    if (referTokenData) {
      res.push({
        APRs,
        estimatedDailyRewards,
        referToken: {
          referredTokenDefn,
          ...referTokenData,
        },
        rewardsTokens,
      });
    }
  });

  return sortBy(res, [(farmRow) => farmRow.rewardsTokens.reduce((prev, current) => {
    const curr = Number(current.totalRewards.amount);
    return prev + (Number.isNaN(curr) ? 0 : curr);
  }, 0)]).reverse();
};
