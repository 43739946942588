import { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  BulbIcon, DangerIcon, RewardsIcon, Tooltip, TooltipContentList, TooltipContentWithIcon,
} from 'components';

import { actions } from 'store';
import { config } from 'config';
import { TOKENS_BOUGHT } from 'modules/shared/utils';

import { EReferralFarmsPageType } from '../store/types';

import styles from './aprTooltip.module.css';

const { homePageUrl } = config;

interface IAPRTooltip {
  estimatedDailyReward: number;
  referredTokenSymbol?: string;
  rewardTokenSymbol?: string;
  withLastSection?: boolean;
  pageType?: EReferralFarmsPageType
}

const TooltipItems = ({
  referredTokenSymbol, estimatedDailyReward, rewardTokenSymbol, pageType,
}: Omit<IAPRTooltip, 'withLastSection'>): Array<any> => {
  const items = [
    {
      element: (
        <>
          Tokens bought:
          <span className={styles.tooltipAmount}>
            {` ${TOKENS_BOUGHT} ${referredTokenSymbol}`}
          </span>
        </>
      ),
      key: 'Tokens bought',
    },
    {
      element: (
        <>
          You earn:
          <span className={styles.tooltipAmount}>
            {` ${estimatedDailyReward} ${rewardTokenSymbol}`}
          </span>
        </>
      ),
      key: 'You earn',
    }];

  if (pageType === EReferralFarmsPageType.referralFarming) {
    items.push({
      element: (
        <>
          Buyer earns:
          <span className={styles.tooltipAmount}>
            {` ${estimatedDailyReward} ${rewardTokenSymbol}`}
          </span>
        </>
      ),
      key: 'Buyer earns',
    });
  }

  return items;
};

const AprTooltipContent: FC<IAPRTooltip> = ({
  estimatedDailyReward,
  referredTokenSymbol,
  rewardTokenSymbol,
  withLastSection = true,
  pageType = EReferralFarmsPageType.referralFarming,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <span>
        <strong>Estimate of your daily rewards</strong>
        {' '}
        when someone buys via your referral link:
        <br />
        <br />
      </span>
      <TooltipContentList items={TooltipItems({
        estimatedDailyReward,
        referredTokenSymbol,
        rewardTokenSymbol,
        pageType,
      })}
      />
      <br />
      <TooltipContentWithIcon
        icon={(
          <DangerIcon
            size="16"
            fillColor="var(--white)"
          />
        )}
        text={(
          <>
            <strong>The calculation changes continuously</strong>
            {' '}
            based on the total amount of tokens bought via referral links.
          </>
        )}
      />
      {pageType === EReferralFarmsPageType.buyToEarn && (
        <>
          <br />
          <TooltipContentWithIcon
            icon={(
              <BulbIcon
                size="16"
              />
            )}
            text={(
              <div className={styles.tooltipBottom}>
                {'Check  '}
                <a
                  className="linkHoverUnderline"
                  href={`${homePageUrl}/guides/referral-farming/how-it-works`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>
                    {'How it works '}
                  </strong>
                </a>
                to learn more.
              </div>
            )}
          />
        </>
      )}

      {withLastSection && pageType === EReferralFarmsPageType.referralFarming && (
        <>
          <br />
          <TooltipContentWithIcon
            icon={(
              <BulbIcon
                size="16"
              />
            )}
            text={(
              <div className={styles.tooltipBottom}>
                {'Check our '}
                <a
                  className="linkHoverUnderline"
                  href={`${homePageUrl}/guides/referral-farming/how-it-works`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>
                    {'Guides '}
                  </strong>
                </a>
                {'to learn more or watch '}
                <button
                  className="linkHoverUnderline"
                  type="button"
                  onClick={() => dispatch(actions.app.toggleVideoModal())}
                >
                  <strong>
                    this short video.
                  </strong>
                </button>
              </div>
            )}
          />
        </>
      )}
    </>
  );
};

export const AprTooltip: FC<IAPRTooltip> = (props) => (
  <Tooltip
    className={styles.tooltipTrigger}
    content={(
      <AprTooltipContent
        {...props}
      />
    )}
  >
    <RewardsIcon questionMark />
  </Tooltip>
);
