import { lazy } from 'react';

import { offLayoutRoutes } from './routes';
import { Loadable } from '../components';

const { REDIRECTING } = offLayoutRoutes;

const Redirecting = Loadable(lazy(() => import('modules/redirecting')));

const OffLayoutRoutes = {
  children: [
    {
      path: REDIRECTING,
      element: <Redirecting />,
    },
  ],
};

export default OffLayoutRoutes;
