import create from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { EReferralFarmsPageType, IReferralFarmsState } from './types';
import { BUY_TO_EARN_COLUMNS, REFER_TO_EARN_COLUMNS } from '../config';

const REFERRAL_PAGE_CONFIGS = {
  [EReferralFarmsPageType.referralFarming]: {
    pageTitle: 'Refer to Earn',
    pageSubtitle: 'Use a referral link when recommending a token and earn farming rewards! 🔥',
    columns: REFER_TO_EARN_COLUMNS,
    type: EReferralFarmsPageType.referralFarming,
    farmCTALabel: 'Refer to Earn',
  },
  [EReferralFarmsPageType.buyToEarn]: {
    pageTitle: 'Buy to Earn',
    pageSubtitle: 'Buy & Hold token to earn rewards! 🔥',
    columns: BUY_TO_EARN_COLUMNS,
    type: EReferralFarmsPageType.buyToEarn,
    farmCTALabel: 'Buy to Earn',

  },
};

const initState: IReferralFarmsState['values'] = {
  type: undefined,
  farmCTALabel: '',
  pageTitle: '',
  pageSubtitle: '',
  columns: [],
};

export const useReferralFarmsStore = create<IReferralFarmsState, [['zustand/immer', never]]>(
  immer(
    (set) => ({
      values: initState,
      actions: {
        setReferralFarmPageVariant: (variant: EReferralFarmsPageType) => set((state: IReferralFarmsState) => {
          state.values = REFERRAL_PAGE_CONFIGS[variant];
        }),
      },
    }),
  ),
);

export const selectReferralFarmsActions = (state: IReferralFarmsState): IReferralFarmsState['actions'] => state.actions;
export const selectReferralFarmsValues = (state: IReferralFarmsState): IReferralFarmsState['values'] => state.values;
