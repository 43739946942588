import { FC } from 'react';

import { ArrowLeftIcon } from 'components';

import { cx } from '@emotion/css';
import styles from './backBtn.module.css';

interface IBackBtn {
    handleClick: () => void;
}

export const BackBtn: FC<IBackBtn> = ({ handleClick }) => (
  <button
    className={cx(styles.backBtn, 'flex-center')}
    type="button"
    onClick={handleClick}
  >
    <ArrowLeftIcon opacity=".8" />
  </button>
);
