import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { cx } from '@emotion/css';
import { Link, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  CloseIcon, Drawer, List, SlicedString,
} from 'components';
import { ListItem } from 'components/list/listItem';
import { DefaultLink } from 'components/defaultLink';

import { EExplorerPathnames, web3 } from 'services';

import {
  AppDispatch, thunkActions, selectors,
} from 'store';
import { EBalances } from 'store/types';
import { useThemeContext } from 'providers/theme';
import { partnerRoutes, routes } from 'routes';
import {
  DEX_LINKS, ADDRESSES, config,
} from 'config';
import { BigNumberZero } from 'types/web3';

import {
  ProfileButton, CopyAddress, DisconnectButton,
} from 'modules/shared';

import sushiswap from 'assets/icons/sushiswap.svg';
import uniswap from 'assets/icons/uniswap.svg';
import bittrex from 'assets/icons/bittrex.svg';
import eth from 'assets/icons/eth.svg';
import weth from 'assets/icons/weth.svg';
import attr from 'assets/icons/attr.svg';

import { Balance } from './balances';
import { SwapLink } from './swapLink';

import styles from './style.module.css';

const { supportedChainId } = config;

const { BUYERS_FLOW } = routes;
const {
  PARTNER_MY_REWARDS, PARTNER_BUY_TO_FARM,
} = partnerRoutes;

const DEX_LIST = [
  {
    icon: sushiswap,
    label: 'SushiSwap',
    url: `${DEX_LINKS.sushiswap}/swap?inputCurrency=ETH&outputCurrency=${ADDRESSES.mainnet.ATTR}`,
  },
  {
    icon: uniswap,
    label: 'Uniswap',
    url: `${DEX_LINKS.uniswapv3}/#/swap?chain=mainnet&inputCurrency=ETH&outputCurrency=${ADDRESSES.mainnet.ATTR}`,
  },
  {
    icon: bittrex,
    label: 'Bittrex',
    url: `${DEX_LINKS.bittrex}/Market/Index?MarketName=USDT-ATTR`,
  },
];

const BALANCES_LIST = [
  {
    icon: eth,
    label: EBalances.chainCurrencyBalance,
  },
  {
    icon: weth,
    label: EBalances.wETH,
  },
  {
    icon: attr,
    label: EBalances.ATTR,
  },
];

export const Sidebar: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const account = useSelector(selectors.wallet.selectAccount);
  const balances = useSelector(selectors.wallet.selectBalances);

  const theme = useThemeContext();

  const buyToFarmMatch = useMatch(BUYERS_FLOW);
  const partnerBuyToFarmMatch = useMatch(PARTNER_BUY_TO_FARM);
  const partnerMyRewardsMatch = useMatch(PARTNER_MY_REWARDS);

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const onToggleSidebar = useCallback((): void => setSidebarOpen((p) => !p), []);

  useEffect(() => {
    if (account) {
      dispatch(thunkActions.wallet.getBalances());
    }
  }, [account, dispatch]);

  const accountExplorerLink = web3.getExplorerUrl(supportedChainId, EExplorerPathnames.addressPathname, {
    address: account,
  });

  const showMyFarmsLink = !buyToFarmMatch && !partnerBuyToFarmMatch && !partnerMyRewardsMatch;

  const balancesArr = theme.isDefaultTheme
    ? BALANCES_LIST
    : BALANCES_LIST.slice(0, BALANCES_LIST.length - 1);

  return (
    <>
      <ProfileButton
        handleProfileClick={onToggleSidebar}
        account={account}
      />

      <Drawer
        isSidebarOpen={isSidebarOpen}
        onToggleSidebar={onToggleSidebar}
      >
        <div className={styles.sidebar}>
          <div className={cx('flex-space-between', styles.sidebarHead)}>
            <div className="flex-center">
              <ProfileButton
                handleProfileClick={onToggleSidebar}
                className={styles.profileBtn}
              />
              <SlicedString
                text={account}
                className={styles.title}
              />
            </div>

            <button
              type="button"
              onClick={onToggleSidebar}
            >
              <CloseIcon />
            </button>
          </div>

          <div className={styles.actionBtns}>
            <DefaultLink
              link={accountExplorerLink}
              linkText="View on Explorer"
            />
            <CopyAddress />
          </div>

          <List>
            <nav className={styles.navLinks}>
              <ListItem>
                <Link to={theme.routes.myRewards}>My Rewards</Link>
              </ListItem>
              {showMyFarmsLink && (
                <ListItem>
                  <Link to="/my-farms">My Farms</Link>
                </ListItem>
              )}
            </nav>
          </List>

          <div className={styles.sidebarBalance}>
            <List
              label="BALANCE"
              labelClassName={styles.sidebarListLabel}
            >
              {balancesArr.map(({
                label, icon,
              }) => (
                <ListItem key={label}>
                  <Balance
                    icon={icon}
                    balance={balances[label] || BigNumberZero}
                    suffix={` ${label}`}
                  />
                </ListItem>
              ))}
            </List>
          </div>

          {theme.isDefaultTheme && (
            <div className={styles.sidebarSwap}>
              <List
                label="BUY $ATTR"
                labelClassName={styles.sidebarListLabel}
              >
                {DEX_LIST.map((dex) => (
                  <ListItem key={dex.label}>
                    <SwapLink
                      icon={dex.icon}
                      label={dex.label}
                      link={dex.url}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          )}

          <DisconnectButton />

        </div>
      </Drawer>
    </>
  );
};
