import { FC, ReactNode } from 'react';
import { cx } from '@emotion/css';

import { TRANSACTION_STATUS, TTransactionStatus } from 'types';

import { CircularProgress } from '@mui/material';
import { Modal, RedoIcon } from 'components';

import successfulImg from 'assets/images/successful.svg';
import failedImg from 'assets/images/failed.svg';

import styles from './styles.module.css';

interface IStatus {
    status: TTransactionStatus;
}
const Status: FC<IStatus> = ({ status }) => {
  if (status === TRANSACTION_STATUS.loading) {
    return (
      <CircularProgress
        size={64}
        sx={{
          color: '#ffffffe6',
        }}
      />
    );
  }

  if (status === TRANSACTION_STATUS.success) {
    return (
      <img
        loading="lazy"
        src={successfulImg}
        alt={TRANSACTION_STATUS.success}
      />
    );
  }

  return (
    <img
      loading="lazy"
      src={failedImg}
      alt={TRANSACTION_STATUS.error}
    />
  );
};

interface ITransactionModal {
    status: TTransactionStatus;
    onClose?: () => void;
    onRetry: () => void;
    title: string;
    subTitle?: string | ReactNode;
    content?: ReactNode;
    footer?: ReactNode;
    button?: ReactNode;
}

export const TransactionModal: FC<ITransactionModal> = ({
  status,
  title,
  subTitle,
  content,
  footer,
  button,
  onClose,
  onRetry,
}) => (
  <Modal
    open
    onClose={status !== TRANSACTION_STATUS.loading ? onClose : undefined}
    classNameContent={styles.modalContent}
    footer={footer}
  >
    <div className="flex-center flex-column">
      <Status status={status} />
      <p className={styles.title}>{title}</p>
      {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
      <div className={styles.content}>{content}</div>
      {button && (
        <div className={styles.transactionModalBtn}>
          {button}
        </div>
      )}

      {/* failed tx */}
      {status === TRANSACTION_STATUS.error && (
        <button
          type="button"
          className={cx('flex-center', styles.retryBtn)}
          onClick={onRetry}
        >
          <RedoIcon />
          Try again
        </button>
      )}
    </div>
  </Modal>
);
