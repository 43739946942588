import {
  FC, ReactFragment, ReactNode,
} from 'react';

import styles from './tooltipContentList.module.css';

interface ITooltipContentList {
  items: { element: string|ReactNode|ReactFragment, key: string }[]
}

export const TooltipContentList: FC<ITooltipContentList> = ({ items }) => (
  <ul className={styles.tooltipContentList}>
    {items.map(({
      element, key,
    }) => (
      <li key={key}>
        {element}
      </li>
    ))}
  </ul>
);
