import { FC } from 'react';

import { IIcon } from './types';

export const DangerIcon: FC<IIcon> = ({
  size = '20', fillColor = '#FA4D56',
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 5.41675C10.4603 5.41675 10.8334 5.78984 10.8334 6.25008V10.8334C10.8334 11.2937 10.4603 11.6667
      10 11.6667C9.53978 11.6667 9.16669 11.2937 9.16669 10.8334V6.25008C9.16669 5.78984 9.53978 5.41675 10 5.41675Z"
      fill={fillColor}
    />
    <path
      d="M10 12.9167C9.53978 12.9167 9.16669 13.2898 9.16669 13.7501C9.16669 14.2103 9.53978 14.5834 10 14.5834C10.4603
       14.5834 10.8334 14.2103 10.8334 13.7501C10.8334 13.2898 10.4603 12.9167 10 12.9167Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.66675C5.39765 1.66675 1.66669 5.39771 1.66669 10.0001C1.66669 14.6025 5.39765 18.3334 10 18.3334C14.6024
      18.3334 18.3334 14.6025 18.3334 10.0001C18.3334 5.39771 14.6024 1.66675 10 1.66675ZM3.33335 10.0001C3.33335
      13.682 6.31812 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31818 13.6819
      3.33341 10 3.33341C6.31812 3.33341 3.33335 6.31818 3.33335 10.0001Z"
      fill={fillColor}
    />
  </svg>

);
