import { FC, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { cx } from '@emotion/css';

import { routes } from 'routes';

import { SubNav } from './subNav';

import styles from './styles.module.css';

const {
  FARMS, BUY_TO_EARN, MY_REWARDS, ORACLE_STAKING, ORACLE_STAKING_V1, MY_FARMS,
} = routes;

interface INav {
  mobileMenuOpen: boolean;
}

const ORACLE_LINKS = [
  {
    key: 'Oracle Pre-Staking V2',
    element: (
      <NavLink
        key="Oracle Pre-Staking V2"
        className={({ isActive }) => (`${isActive ? styles.active : ''}`)}
        to={ORACLE_STAKING}
      >
        Oracle Pre-Staking V2
      </NavLink>
    ),
  },
  {
    key: 'Oracle Pre-Staking V1',
    element: (
      <NavLink
        key="Oracle Pre-Staking V1"
        className={({ isActive }) => (`${isActive ? styles.active : ''}`)}
        to={ORACLE_STAKING_V1}
      >
        Oracle Pre-Staking V1
      </NavLink>
    ),
  },
];

const NAV_LINKS = [
  {
    name: 'Refer to Earn',
    to: FARMS,
  },
  {
    name: 'Buy to Earn',
    to: BUY_TO_EARN,
  },
  {
    name: 'Set up Referrals',
    to: MY_FARMS,
  },
  {
    name: 'Stake with Oracles',
    to: ORACLE_STAKING,
    menuItems: ORACLE_LINKS,
  },
  {
    name: 'Rewards',
    to: MY_REWARDS,
    className: styles.rewardsNav,
  },
];

const Nav: FC<INav> = ({ mobileMenuOpen }) => {
  const navClassName = useCallback(({
    isActive, className = '',
  }: { isActive: boolean, className?: string }): string => (`${isActive ? styles.active : ''} ${className}`), []);
  return (
    <nav className={cx(styles.nav, {
      [styles.mobileMenuOpen]: mobileMenuOpen,
    })}
    >
      {NAV_LINKS.map(({
        to, name, className, ...rest
      }) => (
        <NavLink
          key={to}
          to={to}
          className={({ isActive }) => navClassName({
            isActive,
            className,
          })}
        >
          {rest.menuItems ? (
            <SubNav
              menuItems={rest.menuItems}
              mobileMenuOpen={mobileMenuOpen}
              label={name}
            />
          ) : name}
        </NavLink>
      ))}
    </nav>
  );
};
export default Nav;
