import { FC } from 'react';

import { IIcon } from './types';

export const ShareIcon: FC<IIcon> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9165 16.2718C17.9165 14.673 16.6107 13.377 14.9998 13.377C14.2134 13.377 13.4997 13.686 12.9751 14.1882L6.2278 10.3575C6.24235 10.2403 6.24984 10.1209 6.24984 9.99984C6.24984 9.87875 6.24235 9.7594 6.2278 9.64221L12.9751 5.81148C13.4997 6.31373 14.2134 6.62265 14.9998 6.62265C16.6107 6.62265 17.9165 5.32663 17.9165 3.72791C17.9165 2.12919 16.6107 0.833172 14.9998 0.833172C13.389 0.833172 12.0832 2.12919 12.0832 3.72791C12.0832 3.849 12.0907 3.96835 12.1052 4.08554L5.35793 7.91626C4.83336 7.41402 4.11961 7.1051 3.33317 7.1051C1.72234 7.1051 0.416504 8.40112 0.416504 9.99984C0.416504 11.5986 1.72234 12.8946 3.33317 12.8946C4.11961 12.8946 4.83336 12.5857 5.35793 12.0834L12.1052 15.9141C12.0907 16.0313 12.0832 16.1507 12.0832 16.2718C12.0832 17.8705 13.389 19.1665 14.9998 19.1665C16.6107 19.1665 17.9165 17.8705 17.9165 16.2718ZM14.9998 14.9998C15.6902 14.9998 16.2498 15.5595 16.2498 16.2498C16.2498 16.9402 15.6902 17.4998 14.9998 17.4998C14.3095 17.4998 13.7498 16.9402 13.7498 16.2498C13.7498 15.5595 14.3095 14.9998 14.9998 14.9998ZM4.58317 9.99984C4.58317 9.30948 4.02353 8.74984 3.33317 8.74984C2.64282 8.74984 2.08317 9.30948 2.08317 9.99984C2.08317 10.6902 2.64282 11.2498 3.33317 11.2498C4.02353 11.2498 4.58317 10.6902 4.58317 9.99984ZM14.9998 2.49984C15.6902 2.49984 16.2498 3.05948 16.2498 3.74984C16.2498 4.44019 15.6902 4.99984 14.9998 4.99984C14.3095 4.99984 13.7498 4.44019 13.7498 3.74984C13.7498 3.05948 14.3095 2.49984 14.9998 2.49984Z"
      fill="#E7E7E8"
    />
  </svg>
);
