import {
  FC, ReactElement, useState,
} from 'react';

import {
  Accordion, AccordionDetails, AccordionSummary, ChevronIcon, Dropdown,
} from 'components';

import styles from './styles.module.css';

interface IMenuItem { key: string, element: ReactElement }

interface IList {
  label: string;
  menuItems: IMenuItem[];
}

const ListAccordion: FC<IList> = ({
  menuItems, label,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Accordion
      expanded={expanded}
      handleChange={() => setExpanded((p) => !p)}
    >
      <AccordionSummary classes={{
        content: styles.subNavLabel,
      }}
      >
        {label}
      </AccordionSummary>
      <AccordionDetails classes={{
        root: styles.accordionDetails,
      }}
      >
        {menuItems.map((menuItem) => menuItem.element)}
      </AccordionDetails>
    </Accordion>
  );
};

interface ISubNav extends IList {
  mobileMenuOpen: boolean;
  menuItems: IMenuItem[]
}

const ListDropdown: FC<IList> = ({
  menuItems, label,
}) => (
  <Dropdown
    className={styles.subNavLabel}
    mainContent={(
      <div className={styles.dropdownMainContent}>
        {label}
        <ChevronIcon />
      </div>
    )}
    menuItems={menuItems}
  />
);

export const SubNav: FC<ISubNav> = ({
  mobileMenuOpen, menuItems, label,
}) =>
  (mobileMenuOpen ? (
    <ListAccordion
      label={label}
      menuItems={menuItems}
    />
  ) : (
    <ListDropdown
      label={label}
      menuItems={menuItems}
    />
  ));
