import { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import {
  routes, partnerRoutes, offLayoutRoutes, ESearchParams,
} from './routes';

import MainRoutes from './mainRoutes';
import PartnerRoutes from './partnerRoutes';
import OffLayoutRoutes from './offLayoutRoutes';

export const AppRoutes: FC = () => useRoutes([MainRoutes, PartnerRoutes, OffLayoutRoutes]);

export {
  routes, partnerRoutes, offLayoutRoutes, ESearchParams,
};
