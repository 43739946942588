import { FC } from 'react';

import { TransactionModal } from 'components';

import { EDapp, EDappNames } from 'config';
import { url } from 'utils';

import { Address } from 'types/web3';

import { useBuyToFarm } from 'modules/buyFarmBtn/useBuyToFarm';
import { getTransactionInfo } from './getTransactionInfo';

interface ITranscactionModal {
    buyFarm: ReturnType<typeof useBuyToFarm>;
    dapp: EDapp;
    tokenAddress: Address;
}

export const BuyToEarnTransactionModal: FC<ITranscactionModal> = ({
  buyFarm, dapp, tokenAddress,
}) => {
  const dexName = EDappNames[dapp];

  if (!buyFarm.isIdle) {
    const subTitle = buyFarm?.error?.message || dexName;
    const redirectLink = url.getDappRedirectLink(dapp, tokenAddress);
    const transactionBodyDetails = getTransactionInfo({
      status: buyFarm.status,
      dexName,
      subTitle,
      redirectLink,
    });
    return (
      <TransactionModal
        status={buyFarm.status}
        onClose={buyFarm.reset}
        onRetry={buyFarm.trigger}
        {...transactionBodyDetails}
      />
    );
  }

  return null;
};
