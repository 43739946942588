import {
  FC, ReactText, useCallback, useEffect, useRef, useState,
} from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { cx } from '@emotion/css';

import { Tabs } from 'components';
import { ComingSoon, PageTitle } from 'modules/shared';

import { actions, selectors } from 'store';
import { toast } from 'services';
import { ESearchParams, routes } from 'routes';

import { CryptoTokens } from './сryptoTokens';
import {
  selectReferralFarmsActions, selectReferralFarmsValues, useReferralFarmsStore,
} from './сryptoTokens/store';
import { EReferralFarmsPageType } from './сryptoTokens/store/types';

import styles from './styles.module.css';

const {
  BUY_TO_EARN, BUY_TO_EARN_SELECTED, FARMS,
} = routes;

const TABS = [
  {
    label: 'Crypto Tokens',
    content: <CryptoTokens />,
  },
  {
    label: 'NFT Collections',
    labelChildren: <ComingSoon className={styles.comingSoon} />,
    content: 'nft',
    disabled: true,
  },
];

const ReferralFarms: FC = () => {
  const dispatch = useDispatch();
  const tokensList = useSelector(selectors.app.selectTokenList);

  const [searchParams] = useSearchParams();
  const farmsMatch = useMatch(FARMS);

  const [activeTabIdx, setActiveTabIdx] = useState<number>(0);

  const buyToEarnToastId = useRef<ReactText>('');

  const handleTabChange = useCallback((e, value) => setActiveTabIdx(value), []);

  const buyToEarnMatch = useMatch(BUY_TO_EARN);
  const buyToEarnSelectedMatch = useMatch(BUY_TO_EARN_SELECTED);

  const { setReferralFarmPageVariant } = useReferralFarmsStore(selectReferralFarmsActions);
  const {
    pageSubtitle, pageTitle, type,
  } = useReferralFarmsStore(selectReferralFarmsValues);

  useEffect(() => {
    if (type === EReferralFarmsPageType.buyToEarn) {
      buyToEarnToastId.current = toast.toastInfo(
        'Buy to Earn feature is only used to showcase the capabilities of the Attrace protocol. Since Attrace takes a role of a referrer, all referrer’s rewards will go to the protocol treasury.',
      );
    } else {
      toast.dismissToast(buyToEarnToastId.current);
    }

    return () => {
      toast.dismissToast(buyToEarnToastId.current);
    };
  }, [type]);

  useEffect(() => {
    if (tokensList.size && farmsMatch) {
      const openCreateFarm = searchParams.get(ESearchParams.createFarm);
      if (openCreateFarm) {
        dispatch(actions.app.toggleCreateFarmModal());
      }
    }
  }, [searchParams, tokensList, farmsMatch, dispatch]);

  useEffect(() => {
    const pageVariant = buyToEarnMatch || buyToEarnSelectedMatch
      ? EReferralFarmsPageType.buyToEarn
      : EReferralFarmsPageType.referralFarming;

    setReferralFarmPageVariant(pageVariant);
  }, [buyToEarnMatch, buyToEarnSelectedMatch, setReferralFarmPageVariant]);

  return (
    <>
      <Helmet>
        <title>Referral Farms | Attrace</title>
        <meta
          name="description"
          content="Use referral links when recommending crypto or NFT projects and earn referral farming rewards!"
        />
      </Helmet>
      <div className={cx('flex-align-center', styles.pageHeader)}>
        <PageTitle title={pageTitle} />
      </div>
      <div className={styles.subtitle}>
        <p className={styles.infoLine}>{pageSubtitle}</p>
      </div>
      <Tabs
        tabs={TABS}
        activeTabIdx={activeTabIdx}
        handleTabChange={handleTabChange}
        classNameTab={styles.tab}
      />
    </>
  );
};

export default ReferralFarms;
