import { FC } from 'react';

import { IIcon } from './types';

const ArrowRightIcon: FC<IIcon> = ({
  fillColor = 'white', size = '25',
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8921 6.69599C15.6964 6.50118 15.3798 6.50192 15.185 6.69764L14.4795 7.4064C14.2847 7.60212 14.2855 7.9187 14.4812 8.11351L17.397 11.0157L4.29199 11.0289C4.01585 11.0292 3.79222 11.2533 3.7925 11.5294L3.7935 12.5294C3.79378 12.8056 4.01786 13.0292 4.294 13.0289L17.3618 13.0158L14.4993 15.8916C14.3045 16.0874 14.3053 16.4039 14.501 16.5987L15.2098 17.3042C15.4055 17.499 15.7221 17.4983 15.9169 17.3025L20.855 12.3412C21.0498 12.1455 21.0491 11.8289 20.8534 11.6341L15.8921 6.69599Z"
      fill={fillColor}
    />
  </svg>

);

export default ArrowRightIcon;
