import {
  FC, ReactNode, useMemo,
} from 'react';

import { Dropdown } from 'components';

import { cx } from '@emotion/css';
import styles from './menuDropdown.module.css';

interface IItem { label: string, link: string, linkTarget?: string }

interface IMenuDropdown {
  icon?: ReactNode;
  label?: ReactNode;
  className?: string;
  items: IItem[];
}

interface ILink {
  item: IItem;
}

const Item: FC<ILink> = ({ item }) => (
  <a
    href={item.link}
    className={styles.menuItem}
    target={item.linkTarget}
  >
    {item.label}
  </a>
);

export const MenuDropdown: FC<IMenuDropdown> = ({
  icon,
  label,
  className,
  items,
}) => {
  const menuItems = useMemo(() => items.map((item) => ({
    key: item.label,
    element: <Item item={item} />,
  })), [items]);

  return (
    <Dropdown
      className={cx(styles.menuDropdown, className)}
      mainContent={(
        <>
          {icon && icon}
          {label && <span className={styles.label}>{label}</span>}
        </>
      )}
      menuItems={menuItems}
    />
  );
};
