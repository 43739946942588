import { FC } from 'react';

import { IIcon } from './types';

export const SearchIcon: FC<IIcon> = ({
  opacity = 1, size = '24',
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
    width={size}
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8691 15.4575C19.3128 12.319 19.0921 7.77832 16.2069 4.89313C13.0827 1.76894 8.01739 1.76894 4.89319 4.89313C1.769 8.01733 1.769 13.0826 4.89319 16.2068C7.77838 19.092 12.319 19.3128 15.4575 16.869C15.4711 16.8843 15.4852 16.8993 15.4998 16.9139L19.7424 21.1566C20.133 21.5471 20.7661 21.5471 21.1567 21.1566C21.5472 20.7661 21.5472 20.1329 21.1567 19.7424L16.914 15.4997C16.8994 15.4851 16.8844 15.471 16.8691 15.4575ZM14.7927 6.30735C17.1358 8.65049 17.1358 12.4495 14.7927 14.7926C12.4495 17.1358 8.65055 17.1358 6.30741 14.7926C3.96426 12.4495 3.96426 8.65049 6.30741 6.30735C8.65055 3.9642 12.4495 3.9642 14.7927 6.30735Z"
      fill="white"
    />
  </svg>

);
