import { FC } from 'react';

interface IQuestionCircleIcon {
  opacity?: string;
  size?: string;
}

const QuestionCircleIcon: FC<IQuestionCircleIcon> = ({
  opacity = '1', size = '24',
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
  >
    <g>
      <path
        d="M12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5C13 15.9477 12.5523 15.5 12 15.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
        fill="white"
      />
      <path
        d="M8.87365 9.29325C8.87198 9.33271 8.8784 9.3721 8.89251 9.40899C8.90662 9.44588 8.92813 9.47949 8.95571 9.50776C8.98329 9.53603 9.01637 9.55835 9.0529 9.57337C9.08943 9.58838 9.12864 9.59577 9.16813 9.59506H10.1762C10.3448 9.59506 10.4792 9.45699 10.5012 9.28959C10.6112 8.48803 11.161 7.90396 12.141 7.90396C12.9792 7.90396 13.7466 8.32307 13.7466 9.33113C13.7466 10.107 13.2896 10.4638 12.5674 11.0064C11.7451 11.6039 11.0938 12.3016 11.1403 13.4343L11.1439 13.6994C11.1452 13.7796 11.178 13.856 11.2351 13.9123C11.2923 13.9685 11.3692 14 11.4494 14H12.4404C12.5214 14 12.5991 13.9678 12.6564 13.9105C12.7137 13.8532 12.7458 13.7755 12.7458 13.6945V13.5662C12.7458 12.6889 13.0794 12.4335 13.98 11.7505C14.7241 11.1848 15.5 10.5567 15.5 9.23827C15.5 7.39198 13.9409 6.5 12.2339 6.5C10.6857 6.5 8.98973 7.22092 8.87365 9.29325Z"
        fill="white"
      />
    </g>
  </svg>

);

export default QuestionCircleIcon;
