import { ethers, providers } from 'ethers';
import urlcat from 'urlcat';

import { CHAIN_NAMES, config } from 'config';
import {
  InfuraProvider, ChainId, Web3Provider,
} from 'types/web3';
import { EExplorerPathnames, IExplorerParams } from './types';

const {
  infuraProjectId,
  localChainId,
} = config;
function getReaderProvider(chainId: ChainId): InfuraProvider {
  if (CHAIN_NAMES[localChainId] === 'local') {
    return (ethers.getDefaultProvider('http://127.0.0.1:8545') as any);
  }
  return new ethers.providers.InfuraProvider(chainId, {
    projectId: infuraProjectId,
  });
}

const getWeb3Provider = (provider: providers.ExternalProvider | providers.JsonRpcFetchFunc = window.ethereum)
  : Web3Provider => new ethers.providers.Web3Provider(provider);

const EXPLORER_URLS: { [key: number]: string } = {
  1: 'https://etherscan.io',
  5: 'https://goerli.etherscan.io',
};

const getExplorerUrl = (
  chainId: ChainId,
  explorerRoute: EExplorerPathnames,
  params: Partial<IExplorerParams>,
): string => {
  if (!chainId || !explorerRoute || !params) return '';

  return urlcat(EXPLORER_URLS[chainId], explorerRoute, {
    ...params,
  });
};

export {
  getReaderProvider, getWeb3Provider, getExplorerUrl,
};
