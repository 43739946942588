import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectors } from 'store';
import { EExplorerPathnames, web3 } from 'services';
import { config } from 'config';

import { DefaultLink, Dropdown } from 'components';
import {
  CopyAddress, ProfileButton, DisconnectButton,
} from 'modules/shared';

import styles from './profileDropdown.module.css';

const { supportedChainId } = config;

export const ProfileDropdown: FC = () => {
  const account = useSelector(selectors.wallet.selectAccount);

  const accountExplorerLink = web3.getExplorerUrl(supportedChainId, EExplorerPathnames.addressPathname, {
    address: account,
  });

  return (
    <div className={styles.profileDropdown}>
      <Dropdown
        classNameMenuItems={styles.profileDropdownMenu}
        mainContent={(
          <ProfileButton
            account={account}
          />
        )}
        menuItems={[
          {
            key: 'accountExplorerLink',
            element: <DefaultLink
              iconSize="24"
              link={accountExplorerLink}
              linkText="View on Explorer"
              classNameLinkText={styles.accountExplorerLink}
            />,
          },
          {
            key: 'copyAddress',
            element: <CopyAddress className={styles.copyAddress} />,
          },
          {
            key: 'disconnectButton',
            element: <DisconnectButton />,
          },
        ]}
      />
    </div>
  );
};
