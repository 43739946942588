import { FC, ReactNode } from 'react';
import { cx } from '@emotion/css';

import { DefaultLink, Tooltip } from 'components';

import { numbers } from 'utils';
import { APR } from 'types/web3';
import { TokenAmount } from 'modules/shared';

import { ITokenAmount } from '../types';

import styles from './farmDetails.module.css';

interface IFarmDetails {
  labelClassName?: string;
  valueClassName?: string,
  dailyRewards: ITokenAmount[]
  totalRewards: ITokenAmount[];
  APRs: APR[];
  totalRewardLink?: string;
  columnAprLabel?: string;
  tooltips?: {
    apr?: string,
    daily?: string,
    total?: string,
    estimatedDailyRewards?: ReactNode[]
  };
}

interface IColumn {
  label: string | ReactNode;
  value: string | ReactNode;
  labelClassName?: string;
  valueClassName?: string;
  tooltip?: string;
}

const Column: FC<IColumn> = ({
  label,
  value,
  labelClassName,
  valueClassName,
  tooltip,
}) => (
  <div className={cx('flex-column', styles.column)}>
    <span className={cx(styles.label, labelClassName)}>
      {label}
      {tooltip && (
        <Tooltip
          content={tooltip}
          iconSize="20"
        />
      )}
    </span>
    <span className={cx(styles.value, valueClassName)}>{value}</span>
  </div>
);

export const FarmDetails: FC<IFarmDetails> = ({
  labelClassName,
  valueClassName,
  dailyRewards,
  totalRewards,
  APRs,
  totalRewardLink,
  columnAprLabel = 'Referral Rate (est.)',
  tooltips,
}) => (
  <div className={styles.farmDetailsContent}>
    <Column
      label="Total Daily Rewards"
      value={dailyRewards.map(({
        amount, tokenSymbol, rewardsLockTime,
      }) => (
        <TokenAmount
          key={`${amount} ${tokenSymbol}`}
          amount={numbers.getShorterNumber(amount)}
          tokenSymbol={tokenSymbol}
          rewardsLockTime={rewardsLockTime}
        />
      ))}
      labelClassName={labelClassName}
      valueClassName={valueClassName}
      tooltip={tooltips?.daily}
    />
    <Column
      label={(
        <>
          Total Farm Rewards
          {totalRewardLink && (
            <DefaultLink
              link={totalRewardLink}
              iconSize="20"
            />
          )}
        </>
      )}
      value={totalRewards.map(({
        amount, tokenSymbol,
      }) => (
        <TokenAmount
          key={`${amount} ${tokenSymbol}`}
          amount={numbers.getShorterNumber(amount)}
          tokenSymbol={tokenSymbol}
        />
      ))}
      labelClassName={labelClassName}
      valueClassName={valueClassName}
      tooltip={tooltips?.total}
    />
    <Column
      label={columnAprLabel}
      value={(
        <div className={styles.aprWrapper}>
          <div>
            {APRs.map((apr) => (
              <div
                key={apr}
                className={styles.apr}
              >
                {apr}
              </div>
            ))}
          </div>
          {tooltips?.estimatedDailyRewards && (
            <div>
              {tooltips?.estimatedDailyRewards.map((reward) => reward)}
            </div>
          )}
        </div>
      )}
      labelClassName={labelClassName}
      valueClassName={valueClassName}
      tooltip={tooltips?.apr}
    />
  </div>
);
