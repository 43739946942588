import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CloseIcon, GeneralModal } from 'components';

import { actions, selectors } from 'store';

import attraceRewardsDistribution from 'assets/media/attraceRewardsDistribution.mp4';

import styles from './videoModal.module.css';

export const VideoModal: FC = () => {
  const dispatch = useDispatch();
  const videoModalOpen = useSelector(selectors.app.isVideoModalOpen);

  return (
    <GeneralModal
      open={videoModalOpen}
      mode="videoModal"
    >
      <div className={styles.videoPopup}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          src={attraceRewardsDistribution}
          autoPlay
          controls
        />
        <button
          type="button"
          onClick={() => dispatch(actions.app.toggleVideoModal())}
        >
          <CloseIcon
            opacity=".6"
            size="20"
          />
        </button>
      </div>
    </GeneralModal>
  );
};
