import {
  FC, useCallback, useState,
} from 'react';
import { useSelector } from 'react-redux';

import { BuyFarmBtn, useBuyToFarm } from 'modules/buyFarmBtn';

import { selectors } from 'store';
import { config, EDapp } from 'config';

import {
  getAvailableDexList, TAvailableDexList, useGetDexesPool,
} from 'services';

import { CircularProgress } from '@mui/material';

import { SelectedFarmModal } from '../selectedFarmModal';
import { BuyToEarnTransactionModal } from '../buyToEarnTransactionModal';
import { IReferralFarmsFarmRow } from '../../../types';
import { BUY_TO_EARN_TOOLTIPS } from '../../config';

import styles from './buyToEarnModal.module.css';

const {
  attraceReferrer, homePageUrl,
} = config;

interface IBuyToEarnModal {
  selectedFarm: IReferralFarmsFarmRow;
  handleClose: () => void;
}

const getDapp = (dexList: TAvailableDexList): EDapp => {
  let res: EDapp = EDapp.UNISWAPV3;
  if (dexList[EDapp.SUSHISWAP] && !dexList[EDapp.UNISWAPV3]) {
    res = EDapp.SUSHISWAP;
  }

  return res;
};

export const BuyToEarnModal: FC<IBuyToEarnModal> = ({
  selectedFarm, handleClose,
}) => {
  const { referToken } = selectedFarm;

  const oracleUrl = useSelector(selectors.app.selectOracleUrl);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(Boolean(selectedFarm));

  const { poolExists } = useGetDexesPool(referToken.address);

  let dapp = EDapp.UNISWAPV3;

  if (poolExists) {
    const dexList = getAvailableDexList(...poolExists);
    dapp = getDapp(dexList);
  }

  const buyFarm = useBuyToFarm(
    referToken.address, attraceReferrer, dapp, oracleUrl,
  );

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    handleClose();
  }, [handleClose]);

  return (
    <SelectedFarmModal
      title="Buy to Earn"
      subtitle="Buy & Hold tokens to join the referral farm and receive rewards based on the purchased value 💰"
      columnAprLabel="Estimated APR"
      tooltips={BUY_TO_EARN_TOOLTIPS}
      hint={(
        <div>
          Check our
          <a
            className="linkHoverUnderline"
            href={`${homePageUrl}/guides/referral-farming/how-it-works`}
            target="_blank"
            rel="noreferrer"
          >
            <strong>
              {' How it works '}
            </strong>
          </a>
          to learn more
        </div>
      )}
      onCloseModal={handleModalClose}
      selectedFarm={selectedFarm}
      isOpen={isModalOpen}
      footerBtn={(
        <BuyFarmBtn
          icon={!poolExists.length && (
            <CircularProgress
              size={24}
              sx={{
                color: '#ffffffe6',
              }}
            />
          )}
          className={styles.buyToEarnBtn}
          isDisabled={!poolExists.length}
          label="Register for Rewards"
          handleClick={buyFarm.trigger}
          transactionModal={(
            <BuyToEarnTransactionModal
              buyFarm={buyFarm}
              dapp={dapp}
              tokenAddress={referToken.address}
            />
          )}

        />
      )}
    />
  );
};
