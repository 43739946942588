import { FC } from 'react';
import { cx } from '@emotion/css';

import { TokenIcon } from 'modules/shared';

import { ERC20Token } from 'types';

import styles from './configReferralFarmTitle.module.css';

interface IConfigReferralFarmTitle {
  token: ERC20Token;
}
export const ConfigReferralFarmTitle: FC<IConfigReferralFarmTitle> = ({ token }) => (
  <div className={cx('flex-align-center', styles.title)}>
    <TokenIcon token={token} />
    <span>
      $
      {token?.symbol}
    </span>
    Referral Farm
  </div>
);
