import {
  logger, rpc, RpcMethod,
} from 'services';
import {
  Address, Bytes32, IEventLog,
} from 'types/web3';
import { config } from 'config';

const {
  infuraUrl, infuraProjectId,
} = config;

export interface IParams {
  address?: Address,
  topics?: (Bytes32 | null)[],
  fromBlock: string
}

async function getLogs(
  params: IParams,
): Promise<IEventLog[] | undefined> {
  try {
    const res = await rpc.call(`${infuraUrl}/${infuraProjectId}`, RpcMethod.eth_getLogs, [{
      ...params,
    }]);

    return Promise.resolve(res?.result);
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'eth.getLogs',
    });
    return Promise.reject(error);
  }
}

export const eth = {
  getLogs,
};
