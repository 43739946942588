import { FC, SyntheticEvent } from 'react';

import MuiRadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';

import { RadioButton } from '../radioButton';

interface IRadioGroup {
  name: string;
  selected?: string;
  options: { value: string; label: string, disabled?: boolean }[];
  handleChange: (event: SyntheticEvent<Element, Event>, checked: boolean) => void
}

const RadioGroupCustom = styled(MuiRadioGroup)({
  display: 'flex',
  flexDirection: 'row',
});

export const RadioGroup: FC<IRadioGroup> = ({
  name, options, selected, handleChange,
}) => (
  <RadioGroupCustom
    aria-label={name}
    name={name}
  >
    {options.map((option) => (
      <RadioButton
        key={option.label}
        checked={option.value === selected}
        disabled={option.disabled}
        value={option.value}
        label={option.label}
        handleChange={handleChange}
      />
    ))}
  </RadioGroupCustom>
);
