import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { TypedStartListening } from '@reduxjs/toolkit';

import { config } from 'config';
import { sentry } from 'services';

import type { RootState, AppDispatch } from '.';
import { wallet } from './thunkActions';
import { actions, thunkActions } from '.';

const { localChainId } = config;

const listenerMiddleware = createListenerMiddleware();
type AppStartListening = TypedStartListening<RootState, AppDispatch>
const startAppListening = listenerMiddleware.startListening as AppStartListening;

startAppListening({
  predicate: (action): boolean =>
    action.type === wallet.connectWallet.fulfilled.type,

  effect: async (action, {
    getState, dispatch,
  }) => {
    const { wallet: {
      web3Instance, account,
    } } = getState();

    if (!web3Instance?.on) {
      return;
    }

    if (account) {
      dispatch(thunkActions.wallet.getBalances());
    }

    web3Instance.on('accountsChanged', async (accounts: string[]) => {
      dispatch(actions.wallet.setAccount(accounts[0]));
    });

    web3Instance.on('chainChanged', async (chainId: string) => {
      dispatch(actions.wallet.setWalletChainId(parseInt(chainId, 16)));
    });
  },
});

startAppListening({
  predicate: (action, currentState, prevState): boolean =>
    currentState.wallet.walletChainId !== 0 && (currentState.wallet.walletChainId !== prevState.wallet.walletChainId),
  effect: (action, {
    getState, dispatch,
  }) => {
    const { walletChainId } = getState().wallet;
    dispatch(actions.wallet.setSwitchNetworkRequired(walletChainId !== localChainId));
  },
});

startAppListening({
  predicate: (action, currentState, prevState): boolean =>
    currentState.wallet.account !== '' && (currentState.wallet.account !== prevState.wallet.account),
  effect: (action, { getState }) => {
    const { account } = getState().wallet;
    if (account) {
      sentry.setUserContext({
        userAddress: account,
      });
    }
  },
});

export { listenerMiddleware };
