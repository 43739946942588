import { FC } from 'react';

const DisconnectIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="1">
      <path
        d="M9 19.5C9 19.7761 8.77614 20 8.5 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H8.5C8.77614 4 9 4.22386 9 4.5V5.5C9 5.77614 8.77614 6 8.5 6H4.5C4.22386 6 4 6.22386 4 6.5V17.5C4 17.7761 4.22386 18 4.5 18H8.5C8.77614 18 9 18.2239 9 18.5V19.5Z"
        fill="white"
      />
      <path
        d="M17.0989 6.67304C16.9032 6.47824 16.5866 6.47898 16.3918 6.6747L15.6864 7.38346C15.4916 7.57918 15.4923 7.89576 15.688 8.09056L18.6039 10.9928L7.49885 11.006C7.22271 11.0063 6.99907 11.2304 6.99935 11.5065L7.00036 12.5065C7.00064 12.7826 7.22472 13.0063 7.50086 13.006L18.5686 12.9928L15.7062 15.8687C15.5114 16.0644 15.5121 16.381 15.7079 16.5758L16.4166 17.2812C16.6123 17.4761 16.9289 17.4753 17.1237 17.2796L22.0619 12.3183C22.2567 12.1226 22.256 11.806 22.0602 11.6112L17.0989 6.67304Z"
        fill="white"
      />
    </g>
  </svg>

);

export default DisconnectIcon;
