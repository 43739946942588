import { FC } from 'react';

export const ChevronIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z"
      fill="white"
    />
  </svg>

);
