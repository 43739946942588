import Big, { BigSource } from 'big.js';
import { round } from 'lodash';

import { APR } from 'types/web3';

export function roundValue(value: string | number, decimals = 4): number {
  if (!value) return 0;

  return parseFloat(Number(value).toFixed(decimals));
}

export function bigIntToNumber(bigInt: BigSource, units = 18, precision = 4): number {
  return +Big(bigInt).div(Big(10).pow(units)).toFixed(precision);
}

export function formatNumber(value: number, units = 18, precision = 4): number {
  return units === 0 ? Math.ceil(value) : +value.toFixed(precision);
}

const suffixesArr = ['', 'K', 'M', 'B', 'T', 'Q'];
export function getShorterNumber(number: string | number = 0): string {
  if (+number === 0) return String(number);

  let n = Number(number);

  if (n < 1000) {
    return String(round(n, 4));
  }

  let suffix = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const key of suffixesArr) {
    suffix = key;
    if (Math.abs(n) < 1000) {
      break;
    } else {
      n /= 1000;
    }
  }

  if (Math.abs(n) < 10) {
    n = Math.floor(n * 10) / 10;
  } else {
    n = Math.floor(n);
  }

  return String(n) + suffix;
}

export function calcApr(farmTokenSize: number, lastConfirmedReward: number): APR {
  if (farmTokenSize === 0) {
    return '∞';
  }

  let apr: string | number = ((lastConfirmedReward * 365) / (farmTokenSize * 2)) * 100;
  if (apr < 1) {
    if (apr === 0) {
      return '-';
    }
    apr = apr.toFixed(2);
  } else {
    apr = Math.round(apr);
  }

  if (Number.isNaN(apr)) {
    return '-';
  }

  return `${apr}%`;
}
