import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, NavLink } from 'react-router-dom';
import { cx } from '@emotion/css';

import {
  CloseIcon, MenuIcon, Nav, MenuDropdown, QuestionCircleIcon,
} from 'components';
import { ProfileDropdown } from 'modules';
import { ConnectBtn } from 'modules/shared';

import { selectors } from 'store';
import { routes } from 'routes';
import { config } from 'config';
import { Logo } from './logo';

import styles from './styles.module.css';

const {
  BUYERS_FLOW, FARMS,
} = routes;

const { homePageUrl } = config;

const QUESTION_MARK_MENU = [
  {
    label: 'How it Works',
    link: `${homePageUrl}/guides/referral-farming/how-it-works`,
    linkTarget: '_blank',
  },
  {
    label: 'FAQ',
    link: `${homePageUrl}/guides/faq`,
    linkTarget: '_blank',
  },
];

interface IHeader {
  mainMenuHidden?: boolean;
  menuDropdownHidden?: boolean;
  connectBtnVariant?: 'secondary' | 'primary' | 'tertiary';
}

export const Header: FC<IHeader> = ({
  mainMenuHidden, menuDropdownHidden, connectBtnVariant = 'secondary', children,
}) => {
  const buyToFarmMatch = useMatch(BUYERS_FLOW);

  const account = useSelector(selectors.wallet.selectAccount);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const onToggleMobileMenu = (): void => setMobileMenuOpen((prevValue) => !prevValue);

  return (
    <header className={cx('flex-align-center', styles.header)}>
      {buyToFarmMatch
        ? (
          <Logo />
        )
        : (
          <NavLink
            to={FARMS}
            className="flex-center"
          >
            <Logo />
          </NavLink>

        )}

      {!mainMenuHidden && !buyToFarmMatch && <div className={styles.nav}><Nav mobileMenuOpen={mobileMenuOpen} /></div>}
      {children}
      <div className={cx('flex', styles.rightMenu)}>
        {!menuDropdownHidden && (
          <MenuDropdown
            icon={(
              <QuestionCircleIcon
                opacity=".5"
                size="24"
              />
            )}
            label="Help"
            className={styles.menuDropdown}
            items={QUESTION_MARK_MENU}
          />
        )}
        {account
          ? (
            <ProfileDropdown />
          )
          : (
            <ConnectBtn
              variant={connectBtnVariant}
              btnSize="md"
              iconSize="22"
              className={styles.connectBtn}
            />
          )}
        {!mainMenuHidden && !buyToFarmMatch && (
          <div className="flex-center">
            <button
              onClick={onToggleMobileMenu}
              type="button"
              className={styles.mobileMenuBtn}
            >
              {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </button>
          </div>
        )}
      </div>
    </header>
  );
};
