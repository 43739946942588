import { FC } from 'react';

import { IIcon } from './types';

export const CheckIcon: FC<IIcon> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 14.4001L0 8.0001L2 5.86677L6 10.1334L14 1.6001L16 3.73343L6 14.4001Z"
      fill="white"
    />
  </svg>
);
