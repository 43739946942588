import { monotonicFactory } from 'ulid';

import { logger, customFetch } from 'services';

import { JsonRpcResponse } from './types';
import { parseRpcError } from './parseRpcError';

const ulid = monotonicFactory();

// eslint-disable-next-line no-undef
async function rpcReq<U = never>(url: string, opts: RequestInit): Promise<JsonRpcResponse<U>> {
  const res = await customFetch(url, {
    ...opts,
    headers: {
      ...opts?.headers,
      'content-type': 'application/json',
    },
  });

  if (res.status !== 200) {
    logger.logError(Error(res.statusText));
    throw new Error(res.statusText);
  }

  const json: JsonRpcResponse<U> = await res.json();
  if (json.error) {
    const error = parseRpcError(json.error);
    logger.logError(error);
    throw error;
  }

  return json;
}

export async function call<T, U = never>(url: string, method: string, params: T): Promise<JsonRpcResponse<U>> {
  return rpcReq<U>(url, {
    method: 'POST',
    body: JSON.stringify({
      jsonrpc: '2.0',
      id: ulid(),
      method,
      params: params || [],
    }),
  });
}
