import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  generatePath, useMatch, useNavigate,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { Table } from 'components';

import { selectors } from 'store';
import { config } from 'config';
import { farms } from 'services';
import { routes } from 'routes';

import { selectReferralFarmsValues, useReferralFarmsStore } from './store';

import { ReferToFarmModal, BuyToEarnModal } from './modals';
import { FarmsTableBody } from './farmsTableBody';
import { FarmTableSkeleton } from './referralFarmsSkeleton';
import { getFarmsWithTokenDetails } from './utils';

import { EReferralFarmsPageType } from './store/types';
import { IReferralFarmsFarmRow } from '../types';

const {
  FARMS_SELECTED, FARMS, BUY_TO_EARN, BUY_TO_EARN_SELECTED,
} = routes;
const {
  supportedChainId, requestsRetryCount,
} = config;

export const CryptoTokens: FC = () => {
  const selectedFarmMatch = useMatch(FARMS_SELECTED);
  const buyToEarnMatch = useMatch(BUY_TO_EARN_SELECTED);
  const navigate = useNavigate();

  const referralFarmsV1Address = useSelector(selectors.app.selectReferralFarmContractAddress);
  const tokensList = useSelector(selectors.app.selectTokenList);
  const oracleUrl = useSelector(selectors.app.selectOracleUrl);

  const {
    farmCTALabel, columns, type,
  } = useReferralFarmsStore(selectReferralFarmsValues);

  const [selectedFarm, setSelectedFarm] = useState<IReferralFarmsFarmRow | undefined>(undefined);
  const [referralFarms, setReferralFarms] = useState<IReferralFarmsFarmRow[]>([]);

  const {
    refetch, data, isLoading, isIdle,
  } = useQuery('referralFarms', () => farms.getFarms(
    supportedChainId,
    referralFarmsV1Address,
    oracleUrl,
  ), {
    retry: requestsRetryCount,
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const handleClose = useCallback((route: routes) => {
    setSelectedFarm(undefined);
    navigate(route);
  }, [navigate]);

  useEffect(() => {
    if (referralFarmsV1Address && oracleUrl) refetch();
  }, [referralFarmsV1Address, oracleUrl, refetch]);

  useEffect(() => {
    if (data) setReferralFarms(getFarmsWithTokenDetails(data, tokensList));
  }, [data, tokensList]);

  useEffect(() => {
    if (referralFarms && selectedFarmMatch) {
      const farm = referralFarms.find(({ referToken }) =>
        referToken.referredTokenDefn === selectedFarmMatch.params.farmAddress);

      if (farm) {
        setSelectedFarm(farm);
      }
    }
  }, [selectedFarmMatch, referralFarms]);

  const body = useMemo(() => {
    if (isLoading || isIdle || !columns.length || !type) {
      return <FarmTableSkeleton />;
    }

    const handlers = {
      [EReferralFarmsPageType.referralFarming]: (farm: IReferralFarmsFarmRow) => {
        setSelectedFarm(farm);
        navigate(generatePath(FARMS_SELECTED, {
          farmAddress: farm.referToken.referredTokenDefn,
        }));
      },
      [EReferralFarmsPageType.buyToEarn]: (farm: IReferralFarmsFarmRow) => {
        setSelectedFarm(farm);
        navigate(generatePath(BUY_TO_EARN_SELECTED, {
          farmAddress: farm.referToken.referredTokenDefn,
        }));
      },
    };

    return (
      <FarmsTableBody
        farms={referralFarms}
        columns={columns}
        farmCTA={{
          label: farmCTALabel,
          handleClick: handlers[type],
        }}
        pageVariant={type}
      />
    );
  }, [isLoading, isIdle, referralFarms, farmCTALabel, type, columns, navigate]);

  return (
    <>
      {selectedFarm && (
        buyToEarnMatch
          ? (
            <BuyToEarnModal
              selectedFarm={selectedFarm}
              handleClose={() => handleClose(BUY_TO_EARN)}
            />
          )
          : (
            <ReferToFarmModal
              selectedFarm={selectedFarm}
              handleClose={() => handleClose(FARMS)}
            />
          )
      )}
      <Table
        columns={columns}
        ariaLabel="Referral Farming"
        body={body}
      />
    </>
  );
};
