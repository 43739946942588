import { FC, ReactElement } from 'react';
import { cx } from '@emotion/css';
import { CheckIcon } from 'components';

import styles from './steps.module.css';

interface IStep {
    id: number;
    text: string;
    icon: ReactElement;
}

const Step: FC<IStep & { activeId: number }> = ({
  id, text, icon, activeId,
}) => {
  const completeStep = activeId > id;
  return (
    <div className={cx(styles.step, {
      [styles.activeStep]: id === activeId,
      [styles.completeStep]: completeStep,
    })}
    >
      <div className={cx('flex-center', styles.stepCircle)}>
        <div className={styles.stepBg} />
        <div className={styles.stepGradient} />
        <div className={styles.stepId}>{id}</div>
        <div className={styles.stepIcon}>{icon}</div>
        {completeStep && <div className={styles.completeIcon}><CheckIcon /></div>}
      </div>
      <div className={styles.stepText}>
        {text}
      </div>
    </div>
  );
};

interface ISteps {
    steps: IStep[];
    successfulStepId?: number;
}

export const Steps: FC<ISteps> = ({
  steps, successfulStepId = 0,
}) => (
  <div className={styles.steps}>
    {steps.map(({
      id, text, icon,
    }) => (
      <Step
        key={id}
        activeId={successfulStepId}
        id={id}
        text={text}
        icon={icon}
      />
    ))}
  </div>
);
