import { lazy } from 'react';

import { Loadable } from 'components/loadable';
import PartnerLayout from 'layout/partner';

import { partnerRoutes } from './routes';

const BuyToFarm = Loadable(lazy(() => import('modules/buyersFlow')));
const MyRewards = Loadable(lazy(() => import('modules/myRewards')));

const {
  PARTNER_BUY_TO_FARM, PARTNER_MY_REWARDS,
} = partnerRoutes;

const PartnerRoutes = {
  element: <PartnerLayout />,
  children: [
    {
      path: PARTNER_BUY_TO_FARM,
      element: <BuyToFarm />,
    },
    {
      path: PARTNER_MY_REWARDS,
      element: <MyRewards />,
    },
  ],
};

export default PartnerRoutes;
