import { FC } from 'react';

import { IIcon } from './types';

export const TractorIcon: FC<IIcon> = ({ opacity = '1' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0251 16.0251C17.6815 15.3687 18.5717 15 19.5 15C20.4283 15 21.3185 15.3687 21.9749 16.0251C22.6313 16.6815 23 17.5717 23 18.5C23 19.4283 22.6313 20.3185 21.9749 20.9749C21.3185 21.6313 20.4283 22 19.5 22C18.5717 22 17.6815 21.6313 17.0251 20.9749C16.3687 20.3185 16 19.4283 16 18.5C16 17.5717 16.3687 16.6815 17.0251 16.0251ZM19.5 17C19.1022 17 18.7206 17.158 18.4393 17.4393C18.158 17.7206 18 18.1022 18 18.5C18 18.8978 18.158 19.2794 18.4393 19.5607C18.7206 19.842 19.1022 20 19.5 20C19.8978 20 20.2794 19.842 20.5607 19.5607C20.842 19.2794 21 18.8978 21 18.5C21 18.1022 20.842 17.7206 20.5607 17.4393C20.2794 17.158 19.8978 17 19.5 17Z"
      fill="white"
    />
    <path
      d="M6.5 15C6.10218 15 5.72064 15.158 5.43934 15.4393C5.15804 15.7206 5 16.1022 5 16.5C5 16.8978 5.15804 17.2794 5.43934 17.5607C5.72064 17.842 6.10218 18 6.5 18C6.89782 18 7.27936 17.842 7.56066 17.5607C7.84196 17.2794 8 16.8978 8 16.5C8 16.1022 7.84196 15.7206 7.56066 15.4393C7.27936 15.158 6.89782 15 6.5 15Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5C4 4.44772 4.44772 4 5 4H10C10.4089 4 10.7766 4.24895 10.9285 4.62861L12.677 9H20C20.5523 9 21 9.44772 21 10V16C21 16.5523 20.5523 17 20 17C19.4477 17 19 16.5523 19 16V11H12C11.5911 11 11.2234 10.751 11.0715 10.3714L9.32297 6H6V12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12V5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 7C15 5.89572 15.8957 5 17 5H19V7H17V11H15V7Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.39524 11.4187C5.06253 11.1423 5.77773 11 6.5 11C7.22227 11 7.93747 11.1423 8.60476 11.4187C9.27205 11.6951 9.87837 12.1002 10.3891 12.6109C10.8998 13.1216 11.3049 13.7279 11.5813 14.3952C11.7937 14.908 11.9269 15.4491 11.9772 16H17C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H11.7915C11.7334 18.2051 11.6632 18.407 11.5813 18.6048C11.3049 19.2721 10.8998 19.8784 10.3891 20.3891C9.87836 20.8998 9.27205 21.3049 8.60476 21.5813C7.93747 21.8577 7.22227 22 6.5 22C5.77773 22 5.06253 21.8577 4.39524 21.5813C3.72795 21.3049 3.12164 20.8998 2.61091 20.3891C2.10019 19.8784 1.69506 19.2721 1.41866 18.6048C1.14226 17.9375 1 17.2223 1 16.5C1 15.7777 1.14226 15.0625 1.41866 14.3952C1.69506 13.728 2.10019 13.1216 2.61091 12.6109C3.12163 12.1002 3.72795 11.6951 4.39524 11.4187ZM6.5 13C6.04037 13 5.58525 13.0905 5.16061 13.2664C4.73597 13.4423 4.35013 13.7001 4.02513 14.0251C3.70012 14.3501 3.44231 14.736 3.26642 15.1606C3.09053 15.5852 3 16.0404 3 16.5C3 16.9596 3.09053 17.4148 3.26642 17.8394C3.44231 18.264 3.70012 18.6499 4.02513 18.9749C4.35013 19.2999 4.73597 19.5577 5.16061 19.7336C5.58525 19.9095 6.04037 20 6.5 20C6.95963 20 7.41475 19.9095 7.83939 19.7336C8.26403 19.5577 8.64987 19.2999 8.97487 18.9749C9.29988 18.6499 9.55769 18.264 9.73358 17.8394C9.90947 17.4148 10 16.9596 10 16.5C10 16.0404 9.90947 15.5852 9.73358 15.1606C9.55769 14.736 9.29988 14.3501 8.97487 14.0251C8.64987 13.7001 8.26403 13.4423 7.83939 13.2664C7.41475 13.0905 6.95963 13 6.5 13Z"
      fill="white"
    />
  </svg>

);
