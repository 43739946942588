export const inputSizes = {
  lg: {
    height: '48px',
    padding: '12px 16px',
    fontSize: '16px',
    lineHeight: '24px',
  },
  md: {
    height: '40px',
    padding: '8px 16px',
    fontSize: '16px',
    lineHeight: '24px',
  },
  sm: {
    height: '36px',
    padding: '7px 12px',
    fontSize: '14px',
    lineHeight: '22px',
  },
};

export const inputSizesWithIconStart = {
  lg: {
    paddingLeft: '48px',
  },
  md: {
    paddingLeft: '48px',
  },
  sm: {
    paddingLeft: '40px',
  },
};

export const iconStartSize = {
  lg: {
    paddingLeft: '18px',
  },
  md: {
    paddingLeft: '18px',
  },
  sm: {
    paddingLeft: '14px',
  },

};
