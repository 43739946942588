import {
  Contract,
  ContractFunction,
  ethers,
  BigNumber,
} from 'ethers';
import { Address, InfuraProvider } from 'types/web3';
import { logger } from 'services/logger';
import { parseContractError } from './parseContractError';
import { IContractError } from './types';
/**
 * Retrieve amount of tokens that are still locked under vesting rules.
 * @param tokenAddress The address of ERC20 token
 * @param userAddress The address to check
 * @param provider The address to check
 * @return Promise<ContractReceipt>
 */
async function getLockedTokens(
  tokenAddress: Address,
  userAddress: Address,
  provider: InfuraProvider,
): Promise<BigNumber> {
  try {
    const contract = new ethers.Contract(
      tokenAddress,
      ['function getLockedTokens(address addr) public view returns (uint256)'],
      provider,
    ) as InstanceType<typeof Contract> & {
      balanceOf: ContractFunction<BigNumber>;
    };
    const lockedTokens = await contract.getLockedTokens(userAddress);
    return lockedTokens;
  } catch (e) {
    logger.logError(e as Error, {
      function: 'contracts.getLockedTokens',
      tokenAddress,
      userAddress,
    });
    return Promise.reject(parseContractError(e as IContractError));
  }
}

export const attrtoken = {
  getLockedTokens,
};
