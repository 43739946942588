import {
  FC, ReactNode, SyntheticEvent,
} from 'react';
import { styled } from '@mui/system';
import {
  TabsListUnstyled, TabPanelUnstyled, TabsUnstyled, TabUnstyled,
} from '@mui/base';
import { cx } from '@emotion/css';

import { tabSizes } from './tab.config';

import styles from './tabs.module.css';

const Tab = styled(TabUnstyled)(() => ({
  position: 'relative',
  width: '100%',
  backgroundColor: 'var(--grey-7)',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '22px',
  color: 'var(--white-90)',
  borderTopLeftRadius: '5px',
  '&:last-child': {
    borderTopLeftRadius: '0',
    borderTopRightRadius: '5px',
  },
  '&:hover': {
    backgroundColor: 'var(--grey-9)',
  },
  '&:focus': {
    outline: 'none',
    backgroundColor: 'var(--grey-9)',
  },
  '&:disabled': {
    background: 'var(--grey-7-opacity-60)',
    cursor: 'default',
    color: 'var(--white-30)',
    opacity: 1,
  },
  '&.Mui-selected': {
    background: 'var(--grey-13)',
  },
}
));

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 16px;
`;

const TabsList = styled(TabsListUnstyled)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

interface ITab {
  label: string;
  labelChildren?: ReactNode;
  content: string | ReactNode;
  disabled?: boolean;
}

interface ITabs {
  tabs: ITab[];
  activeTabIdx: number;
  size?: keyof typeof tabSizes;
  classNameTab?: string
  handleTabChange: (e: SyntheticEvent, value: string | number) => void;
}

const Tabs: FC<ITabs> = ({
  tabs, activeTabIdx, handleTabChange, size = 'lg', classNameTab = '',
}) => (
  <TabsUnstyled
    defaultValue={0}
    onChange={handleTabChange}
  >
    <TabsList className={styles.tabsList}>
      {tabs.map(({
        label, disabled, labelChildren,
      }, idx) => (
        <Tab
          disabled={disabled}
          key={label}
          sx={{
            ...tabSizes[size],
          }}
          className={cx(styles.tab, classNameTab)}
        >
          {label}
          {labelChildren && labelChildren}
          {idx === activeTabIdx && <div className={styles.gradient} />}
        </Tab>
      ))}
    </TabsList>
    {tabs.map((tab, i) => (
      <TabPanel
        value={i}
        key={`${tab.label}-content`}
      >
        {tab.content}
      </TabPanel>
    ))}
  </TabsUnstyled>
);

export default Tabs;
