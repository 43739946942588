import {
  IDiscoveryChainData, TDiscoveryFileName, IDiscoveryRes,
} from 'api/discovery/types';
import { TOracleChainId } from 'config';
import { customFetch } from 'services';

const discoveryUrl = 'https://discovery.attrace.com';

export async function fetchDiscovery<T>(fileName: TDiscoveryFileName): Promise<IDiscoveryRes<T>> {
  try {
    const response = await customFetch(`${discoveryUrl}/mainnet/${fileName}.json`);

    const discovery = await response.json();
    const pop = response.headers.get('x-amz-cf-pop') || '';

    return {
      data: discovery,
      pop,
    };
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function fetchDiscoveryChain(
  oracleChainId: TOracleChainId,
): Promise<IDiscoveryRes<IDiscoveryChainData>> {
  try {
    const {
      data, pop,
    } = await fetchDiscovery<IDiscoveryChainData>(`chains/${oracleChainId}`);

    return {
      data,
      pop,
    };
  } catch (e) {
    return Promise.reject(e);
  }
}
