import { createSelector, Selector } from '@reduxjs/toolkit';

import { config } from 'config';
import { url } from 'utils';
import { IDiscoveryChainData, IDiscoveryChainInfo } from 'api';
import { RootState } from '../index';
import { ITokensList, ITokensListMap } from './types';

const { supportedChainId } = config;

export const isMobile = (state: RootState): boolean => state.app.isMobile;
export const isVideoModalOpen = (state: RootState): boolean => state.app.modals.videoModalOpen;
export const isCreateFarmModalOpen = (state: RootState): boolean => state.app.modals.createFarmOpen;

const chainInfoSelector = (state: RootState): IDiscoveryChainInfo | undefined => state.app.discoveryChain?.chainInfo;
export const selectReferralFarmContractAddress: Selector<RootState, string> = createSelector(
  chainInfoSelector,
  (chainInfo) => chainInfo?.referralFarmsV1?.find((e) => Number(e.chainId) === Number(supportedChainId))?.address || '',
);

export const selectOracleUrl: Selector<RootState, string> = createSelector(
  chainInfoSelector,
  (chainInfo) => {
    if (chainInfo) {
      return url.getOracleUrl(chainInfo.oracles);
    }

    return '';
  },
);

export const selectConfirmationV1Address: Selector<RootState, string> = createSelector(
  chainInfoSelector,
  (chainInfo) => chainInfo?.confirmationsV1?.find((e) => Number(e.chainId) === Number(supportedChainId))?.address ?? '',
);

const tokenListSelector = (state: RootState): ITokensList => state.app.tokensList;
export const selectTokenList: Selector<RootState, ITokensListMap> = createSelector(
  tokenListSelector,
  (tokenList) => new Map(tokenList.map((token) => [token.address.toLowerCase(), token])),
);

export const selectDiscoveryChain = (state: RootState): IDiscoveryChainData | null => state.app.discoveryChain;
