import { FC } from 'react';

import { CloseIcon } from 'components';

import { cx } from '@emotion/css';
import styles from './closeBtn.module.css';

interface ICloseBtn {
    handleClick: () => void;
}

export const CloseBtn: FC<ICloseBtn> = ({ handleClick }) => (
  <button
    className={cx(styles.closeBtn, 'flex-center')}
    type="button"
    onClick={handleClick}
  >
    <CloseIcon />
  </button>
);
