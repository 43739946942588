export type JsonRpcRequestId = number | string;
export type JsonRpcResponseId = JsonRpcRequestId | null;

export interface JsonRPCError extends Error {
  code: number; // Json Rpc compatible code
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: unknown;
}

export interface JsonRpcResponse<T = never> {
  jsonrpc: '2.0';
  id: JsonRpcResponseId;
  result?: T;
  error?: JsonRPCError
}

/* eslint-disable camelcase */
export enum RpcMethod {
  eth_getLogs = 'eth_getLogs',
}

/* eslint-disable camelcase */
export enum RpcOracleMethod {
  oracle_chainId = 'oracle_chainId',
  oracle_getDerivedBlockByHash = 'oracle_getDerivedBlockByHash',
  oracle_getBundleReceipt = 'oracle_getBundleReceipt',
  oracle_getDerivedBlockByNumber = 'oracle_getDerivedBlockByNumber',
  oracle_getOperationalAddress = 'oracle_getOperationalAddress',
  // TODO BLOCKER: migrate to the new oracle_call soon!
  oracle_call_old = 'oracle_call_old',
  oracle_getLogs = 'oracle_getLogs',
}

// recommendations requests, are routed to separate path
export enum RecommendationsRpcOracleMethod {
  oracle_getOperationalAddress = 'oracle_getOperationalAddress',
  oracle_getTimePromise = 'oracle_getTimePromise',
  oracle_sendProofOfRecommendationOrigin = 'oracle_sendProofOfRecommendationOrigin',
  oracle_sendProofOfRecommendation = 'oracle_sendProofOfRecommendation',
  oracle_getProofOfRecommendations = 'oracle_getProofOfRecommendations',
  oracle_getProofOfRecommendationOrigin = 'oracle_getProofOfRecommendationOrigin',
}

export enum RpcRoute {
  rpc = '/v1/rpc',
  recommendations = '/v1/recommendations/rpc',
}
