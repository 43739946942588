import { FC } from 'react';
import { cx } from '@emotion/css';

import attrLogoGrey from 'assets/images/attrLogoGrey.svg';
import attrLogoGreyMobile from 'assets/images/attrLogoGreyMobile.svg';

import styles from './styles.module.css';

export const PoweredBy: FC = () => (
  <div className={cx('flex-center', styles.poweredBy)}>
    Powered by
    <img
      src={attrLogoGrey}
      alt="Attrace"
      className={styles.logo}
    />
    {/* mobile */}
    <img
      src={attrLogoGreyMobile}
      alt="Attrace"
      className={styles.mobileLogo}
    />
  </div>
);
