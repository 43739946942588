import { FC, useMemo } from 'react';

import { Tabs } from 'components';
import { ComingSoon } from 'modules/shared';

import { ERC20Token } from 'types';

import { SelectCryptoTokens } from './selectCryptoTokens';

interface ISelectToken {
  onSelectToken: (token: ERC20Token) => void;
}

export const SelectToken: FC<ISelectToken> = ({ onSelectToken }) => {
  const tabs = useMemo(
    () => [
      {
        label: 'Crypto Tokens',
        content: <SelectCryptoTokens
          onSelectToken={onSelectToken}
        />,
      },
      {
        label: 'NFT',
        labelChildren: <ComingSoon />,
        content: 'nft',
        disabled: true,
      },
    ],
    [onSelectToken],
  );

  return (
    <Tabs
      tabs={tabs}
      activeTabIdx={0}
      size="sm"
      // eslint-disable-next-line no-console
      handleTabChange={(e, value) => console.log('tab change', e, value)}
    />
  );
};
