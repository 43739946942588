import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  Backdrop, Box, Fade, Modal as MuiModal,
} from '@mui/material';

import { selectors } from 'store';

import styles from './generalModal.module.css';

const commonStyle = {
  boxShadow: 'none',
  color: 'var(--white)',
  position: 'absolute',
  bgcolor: 'var(--general-modal-body)',
  border: 'var(--general-modal-border)',
  borderRadius: '10px',
};

const modalStyle = {
  ...commonStyle,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  '@media(max-width: 768px)': {
    maxWidth: 'calc(min(100vh,100vw) - 56px)',
    width: 'calc(100% - 56px)',
  },
};

const bottomBarStyle = {
  ...commonStyle,
  bottom: '0',
  right: '0',
  left: '0',
};

const videoModalStyle = {
  ...modalStyle,
  border: 'none',
  maxWidth: 'fit-content !important',
};

const generalModalStyles = {
  bottomBar: bottomBarStyle,
  modal: modalStyle,
  videoModal: videoModalStyle,
};

export interface IGeneralModal {
  open: boolean;
  children: ReactNode;
  onClose?: () => void;
  backdropTransparent?: boolean;
  mode?: 'bottomBar' | 'modal' | 'videoModal'
}

export const GeneralModal: FC<IGeneralModal> = ({
  open,
  children,
  onClose,
  backdropTransparent = false,
  mode,
}) => {
  const isMobile = useSelector(selectors.app.isMobile);

  return (
    <MuiModal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          background: backdropTransparent ? 'transparent' : 'rgba(11, 9, 16, 0.85)',
          backdropFilter: 'blur(8px)',
        },
      }}
      disableAutoFocus
      className={styles.modal}
    >
      <Fade in={open}>
        <Box
          sx={(mode && generalModalStyles[mode]) || (isMobile ? bottomBarStyle : modalStyle)}
          className={styles.box}
        >
          {children}
        </Box>
      </Fade>
    </MuiModal>
  );
};
