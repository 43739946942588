import dappradarMainLogo from 'assets/images/dappradarMainLogo.svg';
import dappradarMobileLogo from 'assets/images/dappradarMobileLogo.svg';
import attrlogo from 'assets/images/attrLogo.svg';
import noRewardsIcon from 'assets/icons/noRewards.svg';
import noRewardsDappradarIcon from 'assets/icons/noRewardsDappradar.svg';
import { routes, partnerRoutes } from 'routes';

import {
  IPartnerTheme, ITheme, ETheme,
} from './types';

const PARTNER_THEMES: { [partnerName: string]: IPartnerTheme } = {
  [ETheme.dappradar]: {
    name: 'DappRadar',
    farmName: 'Cashback',
    logo: dappradarMainLogo,
    mobileLogo: dappradarMobileLogo,
    theme: ETheme.dappradar,
    routes: {
      myRewards: partnerRoutes.PARTNER_MY_REWARDS.replace(':partner', ETheme.dappradar),
    },
    icons: {
      noRewards: noRewardsDappradarIcon,
    },
  },
};

const DEFAULT_THEME = {
  name: 'default',
  farmName: 'Referral',
  logo: attrlogo,
  mobileLogo: attrlogo,
  theme: ETheme.default,
  routes: {
    myRewards: routes.MY_REWARDS,
  },
  icons: {
    noRewards: noRewardsIcon,
  },
};

export const THEMES = (theme?: string): ITheme => (theme && PARTNER_THEMES[theme]) || DEFAULT_THEME;
