import { EDapp } from 'config';

export type TAvailableDexList = {
  [key: string]: boolean
}
export const getAvailableDexList = (
  uniswapV2PollExist = false, uniswapV3PollExist = false, sushiswapV2PollExist = false,
): TAvailableDexList => ({
  [EDapp.UNISWAPV3]: uniswapV2PollExist || uniswapV3PollExist,
  [EDapp.SUSHISWAP]: sushiswapV2PollExist,
});
