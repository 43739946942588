import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { Loadable } from 'components/loadable';
import BasicLayout from 'layout/basic';

import { routes } from './routes';
import { lazyRetry } from './lazyRetry';

const BuyToFarm = Loadable(lazy(() => lazyRetry(() => import('modules/buyersFlow'))));
const ReferralFarms = Loadable(lazy(() => lazyRetry(() => import('modules/referralFarms'))));
const MyFarms = Loadable(lazy(() => lazyRetry(() => import('modules/myFarms'))));
const MyRewards = Loadable(lazy(() => lazyRetry(() => import('modules/myRewards'))));
const PreStaking = Loadable(lazy(() => lazyRetry(() => import('modules/preStaking'))));

const {
  HOME,
  FARMS,
  BUY_TO_EARN,
  BUY_TO_EARN_SELECTED,
  FARMS_SELECTED,
  MY_FARMS,
  MY_REWARDS,
  BUYERS_FLOW,
  ORACLE_STAKING,
  ORACLE_STAKING_V1,
} = routes;

const MainRoutes = {
  path: '/',
  element: <BasicLayout />,
  children: [
    {
      path: HOME,
      element: <Navigate to={FARMS} />,
    },
    {
      path: FARMS_SELECTED,
      element: <ReferralFarms />,
    },
    {
      path: FARMS,
      element: <ReferralFarms />,
    },
    {
      path: BUY_TO_EARN,
      element: <ReferralFarms />,
    },
    {
      path: BUY_TO_EARN_SELECTED,
      element: <ReferralFarms />,
    },
    {
      path: MY_FARMS,
      element: <MyFarms />,
    },
    {
      path: MY_REWARDS,
      element: <MyRewards />,
    },
    {
      path: BUYERS_FLOW,
      element: <BuyToFarm />,
    },
    {
      path: ORACLE_STAKING,
      element: <PreStaking />,
    },
    {
      path: ORACLE_STAKING_V1,
      element: <PreStaking />,
    },
  ],
};

export default MainRoutes;
