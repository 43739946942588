import { customFetch, logger } from 'services';

import { Address } from 'types/web3';

interface IExchangeRate {
 [key: string]: {
   eth: number
 }
}

interface IMockTokenAddress {
  [key: string] : string;
}

export const MOCK_TOKEN_ADDRESS: IMockTokenAddress = {
  '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6': '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  '0x926362b451A012F72b34240F36C3bDc163d462e0': '0x44e2dec86b9f0e0266e9aa66e10323a2bd69cf9a',
  '0xD8eB5b414e1b5485148E063ee64A5cA5c968D1FB': '0x44e2dec86b9f0e0266e9aa66e10323a2bd69cf9a',
};

const coingeckoApi = 'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=';

// NOTE: only for testnet and local dev
export async function getConversationRateTestnet(tokens: Address[]): Promise<IExchangeRate> {
  try {
    const addresses: string[] = [];
    tokens.forEach((token) => {
      addresses.push(MOCK_TOKEN_ADDRESS[token]);
    });

    const url = `${coingeckoApi}${addresses.toString()}&vs_currencies=eth`;
    const res = await (await customFetch(url)).json();

    const mappedRes: IExchangeRate = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(MOCK_TOKEN_ADDRESS)) {
      if (res[value]) {
        mappedRes[key] = {
          eth: res[value].eth,
        };
      }
    }

    return mappedRes;
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'api.coingecko.getConversationRateTestnet',
    });

    return Promise.resolve({});
  }
}

export async function getConversationRate(token: Address[]): Promise<IExchangeRate> {
  try {
    const url = `${coingeckoApi}${token.toString()}&vs_currencies=eth`;
    return await (await customFetch(url)).json();
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'api.coingecko.getConversationRate',
    });
    return Promise.resolve({});
  }
}

export async function getConversationRateUSD(token: Address): Promise<number> {
  try {
    const url = `${coingeckoApi}${token.toString()}&vs_currencies=usd`;
    const res = await (await customFetch(url)).json();
    return res[token.toString().toLowerCase()]?.usd; // to lower case @#$%@#$%
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'api.coingecko.getConversationRateUSD',
    });
    return Promise.reject(error);
  }
}
