import { toast } from 'react-toastify';
import {
  CSSProperties, ReactNode, ReactText,
} from 'react';
import { ToastOptions } from 'react-toastify/dist/types';

import {
  SuccessIcon, ErrorIcon, InfoIcon, WarningIcon,
} from 'components';

const defaultStyle = {
  color: 'var(--white-90)',
  margin: '50px auto 1rem auto',
};

const toastSuccess = (message: string):
  ReturnType<typeof toast.success> => toast.success(message, {
  style: defaultStyle,
  icon: SuccessIcon,
});

const toastError = (message: string, options?: ToastOptions): void => {
  toast.error(message, {
    style: defaultStyle,
    ...options,
    icon: ErrorIcon,
  });
};

const warnStyle = {
  left: '0',
  right: '0',
  border: 'none',
  width: '100%',
  height: '54px',
  background: 'var(--secondary-link-9)',
  color: 'var(--white-70)',
};

const toastWarn = ({
  component,
  options,
  style,
} : {
  component: string | ReactNode,
  options?: ToastOptions,
  style?: CSSProperties
}): ReactText =>
  toast.warn(component, {
    style: {
      ...warnStyle,
      ...style,
    },
    position: 'top-center',
    icon: WarningIcon,
    autoClose: false,
    closeOnClick: false,
    ...options,
  });

const infoStyle = {
  left: '0',
  right: '0',
  border: 'none',
  width: '100%',
  height: '54px',
  background: 'var(--secondary-link-9)',
  color: 'var(--white-70)',
};

const toastInfo = (message: string, options?: ToastOptions): ReactText => toast.info(message, {
  ...options,
  style: infoStyle,
  autoClose: false,
  icon: InfoIcon({
    opacity: '.7',
    size: '20',
  }),
});

const dismissToast = (id?: ReactText): void => {
  toast.dismiss(id);
};

export {
  toastSuccess,
  toastError,
  toastWarn,
  toastInfo,
  dismissToast,
};
