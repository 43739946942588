import { ReactNode, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Tooltip } from 'components';

import { selectors } from 'store';
import { TTableColumns } from 'types';

import styles from './styles.module.css';

interface ITable {
  columns: TTableColumns;
  body: ReactNode;
  ariaLabel?: string;
}

function Table({
  columns, body, ariaLabel,
}: ITable): ReactElement {
  const isMobile = useSelector(selectors.app.isMobile);

  return (
    <div className={styles.root}>
      <table aria-label={ariaLabel}>
        {!isMobile && (
          <colgroup>
            {columns.map((column) => (
              <col
                style={{
                  width: column.width,
                }}
                key={column.header}
              />
            ))}
          </colgroup>
        )}
        <thead>
          <tr>
            {columns.map(({
              header, key, tooltip,
            }) => (
              <th
                className={styles.tableHeader}
                key={key}
              >
                <div className={styles.tableHeaderCell}>
                  {header}
                  {tooltip && (
                    <Tooltip
                      content={tooltip}
                      className={styles.tooltip}
                      iconSize="20"
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  );
}

export default Table;
