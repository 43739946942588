import { FC } from 'react';
import {
  Checkbox as MUICheckbox, styled, CheckboxProps,
} from '@mui/material';
import { cx } from '@emotion/css';

import styles from './Checkbox.module.css';

const DefaultCheckbox = styled(MUICheckbox)<CheckboxProps>(() => ({
  width: '16px',
  height: '16px',
  padding: 0,
  border: '2px solid var(--grey-60)',
  borderRadius: '3px',
  color: 'var(--transparent)',

  '& .PrivateSwitchBase-input': {
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    width: '16px',
    height: '16px',
  },

  '&:hover': {
    borderColor: 'var(--grey-90)',
    background: 'none',
  },
  '&:focus': {
    outline: '2px solid var(--checkbox-checked-color)',
  },

  '&.Mui-checked': {
    border: '2px solid var(--checkbox-checked-color)',
    color: 'var(--checkbox-checked-color)',
    '&:hover': {
      borderColor: 'var(--checkbox-hover-color)',
      color: 'var(--checkbox-hover-color)',
    },

    '&:focus': {
      border: 'none',
    },
  },

  '& .MuiSvgIcon-root': {
    fontSize: '18px',
  },

  '&.Mui-disabled': {
    color: 'var(--transparent)',
    borderColor: 'var(--grey-30)',
    cursor: 'default',
    pointerEvents: 'all',
  },
  '&.Mui-disabled.Mui-checked': {
    borderColor: 'var(--purple-30)',
    color: 'var(--purple-30)',
  },

  '&.MuiCheckbox-indeterminate': {
    borderColor: 'var(--purple)',
    color: 'var(--purple)',
  },
}));

interface ICheckbox {
  id: string;
  handleClick?: (id: string) => void;
  handleChange?: () => void;
  disabled?: boolean;
  text?: string;
  indeterminate?: boolean;
  checked?:boolean;
}

export const Checkbox:FC<ICheckbox> = ({
  id,
  handleClick,
  handleChange,
  disabled = false,
  text = false,
  indeterminate = false,
  checked,
}) => (
  <div className={cx(styles.checkbox, 'flex-center')}>
    <DefaultCheckbox
      id={id}
      onClick={(e) => {
        e.stopPropagation();
        if (handleClick) {
          handleClick(id);
        }
      }}
      onChange={handleChange}
      disabled={disabled}
      indeterminate={indeterminate}
      checked={checked}
    />
    {text && (
      <span className={cx(styles.checkboxText,
        {
          [styles.disabledCheckboxText]: disabled,
        })}
      >
        {text}
      </span>
    )}
  </div>

);
