import { FC } from 'react';
import { cx } from '@emotion/css';

import { ITokenAmount } from '../types';
import { TooltipLock } from './tooltipLock';

import styles from './styles.module.css';

export const TokenAmount: FC<ITokenAmount> = ({
  amount, tokenSymbol, rewardsLockTime,
}) => (
  <div className={styles.tokenAmount}>
    {rewardsLockTime ? (
      <div className={styles.lockIcon}>
        <TooltipLock rewardsLockTime={rewardsLockTime} />
      </div>
    ) : null}
    <div className={styles.amount}>
      {amount}
    </div>
    {tokenSymbol && <span className={cx(styles.tokenSymbol)}>{tokenSymbol}</span>}
  </div>
);
