import { utils } from 'ethers';
import { FormatTypes } from '@ethersproject/abi';

import { TNodeUrl } from 'api/discovery';
import {
  Address, ChainId, IEventLog,
} from 'types/web3';

import { indexer } from '../indexer';
import { parseEvents } from '../parseEvents';
import { IConfirmationFinalizedEvent } from './types';

const abi = [
  'function getHead() view returns (bytes32)',
  'function getConfirmation(bytes32 confirmationHash) view returns(uint128 number, uint64 timestamp)',

  `event ConfirmationFinalized(
    bytes32 indexed confirmationHash,
    uint128 indexed number,
    bytes32 stateRoot,
    bytes32 parentHash,
    uint64 timestamp,
    bytes32 bundleHash,
    bytes32 indexed closerHash,
    uint32 blockCount,
    bytes32 blockHash,
    uint64 confirmChainBlockNr
  )`,
];

const iface = new utils.Interface(abi);

type IConfirmationFinalizedEventsRes = Map<string, IConfirmationFinalizedEvent>

export function parseConfirmationFinalizedEvents(unparsed: IEventLog[]): IConfirmationFinalizedEventsRes {
  const parsed = parseEvents(unparsed, iface);

  const confirmationFinalizedMap = new Map();
  parsed.forEach((e) => {
    confirmationFinalizedMap.set(e.args.confirmationHash, {
      ...e.args,
    });
  });

  return confirmationFinalizedMap;
}

async function getConfirmationFinalizedEvents(
  oracleUrl: TNodeUrl,
  chainId: ChainId,
  confirmationsV1Address: Address,
): Promise<IConfirmationFinalizedEventsRes> {
  const res = await indexer.queryIndexer(oracleUrl, {
    addresses: [confirmationsV1Address],
    topic1: [utils.id(iface.getEvent('ConfirmationFinalized').format(FormatTypes.sighash))],
    chainId: [chainId],
  });

  return parseConfirmationFinalizedEvents(res.items);
}

export const confirmationsV1 = {
  getConfirmationFinalizedEvents,
};
