import { Address } from 'types/web3';

import { TWeb3Instance } from 'services';
import { RootState } from '../index';
import { IBalances } from './types';

export const selectAccount = (state: RootState): Address => state.wallet.account;
export const selectWalletChainId = (state: RootState): number => state.wallet.walletChainId;
export const selectWeb3Instance = (state: RootState): TWeb3Instance => state.wallet.web3Instance;
export const selectSwitchNetworkRequired = (state: RootState): boolean => state.wallet.isSwitchNetworkRequired;
export const selectBalances = (state: RootState): IBalances => state.wallet.balances;
