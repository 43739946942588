import { FC } from 'react';

import { IIcon } from './types';

const ArrowLeftIcon: FC<IIcon> = ({ opacity = '1' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={opacity}>
      <path
        d="M20.1569 10.657C20.433 10.657 20.6569 10.8808 20.6569 11.157V12.157C20.6569 12.4331 20.433 12.657 20.1569 12.657L7.82854 12.657L10.7175 15.5459C10.9128 15.7412 10.9128 16.0578 10.7175 16.253L10.0104 16.9602C9.81515 17.1554 9.49856 17.1554 9.3033 16.9602L4.35355 12.0104C4.15829 11.8151 4.15829 11.4986 4.35355 11.3033L9.3033 6.35355C9.49856 6.15829 9.81515 6.15829 10.0104 6.35355L10.7175 7.06066C10.9128 7.25592 10.9128 7.5725 10.7175 7.76777L7.82831 10.657L20.1569 10.657Z"
        fill="white"
      />
    </g>
  </svg>

);

export default ArrowLeftIcon;
