import {
  createContext, FC, useCallback, useContext, useState,
} from 'react';

interface IError {
  title: string;
  subTittle?: string;
}

interface IAppErrorModal {
  open: boolean;
  appError?: IError;
  onToggleAppErrorModal: (error: IError) => void;
}

const AppErrorModalContext = createContext<IAppErrorModal>({} as IAppErrorModal);

// TODO: replace to snackbar
export const AppErrorModalProvider: FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [appError, setAppError] = useState<IError>();

  const onToggleAppErrorModal = useCallback((error: IError) => {
    console.log('onToggleAppErrorModal error', error);
    setOpen((prevValue) => !prevValue);
    setAppError(error);
  }, []);

  return (
    <AppErrorModalContext.Provider
      value={{
        open,
        appError,
        onToggleAppErrorModal,
      }}
    >
      {children}
    </AppErrorModalContext.Provider>
  );
};

export const useAppErrorModalContext = (): IAppErrorModal => useContext(AppErrorModalContext);
