import { useCallback, useEffect } from 'react';
import { useMutation, QueryStatus } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import {
  AppDispatch, selectors, thunkActions,
} from 'store';
import {
  JsonRPCError, JsonRpcResponse, por, sentry, web3, useFinishActionWarn,
} from 'services';
import { EDapp } from 'config';

import { Address } from 'types/web3';
import { TRANSACTION_STATUS } from '../../types';

interface IUseBuyToFarm {
  trigger: () => Promise<void>;
  isIdle: boolean;
  status: QueryStatus;
  error: JsonRPCError | null;
  reset: () => void;
}

export const useBuyToFarm = (
  tokenAddress: Address,
  referrerAddress: Address,
  dapp: EDapp,
  oracleUrl: string,
): IUseBuyToFarm => {
  const dispatch: AppDispatch = useDispatch();

  const account = useSelector(selectors.wallet.selectAccount);
  const web3Instance = useSelector(selectors.wallet.selectWeb3Instance);

  const {
    showFinishActionWarn, dismissFinishActionWarn,
  } = useFinishActionWarn();

  const buyFarm = useMutation<JsonRpcResponse, JsonRPCError>(async () => {
    sentry.setUserContext({
      userAddress: account,
      tokenAddress,
    });
    showFinishActionWarn();

    return por.signAndPostProofOfRecommendationWithReferrer(
      account, tokenAddress, referrerAddress, dapp, oracleUrl, web3.getWeb3Provider(web3Instance),
    );
  });

  const triggerBuyToFarm = useCallback(
    async () => {
      if (!account || !web3Instance) {
        dispatch((thunkActions.wallet.connectWallet()))
          .unwrap()
          .then(() => buyFarm.mutate());
      } else {
        buyFarm.mutate();
      }
    },
    [account, web3Instance, buyFarm, dispatch],
  );

  useEffect(() => {
    if ((buyFarm.status === TRANSACTION_STATUS.success || buyFarm.status === TRANSACTION_STATUS.error)) {
      dismissFinishActionWarn();
    }
  }, [buyFarm.status, dismissFinishActionWarn]);

  return {
    trigger: triggerBuyToFarm,
    isIdle: buyFarm.isIdle,
    status: buyFarm.status,
    error: buyFarm.error,
    reset: buyFarm.reset,
  };
};
