import { IContractError } from './types';

const GENERAL_ERROR = 'Something went wrong. Please try again';

enum EContractsErrors {
  'UNPREDICTABLE_GAS_LIMIT' = 'UNPREDICTABLE_GAS_LIMIT'
}
export function parseContractError(error: IContractError): Error {
  switch (error.code) {
    case EContractsErrors.UNPREDICTABLE_GAS_LIMIT: {
      return Error(error.error.message);
    }

    default: {
      return Error(error?.error?.message || GENERAL_ERROR);
    }
  }
}
