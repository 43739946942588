import { utils } from 'ethers';
import { FormatTypes } from '@ethersproject/abi';

import { eth } from 'api';
import { logger } from 'services';
import { address } from 'utils';
import { Address } from 'types/web3';
import {
  uniswapv2FactoryAddr, uniswapv3FactoryAddr, sushiswapv2FactoryAddr,
} from 'config';

const abiFactoryV2 = [
  'event PairCreated(address indexed token0, address indexed token1, address pair, uint)',
];
const abiUniswapV3 = [
  'event PoolCreated(address indexed token0, address indexed token1, uint24 indexed fee, int24 tickSpacing, address pool)',
];

const ifaceFactoryV2 = new utils.Interface(abiFactoryV2);
const ifaceUniswapV3 = new utils.Interface(abiUniswapV3);

export async function checkUniswapV2TokenPoolExist(
  tokenAddress: Address,
): Promise<boolean> {
  try {
    const tokenAddrLowerCase = tokenAddress.toLowerCase();
    const requestParams = [{
      topics: [utils.id(ifaceFactoryV2.getEvent('PairCreated').format(FormatTypes.sighash)), address.expandEvmAddressToBytes32(tokenAddrLowerCase)],
    }, {
      topics: [utils.id(ifaceFactoryV2.getEvent('PairCreated').format(FormatTypes.sighash)), null, address.expandEvmAddressToBytes32(tokenAddrLowerCase)],
    }];

    return Promise.all(requestParams.map((params) => eth.getLogs({
      address: uniswapv2FactoryAddr.toLowerCase(),
      fromBlock: '0x1',
      ...params,
    })))
      .then(([uniswapV2Smallest, uniswapV2Biggest]) => Boolean(uniswapV2Smallest?.length || uniswapV2Biggest?.length))
      .catch((error) => {
        logger.logError(error, {
          function: 'dex.getUniswapV2PollForToken',
        });
        const manyPoolExist = error?.message?.includes('query returned more than 10000 results');

        return manyPoolExist || false;
      });
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'dex.getUniswapV2PollForToken',
    });
    return Promise.reject(error);
  }
}

export async function checkUniswapV3TokenPoolExist(
  tokenAddress: Address,
): Promise<boolean> {
  try {
    const tokenAddrLowerCase = tokenAddress.toLowerCase();
    const requestParams = [{
      topics: [utils.id(ifaceUniswapV3.getEvent('PoolCreated').format(FormatTypes.sighash)), address.expandEvmAddressToBytes32(tokenAddrLowerCase)],
    }, {
      topics: [utils.id(ifaceUniswapV3.getEvent('PoolCreated').format(FormatTypes.sighash)), null, address.expandEvmAddressToBytes32(tokenAddrLowerCase)],
    }];

    return Promise.all(requestParams.map((params) => eth.getLogs({
      address: uniswapv3FactoryAddr.toLowerCase(),
      fromBlock: '0x1',
      ...params,
    })))
      .then(([uniswapV3Smallest, uniswapV3Biggest]) => Boolean(uniswapV3Smallest?.length || uniswapV3Biggest?.length))
      .catch((error) => {
        const manyPoolExist = error?.message?.includes('query returned more than 10000 results');

        logger.logError(error, {
          function: 'dex.getUniswapV3PollForToken',
        });

        return manyPoolExist || false;
      });
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'dex.getUniswapV3PollForToken',
    });
    return Promise.reject(error);
  }
}

export async function checkSushiswapV2TokenPoolExist(
  tokenAddress: Address,
): Promise<boolean> {
  try {
    const tokenAddrLowerCase = tokenAddress.toLowerCase();
    const requestParams = [{
      topics: [utils.id(ifaceFactoryV2.getEvent('PairCreated').format(FormatTypes.sighash)), address.expandEvmAddressToBytes32(tokenAddrLowerCase)],
    }, {
      topics: [utils.id(ifaceFactoryV2.getEvent('PairCreated').format(FormatTypes.sighash)), null, address.expandEvmAddressToBytes32(tokenAddrLowerCase)],
    }];

    return Promise.all(requestParams.map((params) => eth.getLogs({
      address: sushiswapv2FactoryAddr.toLowerCase(),
      fromBlock: '0x1',
      ...params,
    })))
      .then(([resSmallest, resBiggest]) => Boolean(resSmallest?.length || resBiggest?.length))
      .catch((error) => {
        logger.logError(error, {
          function: 'dex.getSushiswapV2PollForToken',
        });
        const manyPoolExist = error?.message?.includes('query returned more than 10000 results');

        return manyPoolExist || false;
      });
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'dex.getSushiswapV2PollForToken',
    });
    return Promise.reject(error);
  }
}
