import { FC } from 'react';
import { cx } from '@emotion/css';

import { PROTOCOL_FEE } from 'config';
import { numbers } from 'utils';
import { ERC20Token } from 'types';

import { ErrorMessage } from 'modules/shared';

import styles from './depositRewards.module.css';

interface IDepositRewards {
  totalFarmRewards: string;
  depositTotal: number;
  token: ERC20Token;
  balance: number;
  insufficientFunds: boolean
}
export const DepositRewards: FC<IDepositRewards> = ({
  totalFarmRewards, depositTotal, token, balance, insufficientFunds,
}) => (
  <>
    <div className={cx('flex-space-between', styles.row)}>
      Your Balance
      <span className={cx('flex-align-center', styles.value)}>
        {insufficientFunds && (
          <ErrorMessage
            message="Insufficient funds"
            className={styles.errorMessage}
          />
        )}
        {`${numbers.formatNumber(+balance)} ${token?.symbol}`}
      </span>
    </div>
    <div className={cx('flex-space-between', styles.row)}>
      Total Farm Rewards
      <span className={styles.value}>
        {`${numbers.formatNumber(+totalFarmRewards, token?.decimals)} ${token?.symbol}`}
      </span>
    </div>
    <div className={cx('flex-space-between', styles.row)}>
      Referral Protocol Fee 5%
      <span className={styles.value}>
        {`${numbers.formatNumber(Number(totalFarmRewards) * PROTOCOL_FEE, token?.decimals)} ${token?.symbol}`}
      </span>
    </div>
    <div className={cx('flex-space-between', styles.depositTotal)}>
      Deposit Total
      <span className={styles.value}>
        {`${depositTotal} ${token?.symbol}`}
      </span>
    </div>
  </>
);
