import {
  FC, ReactElement, ReactNode, useCallback, useEffect, useState,
} from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Button, TransactionModal } from 'components';

import {
  actions,
  AppDispatch, selectors, thunkActions,
} from 'store';
import {
  JsonRPCError, JsonRpcResponse, TAvailableDexList,
  por, web3,
  getAvailableDexList, useGetDexesPool, useFinishActionWarn,
} from 'services';

import { address, url } from 'utils';
import { EDapp, config } from 'config';
import { TRANSACTION_STATUS, TTransactionStatus } from 'types';
import { Address } from 'types/web3';

import { BUY_TO_EARN_STEPS_SUCCESSFUL, REFER_TO_EARN_TOOLTIPS } from '../../config';
import { IReferralFarmsFarmRow } from '../../../types';

import { ReferToFarmSuccessfulContent, ReferToFarmSuccessfulFooter } from './referToFarmSuccessfull';
import { SelectedFarmModal } from '../selectedFarmModal';

type TSuccessfulConfig = {
  successfulStepId: number,
  referToken: Address;
  onChangeSuccessfulStepId: (type: 'next' | 'prev') => void,
  handleClose: () => void,
  onChangeLinkCopied: () => void,
  linkCopied: boolean,
  copyLinks?: { [key: string]: string },
  availableDexList: TAvailableDexList
}

const getTransactionInfo = (
  status: TTransactionStatus,
  successfulConfig: TSuccessfulConfig,
  subTitle?: string,
): { title: string; subTitle?: string; content?: ReactElement, footer?: ReactNode } => {
  if (status === TRANSACTION_STATUS.loading) {
    return {
      title: 'Complete Signature Request',
      subTitle: 'Sign the message to register your address for rewards',
    };
  }
  if (status === TRANSACTION_STATUS.success) {
    const {
      handleClose, successfulStepId, onChangeSuccessfulStepId, linkCopied, onChangeLinkCopied, availableDexList,
    } = successfulConfig;
    return {
      title: 'Successfully Registered',
      subTitle: 'Grab your referral link, spread the word and earn rewards!',
      content: successfulConfig?.copyLinks && (
        <ReferToFarmSuccessfulContent
          successfulStepId={successfulStepId}
          links={successfulConfig.copyLinks}
          availableDexList={availableDexList}
          onChangeLinkCopied={onChangeLinkCopied}
        />
      ),
      footer: <ReferToFarmSuccessfulFooter
        handleDismiss={handleClose}
        linkCopied={linkCopied}
        successfulStepId={successfulStepId}
        onChangeSuccessfulStepId={onChangeSuccessfulStepId}
      />,
    };
  }

  return {
    title: 'Transaction Failed',
    subTitle,
  };
};

const { homePageUrl } = config;

interface IReferToFarm {
  selectedFarm: IReferralFarmsFarmRow;
  handleClose: () => void;
}

export const ReferToFarmModal: FC<IReferToFarm> = ({
  selectedFarm, handleClose,
}) => {
  const { referToken } = selectedFarm;
  const referTokenAddress = address.parseChainAddress(referToken.referredTokenDefn).address;

  const dispatch: AppDispatch = useDispatch();

  const account = useSelector(selectors.wallet.selectAccount);
  const web3Instance = useSelector(selectors.wallet.selectWeb3Instance);

  const [copyLinks, setCopyLinks] = useState<{ [key: string]: string } | undefined>(undefined);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const [successfulStepId, setSuccessfulStepId] = useState<number>(BUY_TO_EARN_STEPS_SUCCESSFUL[0].id);

  const oracleUrl = useSelector(selectors.app.selectOracleUrl);

  const [isModalOpen, setIsOpenModal] = useState(Boolean(selectedFarm));

  const {
    showFinishActionWarn, dismissFinishActionWarn,
  } = useFinishActionWarn();
  const referFarm = useMutation<JsonRpcResponse, JsonRPCError>(() => {
    showFinishActionWarn();

    return por.signAndPostProofOfRecommendationOrigin(
      account,
      referTokenAddress,
      oracleUrl,
      web3.getWeb3Provider(web3Instance),
    );
  });

  const { poolExists } = useGetDexesPool(referToken.address);

  useEffect(() => {
    if (referFarm.status === TRANSACTION_STATUS.success || referFarm.status === TRANSACTION_STATUS.error) {
      dismissFinishActionWarn();
    }
  }, [referFarm.status, dismissFinishActionWarn]);

  useEffect(() => {
    if (referFarm.isSuccess) {
      const links = {
        [EDapp.UNISWAPV3]: url.generateReferralLink(referTokenAddress, account, EDapp.UNISWAPV3),
        [EDapp.SUSHISWAP]: url.generateReferralLink(referTokenAddress, account, EDapp.SUSHISWAP),
      };
      setCopyLinks(links);
    }
  }, [referFarm.isSuccess, referTokenAddress, account]);

  const onClickReferToFarm = useCallback(
    async () => {
      if (!account || !web3Instance) {
        dispatch((thunkActions.wallet.connectWallet()))
          .unwrap()
          .then(() => referFarm.mutate());
      } else {
        referFarm.mutate();
      }
    },
    [account, web3Instance, referFarm, dispatch],
  );

  const onCloseModal = useCallback(async () => {
    setIsOpenModal(false);
    handleClose();
    referFarm.reset();
  }, [referFarm, handleClose]);

  const onChangeSuccessfulStepId = useCallback((type) => {
    setSuccessfulStepId((prevStepId) => (type === 'next' ? prevStepId + 1 : prevStepId - 1));
  }, []);

  const onChangeLinkCopied = useCallback(() => {
    setLinkCopied(true);
  }, []);

  if (!referFarm.isIdle && poolExists) {
    const availableDexList = getAvailableDexList(...poolExists);
    const transactionBodyDetails = getTransactionInfo(
      referFarm.status, {
        successfulStepId,
        onChangeSuccessfulStepId,
        handleClose,
        onChangeLinkCopied,
        linkCopied,
        referToken: selectedFarm.referToken.address,
        copyLinks,
        availableDexList,
      }, referFarm?.error?.message,
    );

    return (
      <TransactionModal
        status={referFarm.status}
        onClose={onCloseModal}
        onRetry={onClickReferToFarm}
        {...transactionBodyDetails}
      />
    );
  }

  return (
    <SelectedFarmModal
      title="Refer to Earn"
      subtitle="Recommend the crypto token and receive rewards based on the value of tokens purchased via your referral link 📣"
      tooltips={REFER_TO_EARN_TOOLTIPS}
      hint={(
        <div>
          Check our
          <a
            className="linkHoverUnderline"
            href={`${homePageUrl}/guides/referral-farming/how-it-works`}
            target="_blank"
            rel="noreferrer"
          >
            <strong>
              {' Guides '}
            </strong>
          </a>
          {'to learn more or watch '}
          <button
            className="linkHoverUnderline"
            type="button"
            onClick={() => dispatch(actions.app.toggleVideoModal())}
          >
            <strong>
              this short video.
            </strong>
          </button>
        </div>
      )}
      isOpen={isModalOpen}
      selectedFarm={selectedFarm}
      onCloseModal={handleClose}
      footerBtn={(
        <Button
          onClick={onClickReferToFarm}
        >
          Create Referral Link
        </Button>
      )}
    />
  );
};
