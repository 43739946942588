import {
  IState,
  ITheme,
} from './types';

export type TAction =
    { type: 'setTheme'; payload: ITheme };

const handlers = {
  DEFAULT: (state: IState) => state,
  setTheme: (state: IState, action: TAction) => ({
    ...state,
    ...action.payload,
  }),
};

export const themeReducer = (state: IState, action: TAction): InstanceType<any> => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state, action);
};

export const actions = {
  setTheme: (payload: ITheme): TAction => ({
    type: 'setTheme',
    payload,
  }),
};
