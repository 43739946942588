import {
  discovery, IDiscoveryTokenList, EDiscoveryFileName,
} from 'api';
import { customFetch } from 'services';

import { ERC20Token } from 'types';

/**
 * fetch token list API urls from https://discovery.attrace.com/tokenLists.json
 * @param chainId
 * @return token list urls per chainId
 */
export async function fetchTokenListUrls(
  chainId: number,
): Promise<string[] | undefined> {
  try {
    const { data } = await discovery.fetchDiscovery<IDiscoveryTokenList>(EDiscoveryFileName.tokenLists);

    const listUrls = Object.entries(data?.tokenLists)
      .find(([tokenListChainId]) => Number(tokenListChainId) === chainId)?.[1]
      .map((tokenList) => tokenList.url);

    return listUrls;
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Fetch ERC20 tokens from multiple services: Attrace Discovery and Mask
 * @param urls
 */
export async function fetchERC20Tokens(chainId: number): Promise<ERC20Token[]> {
  try {
    const urls = await fetchTokenListUrls(chainId);
    if (!urls) {
      return [];
    }

    const allRequest = urls.map(async (url) => {
      const res = await (await customFetch(url)).json();

      return res?.tokens || [];
    });

    const allListResponse = await Promise.allSettled(allRequest);
    return allListResponse.map((res) => {
      if (res.status === 'fulfilled') {
        return res.value;
      }

      return [];
    });
  } catch (e) {
    return Promise.reject(e);
  }
}
