import { FC, CSSProperties } from 'react';
import { cx } from '@emotion/css';
import { useDispatch } from 'react-redux';

import { Button, WalletIcon } from 'components';
import { buttonSizes } from 'components/button/button.config';

import { AppDispatch, thunkActions } from 'store';

import styles from './styles.module.css';

interface IConnectBtn {
  variant?: 'secondary' | 'primary' | 'tertiary';
  iconSize?: string;
  btnSize?: keyof typeof buttonSizes;
  customStyles?: CSSProperties;
  className?: string;
}

export const ConnectBtn: FC<IConnectBtn> = ({
  variant = 'secondary', iconSize = '24', btnSize = 'md', customStyles, className,
}) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <Button
      variant={variant}
      size={btnSize}
      onClick={() => dispatch(thunkActions.wallet.connectWallet())}
      className={cx(styles.connectBtn, className)}
      customStyles={customStyles}
    >
      <WalletIcon
        size={iconSize}
      />
      Connect
    </Button>
  );
};
