import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// import {
//   useLocation,
//   useNavigationType,
//   createRoutesFromChildren,
//   matchRoutes,
// } from 'react-router-dom';

import { env as envUtils } from 'utils';

import { config } from 'config';

const {
  sentryEnabled, sentryDsn, env,
} = config;

const initSentry = (sentryOptions: Sentry.BrowserOptions): void => Sentry.init(sentryOptions);

export const useSentry = () : void => {
  useEffect(() => {
    if (window.location.host.includes('improve')) return;

    if (sentryEnabled) {
      const sentryOptions: Sentry.BrowserOptions = {
        dsn: sentryDsn,
        integrations: [new BrowserTracing()],
        release: `app@${envUtils.getAppVersion()}`,
        tunnel: 'https://tunnel.attrace.com/.netlify/functions/tunnel',
        tracesSampleRate: 1.0,
        environment: env,
      };

      initSentry(sentryOptions);
    }
  }, []);
};
