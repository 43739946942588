import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import { actions } from 'store';

enum EBreakpoints {
  mobile = 768
}

export function useWindowResize(): void {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = debounce(() => {
      const isMobile = window.innerWidth < EBreakpoints.mobile;
      dispatch(actions.app.setIsMobile(isMobile));
    }, 300);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);
}
