function getItem<T>(key: string): T | '' {
  const storedData = localStorage.getItem(key) || '';
  return storedData ? JSON.parse(storedData) : '';
}

function setItem<T>(key: string, value: T): void {
  localStorage.removeItem(key);
  localStorage.setItem(key, JSON.stringify(value));
}

const removeItem = (key: string): void => {
  localStorage.removeItem(key);
};

const clear = (): void => {
  localStorage.clear();
};

export const storage = {
  getItem,
  setItem,
  removeItem,
  clear,
};
