import { cx } from '@emotion/css';
import { FC } from 'react';

import styles from './containerFixedBottom.module.css';

interface IContainerlFixedBottom {
  className?: string;
}

export const ContainerFixedBottom: FC<IContainerlFixedBottom> = ({
  children, className,
}) => (
  <div className={cx(styles.container, className)}>
    {children}
  </div>
);
