import {
  FC, ReactChild, ReactFragment, useState,
} from 'react';
import { ClickAwayListener } from '@mui/base';
import { cx } from '@emotion/css';

import { QuestionCircleIcon } from 'components';
import { StyledTooltip } from './styledTooltip';

import styles from './tooltip.module.css';

type TPlacement = 'bottom' | 'top';

interface ITooltip {
  content: string | boolean | ReactChild | ReactFragment;
  placement?: TPlacement;
  className?: string;
  iconSize?: string;
  isOpen?: boolean;
  isDisabled?: boolean;
}

export const Tooltip: FC<ITooltip> = ({
  content,
  placement = 'bottom',
  className,
  iconSize,
  isOpen = false,
  isDisabled = false,
  children = <QuestionCircleIcon
    size={iconSize}
  />,
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleTooltipClose = (): void => setOpen(false);
  const handleTooltipOpen = (): void => setOpen(true);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <StyledTooltip
          open={open}
          title={content}
          placement={placement}
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          arrow
          disableFocusListener
        >
          <button
            type="button"
            className={cx(styles.tooltip, 'flex-center', className, {
              [styles.tooltipWithHover]: !isDisabled,
            })}
            onClick={handleTooltipOpen}
          >
            {children}
          </button>
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  );
};
