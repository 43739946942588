import { FC } from 'react';

import { AppErrorModalProvider } from './appErrorModalProvider';
import { ThemeProvider } from './theme';

export const Providers: FC = ({ children }) => (
  <AppErrorModalProvider>
    <ThemeProvider>
      { children}
    </ThemeProvider>
  </AppErrorModalProvider>
);
