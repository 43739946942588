import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Providers } from 'providers';
import { AppRoutes } from 'routes';

import { useSentry, SentryErrorBoundary } from 'services';
import { useInitialLoad } from 'layout/useInitialLoad';

const queryClient = new QueryClient();

// TODO: useRoutes -> <Route /> to make integration work
// const SentryRoutes = withSentryReactRouterV6Routing(AppRoutes);

const App: FC = () => {
  useSentry();
  useInitialLoad();

  return (
    <SentryErrorBoundary>
      <Helmet>
        <title>Referral App | Attrace</title>
        <meta
          name="description"
          content="Unlock the value of ‘word of mouth’ in web3. Recommend crypto or NFT and earn rewards from referral farms."
        />
      </Helmet>
      <Router>
        <QueryClientProvider client={queryClient}>
          <Providers>
            <AppRoutes />
          </Providers>
        </QueryClientProvider>
      </Router>
    </SentryErrorBoundary>
  );
};

export default App;
