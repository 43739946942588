import { BigNumber } from 'ethers';

import {
  entitlements, farms, confirmationsV1 as confirmationsV1Api,
} from 'api';
import { contracts, logger } from 'services';
import { hash } from 'utils';

import {
  Address, ChainAddress, ChainId,
} from 'types/web3';
import { Entitlement } from 'api/entitlements/types';
import { IFarmExistsEvent } from 'api/indexer/farms/types';

import { TAccountRewardsMap, TReferredMap } from './types';

export async function filterRewardsByAccountTokenPeriodOffset(
  account: Address,
  rewardTokenDefn: ChainAddress,
  entitles: Entitlement[],
  referralFarmsV1Addr: Address,
): Promise<Entitlement[]> {
  const res: Entitlement[] = [];

  entitles.forEach((entitle) => {
    contracts.referralFarmsV1.getAccountTokenConfirmationOffset(account, rewardTokenDefn, referralFarmsV1Addr)
      .then((offset) => {
        if (offset < BigNumber.from(entitle.confirmation).toNumber()) {
          res.push(entitle);
        }
      });
  });

  return res;
}

function transformAccountRewards(rewards: IGetAccountRewards[]): TAccountRewardsMap {
  const accountRewardsMap: TAccountRewardsMap = new Map();

  rewards.forEach((reward) => {
    const {
      rewardTokenDefn,
      referredTokenDefn,
      rewardValue,
      claimed: isClaimed,
      locked: isLocked,
      finalized: isFinalized,
      rewardsLockTime,
    } = reward;

    const rewardValueBig = rewardValue.toBigInt();

    const prevRewardValue = accountRewardsMap.get(rewardTokenDefn);
    if (prevRewardValue) {
      const prevReferrerValue = prevRewardValue.referredMap.get(referredTokenDefn);
      if (prevReferrerValue) {
        prevRewardValue.referredMap.set(referredTokenDefn, {
          totalRewards: prevReferrerValue.totalRewards + rewardValueBig,
          claimed: prevReferrerValue.claimed + (isClaimed ? rewardValueBig : BigInt(0)),
          locked: prevReferrerValue.locked + (isLocked ? rewardValueBig : BigInt(0)),
          finalized: prevReferrerValue.finalized + (isFinalized ? rewardValueBig : BigInt(0)),
          rewardsLockTime,
        });
      } else {
        prevRewardValue?.referredMap.set(referredTokenDefn, {
          totalRewards: rewardValueBig,
          claimed: (isClaimed ? rewardValueBig : BigInt(0)),
          locked: (isLocked ? rewardValueBig : BigInt(0)),
          finalized: (isFinalized ? rewardValueBig : BigInt(0)),
          rewardsLockTime,
        });
      }

      const totalRewards = prevRewardValue ? (prevRewardValue.totalRewards + rewardValueBig) : rewardValueBig;
      const rewardClaimed = (isClaimed ? rewardValueBig : BigInt(0));
      const rewardLocked = (isLocked ? rewardValueBig : BigInt(0));
      const rewardFinalized = (isFinalized ? rewardValueBig : BigInt(0));
      const claimed = prevRewardValue ? (prevRewardValue.claimed + rewardClaimed) : rewardClaimed;
      const locked = prevRewardValue ? (prevRewardValue.locked + rewardLocked) : rewardLocked;
      const finalized = prevRewardValue ? (prevRewardValue.finalized + rewardFinalized) : rewardFinalized;

      const next = {
        ...prevRewardValue,
        entitles: isClaimed || isLocked || !isFinalized
          ? prevRewardValue.entitles
          : [...prevRewardValue.entitles, reward],
        totalRewards,
        claimed,
        locked,
        finalized,
      };
      accountRewardsMap.set(rewardTokenDefn, next);
    } else {
      const totalRewards = rewardValueBig;
      const claimed = isClaimed ? rewardValueBig : BigInt(0);
      const locked = isLocked ? rewardValueBig : BigInt(0);
      const finalized = isFinalized ? rewardValueBig : BigInt(0);
      const entitles = isClaimed || isLocked || !isFinalized ? [] : [reward];

      const referredMap: TReferredMap = new Map();
      referredMap.set(referredTokenDefn, {
        totalRewards,
        claimed,
        locked,
        finalized,
        rewardsLockTime,
      });
      accountRewardsMap.set(rewardTokenDefn, {
        entitles,
        totalRewards,
        claimed,
        locked,
        finalized,
        rewardsLockTime,
        referredMap,
      });
    }
  });

  accountRewardsMap.forEach((reward) => {
    reward.entitles.sort((rewardA, rewardB) =>
      BigNumber.from(rewardA.confirmation).toNumber() - BigNumber.from(rewardB.confirmation).toNumber());
  });

  return accountRewardsMap;
}

interface IGetAccountRewards extends Entitlement, IFarmExistsEvent {
  claimed: boolean;
  locked: boolean;
  finalized: boolean;
  rewardsLockTime: number;
}

export async function getAccountRewards(
  account: Address,
  chainId: ChainId,
  oracleUrl: string,
  referralFarmsV1Addr: string,
  confirmationsV1Addr: string,
): Promise<TAccountRewardsMap> {
  try {
    const entitlementsRes = await entitlements.getAccountEntitlements(account, oracleUrl);
    const lockedEntitlementsRes = await entitlements.getAccountLockedEntitlements(account, oracleUrl);
    const rewardsHarvested = await farms.getRewardHarvestedEvents(oracleUrl, account, chainId, referralFarmsV1Addr);
    const farmsExists = await farms.getFarmExistsEvents(oracleUrl, chainId, referralFarmsV1Addr);
    const confirmationFinalizedMap = await confirmationsV1Api.getConfirmationFinalizedEvents(
      oracleUrl,
      chainId,
      confirmationsV1Addr,
    );

    const rewards: IGetAccountRewards[] = [];

    entitlementsRes.forEach((entitlement) => {
      const farm = farmsExists.get(entitlement.farmHash);

      if (farm) {
        const leafHash = hash.makeLeafHash(
          chainId,
          entitlement,
          farm.rewardTokenDefn,
          referralFarmsV1Addr,
          confirmationsV1Addr,
        );
        const computedHash = hash.makeComputedHash(leafHash, entitlement.proof);

        rewards.push({
          ...entitlement,
          ...farm,
          rewardsLockTime: 0,
          locked: false,
          claimed: Boolean(rewardsHarvested.get(leafHash)),
          finalized: Boolean(confirmationFinalizedMap.get(computedHash)),
        });
      }
    });

    const farmHashes = Array.from(farmsExists.keys());
    const farmsMetastate = await farms.getFarmMetastateEvents(oracleUrl, chainId, farmHashes, referralFarmsV1Addr);

    lockedEntitlementsRes.forEach((entitlement) => {
      const farm = farmsExists.get(entitlement.farmHash);
      const farmMetastate = farmsMetastate.get(entitlement.farmHash);
      if (farm) {
        rewards.push({
          ...entitlement,
          proof: [],
          ...farm,
          rewardsLockTime: farmMetastate?.rewardsLockTime || 0,
          locked: true,
          claimed: false,
          finalized: false,
        });
      }
    });

    return transformAccountRewards(rewards);
  } catch (e) {
    const error = e as Error;
    logger.logError(error, {
      function: 'rewards.getAccountRewards',
    });
    return Promise.reject(error);
  }
}
