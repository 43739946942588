import { FC, useMemo } from 'react';

import {
  Tooltip, LockIcon, TimeIcon,
} from 'components';

import styles from './styles.module.css';

const SECONDS_IN_DAY = 86400;

interface ITooltipLockContent {
  rewardsLockDays: number
}
const TooltipContent: FC<ITooltipLockContent> = ({ rewardsLockDays }) => (
  <>
    <div className={styles.lockingPeriod}>
      <TimeIcon />
      Locking period:
      {' '}
      {rewardsLockDays}
      {' '}
      days
    </div>
    <div className={styles.text}>
      The daily rewards you earn will be locked for
      {' '}
      {rewardsLockDays}
      {' '}
      days.
      That means that the rewards you earn today will be unlocked and can be harvested
      {' '}
      {rewardsLockDays}
      {' '}
      days from now.
    </div>
  </>
);

interface ITooltipLock {
  rewardsLockTime: number
}

export const TooltipLock: FC<ITooltipLock> = ({ rewardsLockTime }) => {
  const rewardsLockDays = useMemo(() => rewardsLockTime / SECONDS_IN_DAY, [rewardsLockTime]);

  return (
    <Tooltip
      content={<TooltipContent rewardsLockDays={rewardsLockDays} />}
    >
      <LockIcon
        size="16"
      />
    </Tooltip>
  );
};
