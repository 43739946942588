import { FC } from 'react';

export const DownIcon: FC = () => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.34317 0.757355L0.928955 2.17157L8.00001 9.24266L15.0711 2.1716L13.6569 0.757384L8.00003
      6.41422L2.34317 0.757355Z"
      fill="white"
    />
  </svg>

);
