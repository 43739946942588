import {
  FC, memo, ReactNode,
} from 'react';

import { Button } from 'components';

import styles from './buyFarmBtn.module.css';

interface IBuyFarmBtn {
    icon?: ReactNode;
    label?: string;
    handleClick: () => void;
    transactionModal: ReactNode;
    className?: string;
    isDisabled?: boolean;
}

export const BuyFarmBtn: FC<IBuyFarmBtn> = memo(({
  icon,
  label = 'Buy to Earn',
  handleClick,
  transactionModal,
  className,
  isDisabled = false,
}) => (
  <>
    <Button
      className={className}
      size="lg"
      onClick={handleClick}
      disabled={isDisabled}
    >
      {icon && (
        <div className={styles.iconWrapper}>
          {icon}
        </div>
      )}
      {label}
    </Button>

    {transactionModal}
  </>
));

BuyFarmBtn.displayName = 'BuyFarmBtn';
