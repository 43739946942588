import { FC, ReactNode } from 'react';
import {
  Accordion as MUIAccordion, styled, AccordionProps,
} from '@mui/material';

const DefaultAccordion = styled(MUIAccordion)<AccordionProps>(() => ({
  width: '100%',
  background: 'var(--accordion-bg)',
  padding: 0,
  margin: '0 !important',

  boxShadow: 'inset 0 -1px 0 var(--table-border-color)',

  '&:first-of-type': {
    borderRadius: '0',
  },

  '&:last-of-type': {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },

  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: '24px',
  },

}));

const DefaultAccordionMedia = styled(DefaultAccordion)`
  @media(max-width: 768px) {
    box-shadow: none;
  }
`;

interface IAccordion {
  expanded: boolean;
  handleChange: () => void;
  children: NonNullable<ReactNode>
}

export const Accordion: FC<IAccordion> = ({
  children, expanded, handleChange,
}) => (
  <DefaultAccordionMedia
    expanded={expanded}
    onChange={handleChange}
  >
    {children}
  </DefaultAccordionMedia>
);
