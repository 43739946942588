import { CSSProperties, FC } from 'react';
import { cx } from '@emotion/css';

import { ERC20Token } from 'types';

import styles from './styles.module.css';

interface ITokenIcon {
  token: ERC20Token;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  style?: CSSProperties;
}

export const TokenIcon: FC<ITokenIcon> = ({
  token, size = 'lg', style,
}) => (
  <div
    className={cx('flex-center', styles.tokenLogo, styles[size])}
    style={style}
  >
    {token.logoURI ? (
      <img
        src={token.logoURI}
        alt={token.symbol}
        loading="lazy"
      />
    ) : token.symbol?.slice(0, 1)}
  </div>
);
