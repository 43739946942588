import * as Sentry from '@sentry/react';

import { Address } from 'types/web3';

interface SentryUserContext {
  userAddress: Address;
  tokenAddress?: Address;
}

export type ISentryExtra = Record<string, unknown>

function captureException(err: Error, extra?: ISentryExtra): void {
  Sentry.captureException(err, {
    extra,
  });
}
function setContext<T>(contextName: string, context: T): void {
  Sentry.setContext(contextName, context);
}

function setUserContext(context: SentryUserContext): void {
  setContext<SentryUserContext>('user-meta', context);
}

export const sentry = {
  captureException,
  setUserContext,
};
