import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ERC20Token } from 'types/web3';
import { IDiscoveryChainData } from 'api';
import { IAppState } from './types';

const initialState: IAppState = {
  isMobile: false,
  modals: {
    createFarmOpen: false,
    videoModalOpen: false,
  },
  tokensList: [],
  discoveryChain: null,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTokensList(state, action: PayloadAction<Array<ERC20Token>>) {
      state.tokensList = action.payload;
    },
    setDiscoveryChainData(state, action: PayloadAction<IDiscoveryChainData>) {
      state.discoveryChain = action.payload;
    },
    setIsMobile(state, action: PayloadAction<boolean>) {
      state.isMobile = action.payload;
    },
    toggleCreateFarmModal(state) {
      state.modals.createFarmOpen = !state.modals.createFarmOpen;
    },
    toggleVideoModal(state) {
      state.modals.videoModalOpen = !state.modals.videoModalOpen;
    },
  },
});

export const {
  actions,
  reducer,
} = slice;
