import { ReactNode, FC } from 'react';
import { cx } from '@emotion/css';
import { Typography } from '@mui/material';

import { GeneralModal, IGeneralModal } from '../generalModal';
import { CloseBtn } from './closeBtn';
import { BackBtn } from './backBtn';

import styles from './styles.module.css';

interface IModal extends IGeneralModal{
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  onBack?: () => void;
  classNameBody?: string;
  classNameContent?: string;
  classNameFooter?: string;
}

export const Modal: FC<IModal> = ({
  open,
  title,
  subtitle,
  footer,
  children,
  onClose,
  backdropTransparent = false,
  classNameBody,
  classNameContent,
  classNameFooter,
  onBack,
}) => (
  <GeneralModal
    open={open}
    backdropTransparent={backdropTransparent}
  >
    <div className={cx(styles.modalBody, classNameBody, styles.animateBottom)}>
      <div className={cx(styles.modalHeader, 'flex-column', {
        [styles.modalHeaderWithContent]: !!title,
      })}
      >
        <div className={cx({
          [styles.modalHeaderText]: !!title,
        })}
        >
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            className={cx('flex-align-center', styles.modalTitle)}
          >
            {onBack && <BackBtn handleClick={onBack} />}
            {title}
            {onClose && <CloseBtn handleClick={onClose} />}
          </Typography>
          {subtitle && (
            <div className={styles.modalSubtitle}>
              {subtitle}
            </div>
          )}
        </div>
      </div>

      <div className={cx(styles.modalContent, classNameContent)}>{children}</div>
      <div className={cx(styles.modalFooter, classNameFooter)}>{footer}</div>
    </div>
  </GeneralModal>
);
