import { FC } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import { CloseButtonProps } from 'react-toastify/dist/components/CloseButton';

import { CloseIcon } from 'components';

import 'react-toastify/dist/ReactToastify.css';
import styles from './notification.module.css';

const CloseButton: FC<CloseButtonProps> = ({ closeToast }) => (
  <button
    className="flex-center"
    type="button"
    onClick={closeToast}
  >
    <CloseIcon
      opacity="0.7"
      size="20"
    />
  </button>
);

export const Notification: FC = () => (
  <ToastContainer
    toastClassName={styles.toast}
    bodyClassName={styles.toastBody}
    closeButton={CloseButton}
    closeOnClick
    autoClose={3000}
    draggable={false}
    hideProgressBar
    position="bottom-center"
    transition={Slide}
  />
);
