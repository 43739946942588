import { FC } from 'react';
import { cx } from '@emotion/css';

import styles from './styles.module.css';

interface ILabel {
  label: string;
  className?: string;
}

export const Label: FC<ILabel> = ({
  label, className = '',
}) => (
  <h6 className={cx(styles.label, className)}>
    {label}
  </h6>
);
