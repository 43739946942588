import { FC, ReactElement } from 'react';
import { ErrorBoundary } from '@sentry/react';

function Fallback(): ReactElement {
  return <div>An error has occurred</div>;
}

export const SentryErrorBoundary: FC = ({ children }) => (
  <ErrorBoundary fallback={Fallback}>
    {children}
  </ErrorBoundary>
);
