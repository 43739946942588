import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { cx } from '@emotion/css';

import {
  Button, ArrowRightIcon, ArrowLeftIcon, Tooltip, TooltipContentWithIcon, DangerIcon,
} from 'components';

import { routes } from 'routes';

import { REFER_TO_EARN_STEPS_SUCCESSFUL } from '../../../config';

import styles from './referToFarmSuccessfull.module.css';

interface IReferToFarmSuccessfullFooter {
  handleDismiss: () => void;
  successfulStepId: number;
  onChangeSuccessfulStepId: (type: 'next' | 'prev') => void,
  linkCopied: boolean
}

const { MY_REWARDS } = routes;

export const ReferToFarmSuccessfulFooter: FC<IReferToFarmSuccessfullFooter> = ({
  handleDismiss,
  successfulStepId,
  onChangeSuccessfulStepId,
  linkCopied,
}) => {
  const navigate = useNavigate();

  const hasPrevStep = successfulStepId > 1;
  const hasNextStep = successfulStepId < REFER_TO_EARN_STEPS_SUCCESSFUL.length;
  const isLastStep = successfulStepId === REFER_TO_EARN_STEPS_SUCCESSFUL.length;
  return (
    <div className={styles.footer}>
      <Button
        onClick={handleDismiss}
        variant="tertiary"
        className={cx(styles.dissmissBtn, {
          [styles.dissmissBtnHidden]: !linkCopied,
        })}
      >
        Dismiss
      </Button>
      <div className="flex-center">
        {hasPrevStep && (
          <Button
            onClick={() => onChangeSuccessfulStepId('prev')}
            variant="secondary"
          >
            <ArrowLeftIcon opacity=".8" />
            Previous Step
          </Button>
        )}

        {!linkCopied && (
          <Tooltip
            isDisabled={!linkCopied}
            content={(
              <TooltipContentWithIcon
                icon={(
                  <DangerIcon
                    opacity=".9"
                    fillColor="var(--white)"
                  />
                )}
                text="Copy your referral link first"
              />
            )}
          >
            <Button
              onClick={() => onChangeSuccessfulStepId('next')}
              disabled={!linkCopied}
              className={styles.nextBtn}
            >
              Next Step
              <ArrowRightIcon />
            </Button>
          </Tooltip>
        )}
        {linkCopied && hasNextStep && (
          <Button
            onClick={() => onChangeSuccessfulStepId('next')}
            disabled={!linkCopied}
            className={styles.nextBtn}
          >
            Next Step
            <ArrowRightIcon />
          </Button>
        )}
        {isLastStep && (
          <Button
            onClick={() => navigate(MY_REWARDS)}
            className={styles.nextBtn}
          >
            Go to My Rewards
            <ArrowRightIcon />
          </Button>
        )}
      </div>
    </div>
  );
};
