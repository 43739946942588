export type { ERC20Token } from './web3';

export enum TRANSACTION_STATUS {
  loading = 'loading',
  success = 'success',
  error = 'error',
  idle = 'idle',
}

export type TTransactionStatus = keyof typeof TRANSACTION_STATUS;

type TTableColumn = {
  header: string;
  key: string;
  width: string;
  tooltip?: string;
};

export type TTableColumns = TTableColumn[];
