import { FC } from 'react';
import { cx } from '@emotion/css';

import { SlicedString } from 'components';
import { ProfileIcon } from 'modules/shared';

import styles from './profileButton.module.css';

interface IProfileButton {
  handleProfileClick?: () => void
  className?: string;
  account?: string;
}

export const ProfileButton: FC<IProfileButton> = ({
  handleProfileClick,
  className,
  account,
}) => (
  <button
    type="button"
    className={cx(styles.profileButton, className, {
      [styles.withAccount]: !!account,
    })}
    onClick={handleProfileClick && handleProfileClick}
  >
    {account && (
      <div className={styles.account}>
        <SlicedString
          text={account}
        />
      </div>
    )}
    <ProfileIcon />
  </button>

);
