import { web3 } from 'services';

import { web3Modal } from './web3Modal';
import { IWalletSuccess } from './types';

const connect = async (): Promise<IWalletSuccess> => {
  try {
    web3Modal.addSpinnerPending();

    if (!window.ethereum) {
      web3Modal.addInstallMetaMask();
    }

    const instance = await web3Modal.connect();
    const web3Provider = web3.getWeb3Provider(instance);

    const accountsIds = await web3Provider.listAccounts();
    const network = await web3Provider.getNetwork();

    web3Modal.removeWalletUINodes();
    return {
      account: accountsIds[0],
      chainId: network.chainId,
      web3Instance: instance,
    };
  } catch (e) {
    web3Modal.removeWalletUINodes();
    return Promise.reject(e);
  }
};

const disconnect = (): void => {
  web3Modal.clearProvider();
};

export const wallet = {
  connect,
  disconnect,
};
